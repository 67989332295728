import React from 'react';
import './Nvictta.css';
import Aoto1 from '../OwlCarousel/Nvictta';
import NvicctaPdt from '../OwlCarousel/NvicttaPdt';

import FAQ from '../FAQ/NvicttaFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
// import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'

const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Nviccta</title>
                <meta name="Superior AV Products Online" content="Discover premium AV Solutions at Nvictta for High-quality Audio Visual Devices and Accessories. Get Superior Quality and Support For All Your Audio Visual Needs" />
                <meta name="keywords" content="Audio Visual, AV Installations, Audio Visual Solutions, AV Solutions, Audio Visual Devices" />
            </Helmet>
        </div>

        <div class="nviccta-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Connecting Your Vision with <br/> Superior AV/IT Solutions!
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='http://nvictta.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Premium Audio Visual Solutions</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Nvictta stands as a beacon of excellence in the realm of audio-visual solutions and products, dedicated to fulfilling the diverse needs of modern AV installations. As a leading supplier, Nvictta combines innovative technology with exceptional customer support, ensuring that clients receive not just products, but integrated solutions that enhance their audio-visual experiences. 
                    <br/><br/>With a focus on quality and reliability, coupled with highly skilled technical and sales teams, Nvictta empowers system integration partners to achieve their project goals with confidence. Whether it’s high-definition cables, power conditioners, or sophisticated cable management systems, Nvictta is committed to delivering top-tier products that cater to high-end installations, bringing visions to life with seamless efficiency.
</p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Most-Trusted Source for High-End AV/IT Installations</h2>
            <Aoto1/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Our Advanced Audio Visual Solutions</h3>
            <NvicctaPdt/>
            {/* <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Mesh WiFi Systems</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">WiFi Routers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Control">5G Mobile Routers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Cable Modems</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AotoCreative/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AotoRental/>
                </Tab.Pane>

                <Tab.Pane eventKey="Control">
                    <AotoControl/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container> */}
        <div className="middle-button">
            <a target='_blank' href='http://nvictta.com/index.html' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Superior AV/IT Solutions: Where Quality Meets Innovation</h4>
            <div className='line-up'>
                <h4>HDMI Cables:</h4>
                <p>Nvictta’s high-definition HDMI cables deliver superior video and audio quality for optimal performance in any AV solution setup, ensuring a seamless connection between devices.</p>
            </div>
            <div className='line-up'>
                <h4>Power Conditioners:</h4>
                <p>The LC-404 power conditioner protects electronic equipment from power surges and provides clean power for sensitive audio-visual devices, enhancing their longevity and performance.</p>
            </div>
            <div className='line-up'>
                <h4>Cable Managers:</h4>
                <p>Designed to organize and conceal cables, Nvictta's cable managers simplify installations, creating a neat and professional look while preventing tangles and wear on cables.</p>
            </div>
            <div className='line-up'>
                <h4>Fixed Wall Mounts :</h4>
                <p>Nvictta's fixed wall mounts are designed for secure and stable installation of AV equipment, ensuring devices are mounted safely for an optimal viewing experience.</p>
            </div>
            <div className='line-up'>
                <h4>Cameras:</h4>
                <p>High-performance cameras from Nvictta cater to various AV solutions, providing crystal-clear resolution and reliability for any environment, from corporate settings to creative installations.</p>
            </div>
            
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Transforming Ideas Into Reality with High End AV/IT Installations</h5><br/>
            <p>Nvictta envisions becoming a global leader in the audio-visual solutions market, pushing the boundaries of innovation and quality in the industry. 
            With a commitment to sustainability and customer satisfaction, the brand aims to develop new technologies and products that enhance user experiences in a 
            variety of settings, from homes to corporate environments. <br/><br/>By fostering strong partnerships and providing unparalleled support, Nvictta aspires to empower 
            clients to achieve their visions of modern AV/IT installations, ensuring they remain at the cutting edge of technology.
             The future at Nvictta is about creating seamless and enriching AV/IT experiences, making high-quality audio-visual solutions accessible to every project across 
             the globe.</p>
            <a target='_blank' href='http://nvictta.com/index.html' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Nviccta and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
