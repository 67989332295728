import React from 'react';
import aoto1 from '../../Assets/Siemon/Commitment to Sustainability.jpg';
import aoto2 from '../../Assets/Siemon/Comprehensive OEM Technologies.jpg';
import aoto3 from '../../Assets/Siemon/Global Project Services.jpg';
import aoto4 from '../../Assets/Siemon/High-Performance Infrastructure.jpg';
import aoto5 from '../../Assets/Siemon/Innovative Design.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>High-Performance Infrastructure</h3>
            <p className='thro'>Siemon’s expertise in copper and fiber network solutions ensures exceptional speed and reliability, allowing your network to handle increasing data demands with ease. Our products are designed for scalability and minimal downtime, making them ideal for growing businesses.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Innovative Design</h3>
            <p className='thro'>Our team is continually pushing the boundaries of technology to create advanced connectivity solutions that not only meet current needs but anticipate future demands. This includes intelligent innovations tailored for data centers, smart environments, and complex cellular networks.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Global Project Services</h3>
            <p className='thro'>Siemon’s Global Project Services enable us to manage complex deployment projects seamlessly, offering professional support for infrastructure moves, adds, and changes. Our proactive approach ensures quality results backed by on-call global support, providing peace of mind to multi-site businesses.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Comprehensive OEM Technologies</h3>
            <p className='thro'>With decades of experience, Siemon OEM Technologies serves manufacturers and integrators by delivering market-centric solutions. Our collaborative approach ensures that partners receive tailored support throughout the project lifecycle, from design to delivery.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Commitment to Sustainability</h3>
            <p className='thro'>Siemon is not just focused on connectivity—we are committed to creating environmentally friendly solutions that support sustainable practices. Our initiatives prioritize reducing waste and energy consumption throughout the product life cycle, contributing to a greener future.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;