import React from 'react';
import './XpoScreens.css';
import Aoto1 from '../OwlCarousel/XpoScreens';
import AotoCommercial from '../OwlCarousel/xposcreens-elevator-screens';
import AotoCreative from '../OwlCarousel/xposcreens-arcadia';
import AotoRental from '../OwlCarousel/xposcreens-spherix';
import AotoControl from '../OwlCarousel/xposcreens-invite';
import FAQ from '../FAQ/XpoScreensFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Xpo Screens</title>
                <meta name="Digital Signage Media DIsplays For High Traffic Areas" content="Bright, Reliable and Energy Efficient Digital Signage and Media Players For High Traffic Areas. Attract Your Customer Easily With Digital Signage Processors." />
                <meta name="keywords" content="High Traffic Public areas,Energy efficient technologies,Media displays,Digital Displays" />
            </Helmet>
        </div>

        <div class="xpo-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Powering For High-Performance <br/> Connectivity
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.xposcreens.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>The Best Digital Signage Solutions You Need
</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    XPO Screens is at the forefront of innovative digital display solutions that create lasting impressions across various environments. 
                    Specializing in high-end digital signage, particularly in elevators and commercial spaces, XPO delivers engaging and impactful 
                    content that captures attention and enhances the viewer experience. <br/><br/>With a commitment to sustainability and customization, XPO aims 
                    to revolutionize the way brands communicate by placing digital screens in strategic locations where they can truly shine, ensuring your messages are not just seen but remembered.</p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Energy-Efficient Digital Signage Displays
</h2>
            <Aoto1/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Media Players For High Traffic Public Areas</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Elevator screens</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Arcadia</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Control">Invite</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Spherix</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AotoCreative/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AotoRental/>
                </Tab.Pane>

                <Tab.Pane eventKey="Control">
                    <AotoControl/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.xposcreens.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Energy Efficient Technology Support
</h4>
            <div className='line-up'>
                <h4>Elevator Media Displays:</h4>
                <p>Custom-designed screens for elevators that deliver advertisements or informative content, maximizing visibility during brief transit times.</p>
            </div>
            <div className='line-up'>
                <h4>Smart Mirrors:</h4>
                <p>Innovative displays that combine functionality with aesthetic appeal—perfect for retail and hospitality sectors where brand engagement is critical.</p>
            </div>
            <div className='line-up'>
                <h4>Bespoke Digital Signage:</h4>
                <p>Tailored digital screens designed for specific applications, ensuring that your content stands out and delivers the right message in the right context.</p>
            </div>
            <div className='line-up'>
                <h4>Restroom Digital Displays:</h4>
                <p>Eye-catching displays strategically placed in restroom facilities to enhance engagement with patrons while providing valuable advertising space.</p>
            </div>
            <div className='line-up'>
                <h4>DOOH Advertising:</h4>
                <p>Digital Out Of Home advertising solutions that extend your brand's reach by placing eye-catching displays in high-traffic public areas.</p>
            </div>
            <div className='line-up'>
                <h4>Narrowcasting Solutions:</h4>
                <p>Focused communication displays that allow businesses to share targeted messages to specific audiences at the right time and location.</p>
            </div>
            <div className='line-up'>
                <h4>Internal Communication Displays:</h4>
                <p>Essential for corporate environments, these screens facilitate effective communication among employees, enhancing workplace engagement.</p>
            </div>
            <div className='line-up'>
                <h4>Convention and Venue Screens:</h4>
                <p>Custom digital signage solutions designed for events, providing essential information and engagement opportunities for attendees.</p>
            </div>
            <div className='line-up'>
                <h4>Retail Displays:</h4>
                <p>High-visibility screens that bring products to life through captivating visuals, driving foot traffic and increasing sales conversions.</p>
            </div>
            <div className='line-up'>
                <h4>Media Players:</h4>
                <p>Reliable media players to power your content, ensuring smooth playback and an optimal display experience across various platforms.</p>
            </div>
            
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

     

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Digital Signage - The Future of Advertising</h5><br/>
            <p>XPO Screens envisions a future where digital signage becomes an integral part of everyday environments, enhancing communication and 
            engagement in innovative ways. XPO aims to lead the industry in delivering solutions that not only captivate audiences but also contribute 
            to sustainable practices. <br/><br/>As businesses adapt to new challenges in communication, XPO is poised to be a trusted partner, enabling clients to creatively engage their customers and transform their spaces into dynamic storytelling platforms. Emphasizing custom solutions and smart integration, the brand looks forward to a future where digital displays will play a pivotal role in shaping consumer interactions and driving brand loyalty in an increasingly digital world.</p>
            <a target='_blank' href='https://www.xposcreens.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Allsee and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
