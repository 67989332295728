import React from 'react';
import Audkey1 from '../../Assets/Cyviz/cyviz-key1.jpg';
import Audkey2 from '../../Assets/Cyviz/cyviz-key2.jpg';
import Audkey3 from '../../Assets/Cyviz/cyviz-key3.jpg';
import Audkey4 from '../../Assets/Cyviz/cyviz-key4.jpg';
import Audkey5 from '../../Assets/Cyviz/cyviz-key5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Next-Level Collaboration
</h3>
            <p className='thro'>Cyviz offers a suite of solutions designed to elevate how teams communicate, whether they are physically 
            together or spread across the globe. Their systems are built to ensure everyone contributes seamlessly, enhancing creativity.
</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>User-Centric Design
</h3>
            <p className='thro'>Every product from Cyviz emphasizes ease of use, allowing teams to set up and manage their workplace collaboration spaces 
            intuitively. With minimal training required, users can fully leverage the technology, ensuring a smooth meeting experience.
</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Innovation centers
</h3>
            <p className='thro'>Cyviz Solutions are customizable and adaptable to various environments, 
            whether for command and control centers or corporate meeting rooms, providing the flexibility needed to scale as business needs evolve.
</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Robust Integration with Leading Technologies
</h3>
            <p className='thro'>Partnering with industry giants like Microsoft, Cyviz solutions are designed to integrate smoothly with existing technology infrastructures, 
            ensuring a cohesive experience across platforms and devices, thereby enhancing productivity.
</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>High-Impact Collaboration Spaces
</h3>
            <p className='thro'>Cyviz specializes in creating environments that encourage interaction and engagement, 
            transforming mundane meeting rooms into inspiring spaces that motivate and energize teams, leading to improved outcomes and job satisfaction.
</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;