import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of LED displays does AOTO offer?",
      answer: "AOTO provides Virtual Production/XR displays, Rental, Sports, Outdoor, Commercial, and All-In-One LED displays.",
    },
    {
      question: "What is the specific advantage of the RM Series for Virtual Production?",
      answer: "The RM Series boasts a 7680Hz refresh rate and covers 99.9% of the DCI-P3 color gamut for high-quality virtual production.",
    },
    {
      question: "How does the AE Series ensure high-quality performance in studio environments?",
      answer: "The AE Series offers a 2,000 kg/sqm load capacity and full viewing angles, ensuring uniform display quality in studios.",
    },
    {
      question: "What innovations does the MxH Series bring to ceiling displays?",
      answer: "MxH Series simulates sky scenes with a CRI of up to 90%, providing realistic visual effects and reflections.",
    },
    {
        question: "Are AOTO’s displays suitable for outdoor usage?",
        answer: "Yes, AOTO’s Outdoor LED displays are designed to endure weather conditions while delivering ",
      },
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
