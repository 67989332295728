import React from 'react';
import './QSC.css';
import Aoto1 from '../OwlCarousel/QSC';
import Qscpdt from '../OwlCarousel/Qscpdt';
import FAQ from '../FAQ/QSCFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | QSC</title>
                <meta name="Latest Audio Technologies" content="Experience High-performance Audio Solutions With Superior Sound Quality & Networked Capabilities. Upgrade Your Audio System with QSC's Latest Technology Systems" />
                <meta name="keywords" content="High Performance Audio solutions, Latest Technology, audio systems, Superior sound quality, Networked Capabilities" />
            </Helmet>
        </div>

        <div class="qsc-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Experience the Ultimate Power of <br/> High-Performance Audio
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.qsc.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Advanced Audio Technologies</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    QSC is at the forefront of audio innovation, renowned for its commitment to delivering high-performance audio solutions that redefine the industry standard. 
                    With a legacy built on advanced engineering and reliability, QSC empowers sound professionals with latest technology products designed for both portable and 
                    installed applications. <br/><br/>From dynamic loudspeakers to powerful mixing solutions, their products are engineered to provide exceptional sound quality, versatility, and user-friendly operation. QSC’s dedication to excellence extends beyond its products, fostering a community of support and training that helps users harness the full potential of their audio systems, ensuring every performance is both memorable and impactful.</p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Unmatched Performance with Networked Capabilities</h2>
            <Aoto1/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Explore Our Innovative Audio Solutions</h3>
            <Qscpdt/>
            {/* <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Mesh WiFi Systems</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">WiFi Routers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Control">5G Mobile Routers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Cable Modems</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AotoCreative/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AotoRental/>
                </Tab.Pane>

                <Tab.Pane eventKey="Control">
                    <AotoControl/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container> */}
        <div className="middle-button">
            <a target='_blank' href='https://www.qsc.com/solutions-products/' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>High-Performance Products for Every Need</h4>
            <div className='line-up'>
                <h4>K.2 Series Premium Powered Loudspeakers:</h4>
                <p>The K.2 Series combines elegant design with superior audio performance, making it suitable for both portable and installed applications, ensuring exceptional sound quality wherever it’s used.</p>
            </div>
            <div className='line-up'>
                <h4>KS Series Active Subwoofers:</h4>
                <p>Engineered for low-frequency enhancement, the KS Series features models tailored for various applications, from mobile entertainment to live events, ensuring powerful bass that enhances audio experiences.</p>
            </div>
            <div className='line-up'>
                <h4>CP Series Compact Powered Loudspeakers:</h4>
                <p>The CP Series delivers class-leading performance in an ultra-compact design, making it perfect for versatile applications and easy transport for sound professionals on the go.</p>
            </div>
            <div className='line-up'>
                <h4>LA108 and LA112 Line Array Loudspeakers:</h4>
                <p>These line array speakers are designed for optimal coverage and sound clarity in large venues, providing professional-grade audio performance for any live event.</p>
            </div>
            <div className='line-up'>
                <h4>LS118 Subwoofer:</h4>
                <p>This active subwoofer enhances low-frequency response, offering a robust solution for powerful sound reinforcement, making it ideal for live performances and high-output applications.</p>
            </div>
            
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Transform Your Audio Experience Today</h5><br/>
            <p>QSC aims to lead the audio industry by continuously advancing technology and innovation while prioritizing sustainability and responsible practices. 
            The brand envisions a future where seamless audio experiences enhance live performances, installations, and everyday listening, bringing people together 
            through the power of sound. <br/><br/>With a focus on community engagement, education, and exceptional customer service, QSC seeks to empower audio professionals and 
            enthusiasts alike, ensuring that their products not only meet but exceed the expectations of a rapidly evolving market. As they expand their offerings, QSC is committed to creating high-performance audio solutions that resonate globally, defining the future of sound through innovation and creativity.</p>
            <a target='_blank' href='https://www.qsc.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about QSC and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
