import React from 'react';
import Audackey1 from '../../Assets/Audac/AudacKey1.jpg';
import Audackey2 from '../../Assets/Audac/AudacKey2.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Exceptional Sound Quality</h3>
            <p className='thro'>AUDAC products, like the VEXO208, utilize advanced engineering techniques combining LF drivers and coaxial configurations. This ensures an audio performance that delivers full-bodied sound, 
            often likened to that of larger loudspeakers, elevating any listening environment.</p>
        </div>
        <img
            src={Audackey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Innovative Control Solutions
</h3>
            <p className='thro'>With the AUDAC Touch™ platform, users can manage their audio systems seamlessly from any device. This intuitive app allows customized dashboards for easy operation of multiple devices, enhancing ease of use and increasing operational efficiency in controlling audio, lighting, and more.
</p>
        </div>
        <img
            src={Audackey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Robust and Reliable
</h3>
            <p className='thro'>AUDAC stands behind its products with a 5-year warranty, emphasizing durability and long-term performance. This confidence in their engineering is reflected in the comprehensive support and regular firmware updates that keep systems current and performing at their best.
</p>
        </div>
        <img
            src={Audackey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Impeccable Design
</h3>
            <p className='thro'>This 8” coaxial loudspeaker provides a slim design with wide coverage, delivering remarkable sound quality that rivals larger systems, making it perfect for both intimate and expansive settings.

</p>
        </div>
        <img
            src={Audackey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;