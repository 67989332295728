import React from 'react';
import './Audinate.css'
import AudinateKey from '../OwlCarousel/AudinateKey';
import AudinateAdapt from '../OwlCarousel/AudinateAdapt';
// import AudinateSoft from '../OwlCarousel/AudinateSoft';
// import AudinateNetwork from '../OwlCarousel/AudinateNetwork';
import AudinateDante from '../OwlCarousel/AudinateDante';
// import AudinateSol from '../OwlCarousel/AudinateSol';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/Audinate';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Audinate</title>
                <meta name="Dante Networking Solutions: High Resolution Audio & Video" content="Experience High-Performance AV With Audinate's AV Systems. Audinate's High-resolution Audio Over Dante Networks Simplifies Your Audio Video Work Flow." />
                <meta name="keywords" content="High Resolution Audio, AV systems, Audio Video Technology, Dante Network, High Resolution AV systems" />
            </Helmet>
        </div>

        <div class="audinate-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            The New Audio Experience with Dante™ - <br/>The Industry-Leading Networking Solution
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.audinate.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Transformative Audio Video Technology</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Audinate is redefining the landscape of audio and video (AV) technology with their revolutionary Dante™ protocol—an innovation that transforms how professionals connect and manage AV systems. By replacing traditional audio and video connections with an Ethernet network, Audinate enables seamless transmission of high resolution audio and video over simple, slender cables. <br/><br/>
                    Audinate focuses on delivering exceptional AV experiences. It has quickly become the industry standard, adopted by over 600 manufacturers and powering more than 1 million Dante units globally in 2023. Their commitment to innovation, reliability, and user-centric solutions ensures that creators and engineers experience the ultimate in audio quality and connectivity.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Why Choose Our High-Resolution AV Systems?</h2>
            <AudinateKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5"> Advanced AV Systems for Every Need
</h3>
            <Tab.Container defaultActiveKey="Commercial">
              <Nav variant="pills" className="justify-content-center">
                  <Nav.Item>
                      <Nav.Link eventKey="Commercial">Adapters</Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                      <Nav.Link eventKey="Creative">Software Essentials</Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item>
                      <Nav.Link eventKey="Rental">Network Management</Nav.Link>
                  </Nav.Item> */}
                  <Nav.Item>
                      <Nav.Link eventKey="Wall">Dante™ Enabled Products</Nav.Link>
                  </Nav.Item> 
                  {/* <Nav.Item>
                      <Nav.Link eventKey="Solution">Solutions for Manufacturers</Nav.Link>
                  </Nav.Item> */}
              </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AudinateAdapt/>
                </Tab.Pane>

                {/* <Tab.Pane eventKey="Creative">
                    <AudinateSoft/>
                </Tab.Pane> */}

                {/* <Tab.Pane eventKey="Rental">
                    <AudinateNetwork/>
                </Tab.Pane> */}

                <Tab.Pane eventKey="Wall">
                    <AudinateDante/>
                </Tab.Pane> 
                
                 {/* <Tab.Pane eventKey="Solution">
                    <AudinateSol/>
                </Tab.Pane> */}
                 
              
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.getdante.com/products/' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Get the Best in High-Resolution Audio</h4>
            <div className='line-up'>
                <h4>Dante™ Virtual Soundcard 
                :</h4>
                <p>A software application that turns any computer into a Dante™-enabled device, providing unrestricted access to high resolution audio over a network with low latency.

</p>
            </div>
            <div className='line-up'>
                <h4>Dante™ Domain Manager
                : </h4>
                <p>A powerful network management application that enhances the security, reliability, and scalability of Dante™ networks, making it ideal for large installations.

</p>
            </div>
            <div className='line-up'>
                <h4>Dante™ AV
                :
</h4>
                <p>An innovative solution that delivers both audio and video over Ethernet, allowing for easy integration of high resolution AV systems with minimal cabling complexity.
</p>
            </div>
            <div className='line-up'>
                <h4>Dante hardware interfaces
                :</h4>
                <p>Various devices that facilitate the connection of traditional audio and video equipment to a Dante™ network, enhancing existing setups with modern connectivity.

</p>
            </div>
            <div className='line-up'>
                <h4>Dante™-enabled Microphones
                :</h4>
                <p>Advanced microphones designed for perfect integration with Dante™ systems, delivering exceptional audio quality directly into the network.
</p>
            </div>
            <div className='line-up'>
                <h4>Dante™-enabled Mixers
                :</h4>
                <p>Mixing consoles that use the Dante™ protocol to provide powerful audio processing capabilities while allowing for flexible routing and management.
</p>
            </div>
            <div className='line-up'>
                <h4>Dante™-enabled Amplifiers
                :</h4>
                <p>Amplifiers that integrate seamlessly into Dante™ networks, efficiently distributing audio to various speakers and zones.

</p>
            </div>
            <div className='line-up'>
                <h4>Dante™ Bridge
                :</h4>
                <p>A desktop application that provides users with an easy-to-use interface to manage all Dante™-enabled devices on their network, facilitating audio routing and configuration.


                </p>
            </div>
            <div className='line-up'>
                <h4>Dante™ Networking Products
                :</h4>
                <p>A range of network switches and interfaces that ensure reliable and efficient data transmission for Dante™ systems, enhancing overall system performance.


                </p>
            </div>
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

       

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Join the Future of Audio Video Technology

</h5><br/>
            <p>Audinate envisions a future where AV connectivity reaches unprecedented heights of simplicity and quality. With ongoing advancements in Dante™ protocols and an expanding ecosystem of compatible products, Audinate aims to lead the charge in making seamless, high resolution AV experiences accessible to all, fostering creativity and collaboration across industries.


</p>
            <a target='_blank' href='https://www.audinate.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Audinate and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
