import React from 'react';
import './NexMosphere.css';
import NexmosphereKey from '../OwlCarousel/NexMosphere';
import NexSensors from '../OwlCarousel/NexSensors';
import NexControllers from '../OwlCarousel/NexControllers';
import FAQ from '../FAQ/NexmosphereFAQ'; 
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Nexmosphere</title>
                <meta name="Super Engaging LED Displays" content="Discover our Digital Signage Displays Made With Best-in-Class LED Technology Support" />
                <meta name="keywords" content="RFID systems, Sensing Technology, Integrated Portal, Operational efficiency, smooth installation process" />
            </Helmet>
        </div>

        <div class="nex-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Immersive Collaboration for Modern <br/> Teams
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.nexmosphere.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Nexmosphere's Smart Solutions</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Nexmosphere is at the forefront of creating immersive and intelligent environments through innovative sensing technologies and interfaces. Dedicated to revolutionizing how spaces interact with people, Nexmosphere offers a vast array of products designed to enhance user engagement and streamline operational efficiency. 
<br/><br/>Their best-in-town sensors and controllers empower businesses to cultivate rich, interactive experiences, whether in retail, 
exhibitions, or smart buildings. With a commitment to quality and innovation, Nexmosphere is reshaping the future of environment 
sensing technology and customer interactions, making technology accessible and intuitive.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Next Generation Pro-AV Systems</h2>
            <NexmosphereKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Advanced Sensing Technology</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Sensors</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Controllers</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <NexSensors/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <NexControllers/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.nexmosphere.com/products' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Premium LED Displays for Every Need </h4>
            <div className='line-up'>
                <h4>XY-240 Presence Sensor:</h4>
                <p>A versatile sensor designed to detect human presence, ideal for optimizing lighting and energy use in various environments.</p>
            </div>
            <div className='line-up'>
                <h4>XQ-L2 Lidar Sensor:</h4>
                <p>This sensor utilizes advanced light detection and ranging technology for precise distance measurement and spatial awareness.</p>
                 </div>
            <div className='line-up'>
                <h4>XZ-W11 Weight Sensor Bar Type: </h4>
                <p>This is engineered to measure weight effectively, this sensor is perfect for applications in retail and logistics.</p>
            </div>
            <div className='line-up'>
                <h4>XR-DR1 RFID Driver:</h4>
                <p>A robust interface for managing RFID systems, enhancing inventory control and asset tracking capabilities.</p>
            </div>
            <div className='line-up'>
                <h4>XT-B4N6 Push-button Interface:</h4>
                <p>This user-friendly interface allows for quick and easy interaction, responding to straightforward push button commands.</p>
            </div>
            <div className='line-up'>
                <h4>XF-P3W Wireless Pick-up Sensor:</h4>
                <p>This sensor offers flexible deployment options with wireless capabilities, ensuring reliable performance in various settings.</p>
            </div>
            <div className='line-up'>
                <h4>XV-H40 HandGesture Sensor:</h4>
                <p>This enables touchless interaction by recognizing hand gestures, promoting a hygienic and modern engagement method.</p>
            </div>
            <div className='line-up'>
                <h4>XZ-H60 Color Sensor:</h4>
                <p>This sensor accurately detects color variations, making it crucial for applications that require precise color matching.</p>
            </div>
            <div className='line-up'>
                <h4>XE-EF30 AirButton Sensor:</h4>
                <p>This sensor detects air gestures, allowing for a unique, touch-free user interaction experience.</p>
            </div>
            <div className='line-up'>
                <h4>XW-DL6 X-Wave Linear Interface:</h4>
                <p>A versatile interface that connects various devices seamlessly within a network, optimizing control and data collection.</p>
            </div>
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Future-Ready AV Solutions</h5><br/>
            <p>Nexmosphere envisions a world where environments are fully responsive and intuitive, seamlessly integrating technology into daily life. They aim to lead in the sensing technology space, fostering innovation that enhances user engagement and operational efficiency. This commitment includes a focus on sustainability and user-friendly solutions, ensuring that their technologies not only meet current needs but also contribute positively to the future of interactive environments.
</p>
            <a target='_blank' href='https://www.nexmosphere.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Nexmosphere and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
