import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import './Contact.css';
// import ContactForm from '../Forms/ContactForm';
import contact1 from '../../Assets/contact1.svg';
import contact2 from '../../Assets/contact2.svg';
import contact3 from '../../Assets/contact3.svg';
import contact4 from '../../Assets/contact4.svg';
import contact5 from '../../Assets/contact5.png';
import contact6 from '../../Assets/contact6.png';
import contact7 from '../../Assets/contact7.png';
import contact8 from '../../Assets/contact8.svg';
import { Helmet } from 'react-helmet-async';
const About = () => {
  return (
    <div>
       <div>
            <Helmet>
                <title>Nvincy Solutions</title>
                <meta name="Get In Touch With Nvincy For Live Support: Contact Us" content="Need help? Reach Out to Nvincy's Dedicated Team of Support Executives via phone, email, or live chat. Our support team is ready to assist you. Get In Touch Now." />
                <meta name="keywords" content="Get in touch with, Customer service, live support, Support Team" />
            </Helmet>
        </div>
        <div class="contact_sub_banner">
          <div class="contact-banner-content contact">
            <h1 class="career-h1 text-center">How can we help you? <br/> You can get in touch with us for support…</h1>
          </div>
        </div>

        <div className='cust-container'>
        <iframe title="Contact Us" aria-label='Contact Us' frameborder="0" style={{height: "1300px", width: "100%", border: "none"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/ContactUs/formperma/viJrfgj0IzVfUHzufWg3jkuSRRTLlfHJXUY6_hhoYaw'></iframe>
        </div>
        
        
        <div className='cust-container' style={{paddingTop:"30px"}}>
        <h2>Live Support</h2>
        <Container fluid className="contact-options-container">
      <Row className="justify-content-center">
        <Col md={4} sm={12} className="contact-option-col">
          <div className="contact-card">
            <div className="contact-card-body">
            <img style={{width: "60px"}} src={contact3} alt="contact"/>
              <h5 className="contact-card-title">Sales</h5>
              <p className="contact-card-text">
                Wanna know about<br/> our products or looking<br/> for a quote? <br/> Contact our Marketing Team
              </p>
                <p className="contact-info">
                <a href="mailto:sales@nvincy.com">sales@nvincy.com</a>
                <br />
                +91 080 4115 1212
              </p>
            </div>
          </div>
        </Col>

        <Col md={4} sm={12} className="contact-option-col">
          <div className="contact-card">
            <div className="contact-card-body">
            <img style={{width: "60px"}} src={contact2} alt="contact"/>
              <h5 className="contact-card-title">Partner with Us</h5>
              <p className="contact-card-text">
                Excited to join <br/> as a retailer? <br />  Contact our <br /> Retail Team
              </p>
                <p className="contact-info">
                <a href="mailto:partner@nvincy.com">partner@nvincy.com</a>
                <br />
                +91 080 4115 1212
              </p>
            </div>
          </div>
        </Col>

        <Col md={4} sm={12} className="contact-option-col">
          <div className="contact-card">
            <div className="contact-card-body">
            <img style={{width: "60px"}} src={contact1} alt="contact"/>
              <h5 className="contact-card-title">Talk to HR</h5>
              <p className="contact-card-text">
                Trying to get any job <br/> posting clarifications?  <br />  Contact our<br /> HR Team
              </p>
              <p className="contact-info">
                <a href="mailto:hr@nvincy.com">hr@nvincy.com</a>
                <br />
                +91 080 4115 1212
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>

        </div>

        <div className='cust-container' style={{paddingTop:"30px"}}>
        <div className="bills-claim-container">
        <div className="bills-claim-text">
        <img style={{width: "60px"}} src={contact4} alt="contact"/>
          <h3 className="section-title">Bills & Claim Support</h3>
          <p className="section-description">Need bills or claim support?</p>
        </div>
        <div className="contact-info2">
          <p className="acc">Contact our Accounting Team</p>
          <a href="mailto:Bills@nvincy.com" className="contact-email">Bills@nvincy.com</a>
          <p className="contact-phone">+91 080 4115 1212</p>
        </div>
    </div>
        </div>


        <div className='cust-container' style={{paddingTop: "30px"}}>
          <div className='white_bg_bg'>
            <div className='row'>
              <div className="col-12 col-md-6">
                <img src={contact5} alt="mission"/>
              </div>
              <div className="col-12 col-md-6 mission mt-5">
                <h2>About</h2>
                <p>Nvincy is more than just an AV/IT & marketing solutions provider; we’re your partner in strategizing and crafting unforgettable experiences. <br/><br/> We’re about 
                sparking conversations, igniting emotions, and driving results. 
                With our expertise in digital signage, app development, and audio-visual solutions, we empower brands to connect with their audience on a deeper level.</p>
              </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "30px"}}>
          <div className='white_bg_bg'>
            <div className='row'>
            <div className="col-12 col-md-6 mission mt-5">
                <p>We are ONE.</p>
                <h2>Our Goals</h2>
                <p>Fulfilling your needs with complete satisfaction and happiness is what pulls us 
                to work on more innovations for tomorrow. <br/><br/> You can share your feedback or journey with us, letting every user read and experience our journey together.</p>
              </div>
              <div className="col-12 col-md-6">
                <img src={contact6} alt="vision"/>
              </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "220px"}}>
        <div className="customer-support-container">
        <div className="support-text">
        <img src={contact8} alt="contact"/>
          <h3 className="support-title  mt-3 htag">Contact Our Support Team</h3>
          <p className="support-description">
            Our Customer Service team will always be ready to host you...
          </p>
          <a href="mailto:Support@nvincy.com" className="support-email">
            Support@nvincy.com
          </a>
          <p className="support-phone">+91 080 4115 1212</p>
        </div>
        <div className="support-image-container">
          <img
            src={contact7} // Replace with the actual image path
            alt="Customer Support"
            className="support-img"
          />
        </div>
    </div>
        </div>


        <div className='cust-container' style={{paddingTop: "60px"}}>
        <div className="row">
        {/* Google Map Embed */}
        <div className="col-md-6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.151201449655!2d77.63150557588786!3d13.026041713680732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae11318355a6ff%3A0x99ee20745dc47fe4!2sNvincy%20Solutions%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1728542741489!5m2!1sen!2sin"
            width="100%"
            height="600"
            allowFullScreen=""
            loading="lazy"
            style={{ border: '0' }}
            title="Company Location"
          ></iframe>
        </div>

        {/* Contact Information */}
        <div className="col-md-6 d-flex flex-column justify-content-center map-right">
          <h4 className="htag">Address and Location</h4>
          <h3 style={{ color: '#05a54e', fontFamily: 'SF Pro Display, sans-serif' }}>
            Address
          </h3>
          <p>1st Floor, No.207, 5th Cross,<br />
            3rd Block, HRBR Layout,<br />
            Kalyan Nagar,<br />
            Bangalore - 560 043</p>

          <h3 style={{ color: '#05a54e', fontFamily: 'SF Pro Display, sans-serif' }}>
            Email
          </h3>
          <p>sales@nvinciy.com<br />support@nvinciy.com</p>

          <h3 style={{ color: '#05a54e', fontFamily: 'SF Pro Display, sans-serif' }}>
            Contact
          </h3>
          <p>+91 080 4115 1212</p>
        </div>
        </div>
      </div>


        
    </div>
       
  )
}

export default About
