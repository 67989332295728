import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './About.css';
import about1 from '../../Assets/about1.svg';
import about2 from '../../Assets/about2.svg';
import about3 from '../../Assets/about3.svg';
import about4 from '../../Assets/about4.svg';
import about5 from '../../Assets/about5.svg';
import about6 from '../../Assets/about6.svg';
import about7 from '../../Assets/about7.png';
import about8 from '../../Assets/about8.svg';
import about9 from '../../Assets/about9.svg';
import legacy from '../../Assets/legacy.svg';
import commitment from '../../Assets/Commitment.svg';
import value from '../../Assets/Value.svg';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import haris from '../../Assets/haris.jpg';
import saugato from '../../Assets/saugato.jpg';
import green from '../../Assets/nvincygreen.png';
// import TeamMemberCard from '../TeamMemberCard/TeamMemberCard';
import FAQSection from '../FAQ/Faq';
import Aboutmyteam from '../OwlCarousel/AboutMyteam';
import CounterRow from '../Statistics/Statistics';
import { Helmet } from 'react-helmet-async';
import link from '../../Assets/LinkedIn_icon.svg'
// import { Row, Col } from 'react-bootstrap';
// import { Card, Button, Modal } from 'react-bootstrap';


const About = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const handleShow = (member) => {
    setSelectedMember(member);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setSelectedMember(null);
  };



  return (
    <div>
     <div>
            <Helmet>
                <title>Nvincy Solutions</title>
                <meta name="Audio Visual Equipment & Solutions Provider Online" content="Nvincy offers the best AV/IT products and is a leading provider of digital signage and audio-visual solutions. Enquire Now & Upgrade To Advanced AV Technology Now!" />
                <meta name="keywords" content="Digital Signage Experts, Audio Visual Solutions, Marketing Solutions Provider, Best AV/IT Products, Audio Visual Solution Provider" />
            </Helmet>
        </div>
        <div class="about-sub_banner">
          <div className="product-banner-content">
            <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>Captivate. Connect. Convert.</span>
                <span class="ms-2 text-success">--</span>
            </div>
            <h1 className='product-h1'>
            Let's Elevate your Brand, <br/> Engage Your Audience, <br/> and Drive the Best Results.
            </h1>
            {/* <div className="carousel-buttons">
                <button className="btn-primary">View All</button>
            </div> */}
          </div>
        </div>
       

        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg_bg'>
            <div className='row'>
              <h2>Visual Audio Equipment Providers</h2>
              <div className='col-12 col-md-9'>
                <p className='font-weight1 about1'>
                <h3>Beyond the Ordinary</h3>
                Nvincy is more than just an AV/IT and marketing solutions provider. We're your strategic partner in crafting unforgettable experiences 
                that resonate with your audience. Our expertise in digital signage, app development, and audio-visual solutions empowers you to connect on a 
                deeper level, drive results, and ignite emotions.
                </p>
              </div>
              <div className='col-12 col-md-3'>
                <img src={about1} alt="about1" className='img-fluid'></img>
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-12 col-md-3'>
                <img src={legacy} alt="about1" className='img-fluid'></img>
              </div>
              <div className='col-12 col-md-9'>
                <p className='font-weight1 about1'>
                <h3>A Legacy of Excellence</h3>
                With over 20 years of experience in the AV/IT industry, Nvincy has established itself as a trusted partner for businesses across South India with the 
                best AV/IT products and accessories around the world. 
                Our team of technical experts brings a wealth of knowledge and expertise to every project, whether it's an audiovisual need or a marketing goal.
                </p>
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-12 col-md-9'>
                <p className='font-weight1 about1'>
                <h3>Value-Added Services</h3>
                We are a value-added distributor offering more than just products. We provide comprehensive solutions, including:
<br/>
<b>Market Analysis:</b> Understanding your target audience and identifying opportunities.<br/>
<b>Custom Solutions:</b> Tailoring our offerings to meet your specific needs.<br/>
<b>Technical Support:</b> Ensuring seamless integration and ongoing assistance.
                </p>
              </div>
              <div className='col-12 col-md-3'>
                <img src={value} alt="about1" className='img-fluid'></img>
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-12 col-md-3'>
                <img src={commitment} alt="about1" className='img-fluid'></img>
              </div>
              <div className='col-12 col-md-9'>
                <p className='font-weight1 about1'>
                <h3>Our Commitment</h3>
                Nvincy dedicates itself to assisting you in achieving your communication objectives. 
                We're passionate about creating impactful experiences that leave a lasting impression. 
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
          <div className='purple_bg'>
            <div className='row'>
                <div className='col-12 col-md-2 enter2'>
                  <img src={about2} alt="about2"></img>
                </div>
                <div className='col-12 col-md-10'>
                <h3 className='enter1 htag'>Pro-Grade Audio Visual Solutions Provider</h3>
                <p className='enter1'> Beyond Displays, We Create Digital Experiences</p>
                  <p className='font-weight1 enter3 enter1'>
                 
We have often heard of those static messages unnoticed and pamphlets thrown away. We always strive to make advertising your brand a superior choice, over other traditional marketing methods. We've successfully brought digital signage to life to increase your leads to conversions. At Nvincy, we believe your brand deserves more. 
We're not just a digital signage company or an AV/IT retailer—we're storytellers too. We craft dynamic experiences that captivate your audience and ignite connections.
                  </p>
                </div>
            </div>
          </div>
        </div>

        <h4 style={{textAlign: "center"}} className='mt-5 htag'>Why are we The Digital Signage Experts?
</h4>

        <div className='cust-container' style={{paddingTop: "60px"}}>
          <div className='green_bg'>
            <div className='row'>
                <h2>Tailored Solutions</h2>
                <div className='col-12 col-md-9'>
                  <p className='font-weight1 about1'>We showcase all the possibilities and leave the curation to you so that you can create your 

                  ideation plan according to your needs. <br/><br/>Our promise? To equip you with the most innovative solutions in digital signage, app development, and audio-visual technology. This commitment fuels satisfied clients and positions Nvincy as a leader in the communication landscape.</p>
                </div>
                <div className='col-12 col-md-3 abt'>
                  <img src={about3} alt="about1"></img>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
          <div className='white_bg_bg'>
            <div className='row'>
                <h2>Storytelling at its Core</h2>
                <div className='col-md-9'>
                  <p className='font-weight1 about1'>We weave compelling narratives that resonate with your audience, 
                  turning viewers into leads and then into active customers.</p>
                </div>
                <div className='col-md-3 abt'>
                  <img src={about4} alt="about4"></img>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
          <div className='row'>
            <div className='col-12 col-md-4 green_bg padding'>
              <img src={about5} alt="about5" className='img-fluid'></img>
              <h2 className='mt-4'>Engagement is everything</h2>
              <p className='mt-4'>
                We design interactive and <br /> dynamic content that keeps eyes <br />
                glued to your displays. No more <br /> passive leaving out.
              </p>
            </div>
            <div className='col-12 col-md-8 purple_bg padding'>
              <img src={about6} alt="about6" className='img-fluid'></img>
              <h2 className='mt-4'>Innovation fuels <br /> creativity</h2>
              <p className='mt-4'>
              We stay ahead of the curve, offering user-friendly technology and tailored services to elevate your brand image.
              </p>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
          <div className='white_bg_bg'>  
          <div className='row'>
              <div className='col-md-6 success'>
                  <img src={about7} alt="about5"></img>
                </div>
                <div className='col-md-6 results'>
                  <h2>Results-Driven Approach</h2>
                  <p>We work tirelessly to achieve your business objectives, whether it’s driving sales, increasing brand 
                  awareness, or enhancing customer engagement.</p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "60px"}}>
              <div className="row">
                  <div className="col-md-6"><h2 className="statistics"><b>Exceeding Expectations </b> <br/> <span className="font-weight1">and Making a Mark</span></h2></div>
                  <div className="col-md-6 stats"><CounterRow/></div>
              </div>
        </div> 

        <div className='cust-container' style={{paddingTop: "60px"}}>
            <div className='row'>
                <div className='col-md-6 col-sm-12 white_bg_bg padding mission' >
                  <img src={about8} alt="about5"></img>
                  <h2 className='mt-4'>Mission</h2>
                  <p className='mt-4'>Nvincy's on a mission to identify global brands that resonate best with Indian consumers and establishes strategic partnerships to bring them to India. <br/> In short, we are bridging the gap between international AV brands and the Indian market. Also, with our expertise in digital signage, app development, 
                  and audio-visual solutions, we create immersive experiences that captivate audiences and drive tangible results making a lasting impression.</p>
                </div>
                <div className='col-md-6 white_bg_bg padding mission vision' style={{marginLeft: "40px"}}>
                  <img src={about9} alt="about6"></img>
                  <h2 className='mt-4'>Vision</h2>
                  <p className='mt-4'>To become the leading innovator in shaping how brands connect with audiences through technology. To envision a world where digital signage, app development, 
                  and AV/IT solutions seamlessly blend to create impactful interactions that define the future of communication.</p>
                </div>
            </div>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
          <h2 className='text-center'>Meet our Leadership</h2>
          <p className='text-center'>The team leading us into the future of AV/ IT and Digital Signage -driven business solutions.</p>
          <div className='row mt-5'>
          <div className='col-12 col-md-6 team-profile'>
        <img src={haris} alt="Mohammed Haaris A" className='haris' />
        <div className='d-flex justify-content-between mt-5'>
          <h3>Mohammed Haaris A</h3>
          <a href='https://in.linkedin.com/in/mohammed-haaris-cts-54067b13' target='_blank' rel="noreferrer">
            <img src={link} alt='LinkedIn' />
          </a>
        </div>
        <p className='mt-3'>
          <b>Director - <i>Nvincy Solutions Pvt Ltd <br /> Bengaluru, Karnataka, India</i></b>
        </p>
        <button onClick={() => handleShow('haris')} className='button_1 d-flex justify-content-center'>Explore</button>
      </div>

      {/* Saugato Siddhanta */}
      <div className='col-12 col-md-6 team-profile'>
        <img src={saugato} alt="Saugato Siddhanta" className='saugato' />
        <div className='d-flex justify-content-between mt-5'>
          <h3>Saugato Siddhanta</h3>
          <a href='https://www.linkedin.com/in/saugato-siddhanta-10615117' target='_blank' rel="noreferrer">
            <img src={link} alt='LinkedIn' />
          </a>
        </div>
        <p className='mt-3'>
          <b>Director - <i>Nvincy Solutions Pvt Ltd <br /> Bengaluru, Karnataka, India</i></b>
        </p>
        <button onClick={() => handleShow('saugato')} className='button_1 d-flex justify-content-center'>Explore</button>
      </div>
          </div>

          <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="profile-popup">
          <div className="profile-gradient">
            <div className='d-flex justify-content-between'>
              <img
                src={selectedMember === 'haris' ? haris : saugato}
                alt={selectedMember === 'haris' ? "Mohammed Haaris A" : "Saugato Siddhanta"}
                className='profile-image'
              />
              <img src={green} alt="Decorative Icon" className='green' />
            </div>
            <div className="profile-info d-flex justify-content-between">
              <div className='mt-3'>
                <p><b>Director</b> - Nvincy Solutions Pvt Ltd</p>
                <p>
  {selectedMember === 'haris' ? (
    <>
      Previously Manager Application at Harman Professional Solutions,<br />
      Indian Institute of Management, Bangalore.
    </>
  ) : ""}
</p>
              </div>
              <div>
                <h3 className='director mt-3'>Director Insights</h3>
                <p>
                  Insights from our Director on {selectedMember === 'haris' ? "entrepreneurship and building businesses" : "entrepreneurship and building businesses"}
                </p>
              </div>
            </div>
          </div>

          {/* Additional Info Paragraphs */}
          {selectedMember === 'haris' ? (
            <>
              <p className="additional-info">
              Harris’s journey into the world of technology and business began in his formative years when he demonstrated an innate curiosity about how things worked. Growing up in a middle-class family in Bangalore, he had access to a variety of gadgets, which ignited his passion for electronics. His formal education in engineering laid the groundwork for his technical expertise, where he not only excelled academically but also participated in various extracurricular projects related to audio-visual technology.
              </p>
              <p className="additional-info">
              Upon graduating with an engineering degree, Harris joined a prominent multinational company specializing in audio-visual systems. Here, he spent several years understanding client needs to working on large-scale installations that required meticulous planning and execution. His role exposed him to the complexities involved in delivering audio-visual solutions, igniting a desire to do something more impactful. Recognizing a gap in the market for superior audio-visual systems tailored to client-specific needs, Harris envisioned creating a company that would prioritize quality and innovation. This vision became clearer during his interactions with clients who often expressed frustration with the one-size-fits-all approach of existing providers.
              </p>
              <p className="additional-info">
              In 2013, alongside his business partner Saugato, Harris founded Nvincy Solutions Pvt. Ltd. With an unwavering commitment to delivering cutting-edge technology solutions, the company quickly differentiated itself in the competitive AV market. Harris’s technical acumen allowed him to curate a selection of products from high-quality brands.
              </p>
              <p className="additional-info">
              What sets Harris apart is his insatiable thirst for knowledge and continuous improvement. He stayed ahead of industry trends by attending technology expos, seminars, and workshops, ensuring that Nvincy remained at the highest peak of technological advancements. His vision has transformed the company into a trusted name, exemplifying superior quality and innovative solutions in the industry. His journey is a testament to how passion, vision, hard work, and dedication can converge to create a thriving business that impacts the lives of many.
              </p>
            </>
          ) : (
            <>
              <p className="additional-info">
              Saugato was always enthralled by the nuances of business and consumer interaction. Saugato's career path was one of quick development. He immediately established himself in leadership roles by proving mastery of client connection building and channel management. Early on he understood that strong relationships may foster consumer loyalty, laying the foundation for his next projects.
              </p>
              <p className="additional-info">
              When Harris came to him about launching Nvincy in 2013, Saugato saw it as a chance to combine Harris's technical knowledge with his strategic acumen. Together, they saw a business that would not only provide best-in-class audio-visual solutions but also give a first-rate client experience priority. Their company concept was formed based on this cooperation, which combines strategy with creativity to differentiate them in the market. Taking leadership of the company's strategic direction, Saugato carefully investigated market prospects and found possible obstacles. His analytical abilities helped him to react fast to shifting market conditions, thereby keeping Nvincy competitive. 
              </p>
              <p className="additional-info">
              Saugato stressed the need to develop a distinctive brand identity, which would appeal especially to their target market. He oversaw initiatives to produce a strong brand message stressing Nvincy's dedication to excellence and originality. Under his direction, the business developed a niche in the market for audio-visual solutions, servicing well-known customers and landing multiple major contracts. Often leading the discussions, Saugato closed deals that fit the vision and objectives of the business by using his remarkable communication abilities. Apart from his achievements in his career, Saugato stayed very committed to social responsibility.
              </p>
              <p className="additional-info">
              Saugato's path blends strategic acuity, leadership, and a strong dedication to excellence. By means of his efforts, Nvincy not only became a leader in audio-visual solutions but also set benchmarks for client happiness and quality. His narrative shows the great influence of vision, dedication and strategy in turning a fresh idea into a profitable company that keeps developing.
              </p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} className='button_1'>Close</Button>
        </Modal.Footer>
      </Modal>


          {/* <Modal show={showModal} onHide={handleClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="profile-popup">
              <div className="profile-gradient">
                <div>
                  <div className='d-flex justify-content-between first'>
                    <img src={saugato} alt="Mohammed Haaris A" className='profile-image' />
                    <img src={green} alt="Mohammed Haaris A" className='green' />
                  </div>
                  <div className="profile-info d-flex justify-content-between">
                    <div className='mt-3'>
                      <h3>Saugato Siddhanta<a href='https://www.linkedin.com/in/saugato-siddhanta-10615117?jobid=1234&lipi=urn%3Ali%3Apage%3Ad_jobs_easyapply_pdfgenresume%3Bx1%2F3GPL2S%2Bm%2BZnYFS1F8uA%3D%3D&licu=urn%3Ali%3Acontrol%3Ad_jobs_easyapply_pdfgenresume-v02_profile' target='_blank' rel="noreferrer"><img src={link} alt='linkedin'></img></a></h3>
                      <p><b>Director</b> - Nvincy Solutions Pvt Ltd</p>
                    </div>
                    <div>
                    <h3 className='director mt-3'>Director Insights</h3>
                    <p>Insights from our Director on entrepreneurship and building businesses</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="additional-info">
                A High Impact, Seasoned Professional and Business leader with vast experience in setting up and growing businesses; Areas of expertise include business management, sales strategy, installed audio-video systems.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose2} className='button_1'>Close</Button>
            </Modal.Footer>
          </Modal> */}

        </div>
        

        <div className='cust-container' style={{paddingTop: "150px"}}>
          <h5 className='htag'>Digital Signage Experts To Your Assistance </h5>
          <Aboutmyteam/>
        </div>

        {/* <div className="container-fluid hero-section d-flex justify-content-center align-items-center">
          <div className="hero-content text-center">
            <h2 style={{fontSize: "60px"}}>Stay Updated</h2>
            <p style={{marginTop: "40px"}}>You are everything to us subscribe to get our special <br/> discounts, the latest news, and more at your fingertips.</p>
            <button className="button_3 font-weight1" style={{marginTop: "40px"}}>Hi five </button>
          </div>
        </div> */}

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT 
            solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.

</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 
    </div>
  )
}




export default About
