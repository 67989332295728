import React from 'react';
import './Maxhub.css'
import MaxhubKey from '../OwlCarousel/MaxhubKey';
import MaxhubPdt from '../OwlCarousel/MaxhubPdt';
import MaxhubCover from '../OwlCarousel/MaxhubCover';
// import BrightSignLS from '../OwlCarousel/BrightSignLS';
// import trusted_1 from '../../Assets/trusted_1.png';
// import trusted_2 from '../../Assets/trusted_2.png';
// import trusted_3 from '../../Assets/trusted_3.png';
// import trusted_4 from '../../Assets/trusted_4.png';
// import trusted_5 from '../../Assets/trusted_5.png';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/MaxhubFAQ';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Maxhub</title>
                <meta name="Buy Advanced AV/IT Solutions With Microphone Mist Technology" content="Discover World's Best-In-Class Collaboration Technology, With Touch Screen Displays, Audio Visual Solutions, and Microphone Mist For Effective Communication." />
                <meta name="keywords" content="Collaboration Technology, Touch Screen Displays, Audio Visual Solutions, Microphone Mist Technology, Video Conferencing" />
            </Helmet>
        </div>

        <div class="maxhub-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1 maxhub'>
            Nureva + MAXHUB: Creating a Meeting Space <br/> Where Everyone Feels Connected
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.nureva.com/ecosystem/maxhub' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
            <p>Meet the dream team</p>
                <h1 className='htag'>Innovative Collaboration Technology</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    MAXHUB is a leading global provider of collaborative technology solutions for workplaces and educational institutions. Their innovative products and services enable seamless communication, collaboration, and engagement between people, helping to drive business growth and academic success. With a strong focus on research and development, MAXHUB continuously pushes the boundaries of what is possible in the world of collaboration technology.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Why Choose Our Audio Visual Solutions?
</h2>
            <MaxhubKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Microphone Mist Technology For Superior Audio
</h3>
<MaxhubPdt/>
            {/* <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Business and Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">My Team</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Broad">Broadcaster</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">T Pod</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <LaiaBusiness/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <LaiaTeam/>
                </Tab.Pane>

                <Tab.Pane eventKey="Broad">
                    <LaiaBroad/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <LaiaPod/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container> */}
        <div className="middle-button">
            <a target='_blank' href='https://www.nureva.com/ecosystem/maxhub' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>High-Quality Video Experience
</h4>
            <div className='line-up'>
                <h4>Nureva HDL410 System
                :</h4>
                <p>At the top of the Nureva audio line, the HDL410 system is engineered for extra-large meeting rooms and classrooms. Equipped with Microphone Mist technology, the HDL410 ensures comprehensive audio coverage for the most expensive collaboration spaces, delivering pro AV performance and features. This system is designed to provide an immersive, Teams-ready experience for larger meeting areas.
</p>
            </div>
            <div className='line-up'>
                <h4>Nureva HDL310 System Pro Series
                : </h4>
                <p>The HDL310 Pro Series offers pro AV/IT features and performance for large meeting rooms and classrooms. These systems harness the power of Microphone Mist technology to deliver crisp, clear audio coverage, providing comprehensive audio capture for collaborative spaces. The HDL310 Pro Series systems are designed to enhance the audio experience in larger rooms and facilitate more productive, inclusive meetings.
</p>
            </div>
            <div className='line-up'>
                <h4>LCD (Liquid Crystal Display)
                :
</h4>
                <p>LCD displays use liquid crystals to produce images on a flat panel. They work by using an electric current to control the orientation of the liquid crystal molecules, which affects how light passes through the display.
</p>
            </div>
            <div className='line-up'>
                <h4>OLED (Organic Light-Emitting Diode)
                :</h4>
                <p>OLED displays are a type of self-emissive flat-panel display that use organic compounds to emit light when an electric current is applied. Unlike LCD displays, which rely on a backlight, each pixel in an OLED display can produce its own light. 
</p>
            </div>

            <div className='line-up'>
                <h4>Laia Wall Mount
                :</h4>
                <p>A designed solution for securely mounting Laia cameras on walls, allowing users to optimize their conference room setup.
</p>
            </div>

            <div className='line-up'>
                <h4>LED (Light-Emitting Diode)
                :</h4>
                <p>LED displays utilize light-emitting diodes to create images on a flat panel. LEDs are semiconductor devices that emit light when an electric current passes through them. They are commonly used in a variety of applications, including digital billboards, traffic signals, and sports arena scoreboards.
</p>
            </div>
            <div className='line-up'>
                <h4>TCP20T Touch Control Panel
                :</h4>
                <p>The MAXHUB TCP20T touch control panel is designed to enhance collaboration and presentation experiences in Microsoft Teams Rooms. Featuring a high-clarity, anti-glare 10.1-inch full-HD touch screen displays, the TCP20T provides an intuitive and responsive interface for meeting participants. 
</p>
            </div>
            <div className='line-up'>
                <h4>TCP30T Touch Control Panel
                :</h4>
                <p>The MAXHUB TCP30T touch control panel is a larger and more feature-rich option for Microsoft Teams Rooms. Equipped with an 11.6-inch capacitive touch screen displays, the TCP30T delivers1920 x 1080 high-resolution visuals. When paired with Nureva's audio solutions, the TCP30T helps create an immersive and productive Microsoft Teams meeting experience.
</p>
            </div>
            <div className='line-up'>
                <h4>MAXHUB XC13T Mini PC
                :</h4>
                <p>The MAXHUB XC13T mini PC is a compact and powerful solution designed specifically for Microsoft Teams Rooms. Featuring the12th generation Intel® Core™ i3-1220P processor and TPM 2.0 security, the XC13T delivers reliable and efficient performance to power your collaborative meetings. Thus, the XC13T mini PC helps create a cohesive and feature-rich Microsoft Teams experience.
</p>
            </div>

            <div className='line-up'>
                <h4>MAXHUB XC25T Mini PC
                :</h4>
                <p>The MAXHUB XC25T mini PC is a step up in terms of performance and capabilities for Microsoft Teams Rooms. Powered by a 12th generation Intel Core i5 processor, the XC25T offers enhanced processing power and efficiency. With 256 GB of storage and 16 GB of DDR4 memory, this mini PC can handle demanding collaboration and presentation tasks with ease.
</p>
            </div>
            <div className='line-up'>
                <h4>MAXHUB UC W31 4K USB Camera
                :</h4>
                <p>The MAXHUB UC W31 4K USB camera is a high-performance video conferencing solution designed for Microsoft Teams Rooms. Featuring an advanced 1/2.3" 12 MP Sony sensor, the UC W31 delivers exceptional image quality with natural color reproduction. 
</p>
            </div>
            <div className='line-up'>
                <h4>MAXHUB UC P30 4K USB Camera
                :</h4>
                <p>The MAXHUB UC P30 4K USB camera is a versatile and advanced video conferencing solution for Microsoft Teams Rooms. Featuring a dual-lens design, the UC P30 combines a main camera with an 8MP sensor, 12x optical zoom, and a 90-degree field of view, with a panoramic camera boasting an 8 MP sensor and a 120-degree wide-angle field of view. 
</p>
            </div>
            <div className='line-up'>
                <h4>INOGENI Cam 230 selector
                :</h4>
                <p>The MAXHUB Multi-Camera Switch is a versatile and user-friendly solution designed to enhance the video conferencing capabilities within Microsoft Teams Rooms.  The Multi-Camera Switch is compatible with all USB and HDMI cameras, providing flexibility and eliminating the need for specialized drivers. 
</p>
            </div>
          </div>
    </div>

    <div className='cust-container' style={{paddingTop: "150px"}}>
        <h4 className='htag'>We’ve Got You Covered</h4>
        <p>Our Teams-certified audio systems are the ideal complement to MAXHUB’s room solutions. 
        And with ongoing firmware updates to meet your evolving needs, the Nureva® products you buy today will only get better over time. Explore some of your options below.</p>
        <MaxhubCover/>
    </div>

   

        {/* <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Countless brands worldwide have relied on Nvincy for their audio-visual requirements. <br/>
            We have become synonymous with audio visual solutions and the brand value we <br/> have gained over the years is a testament to that.</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div>  */}

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Boost Productivity with Our Interactive AV/IT Technology
</h5><br/>
            <p>At MAXHUB, we are committed to driving innovation in the world of collaboration technology. Our future vision is to continue to push the boundaries of what is possible in the world of collaboration, enabling seamless communication, collaboration, and engagement between people. We plan to achieve this through continuous research and development to stay ahead of the curve in collaboration technology, and by investing in emerging technologies, such as AI and machine learning, to further enhance the collaboration experience.
</p>
            <a target='_blank' href='https://www.nureva.com/ecosystem/maxhub' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Maxhub and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
