import React from 'react';
import './Harman.css'
import HarmanKey from '../OwlCarousel/HarmanKey';
import HarmanProduct from '../OwlCarousel/HarmanProduct';
// import BrightSignLS from '../OwlCarousel/BrightSignLS';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
// import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/HarmanFAQ';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Harman</title>
                <meta name="Harman Car Audio: Buy Smart Audio Systems for Cars" content="Harman Offers Pro-Grade Car Audio Systems, With The Blend of Automotive Design Excellence and Professional Audio Technology for Seamless Audio Experience." />
                <meta name="keywords" content="Car Audio systems, IoT solutions, automotive design, smart home systems, professional audio" />
            </Helmet>
        </div>

        <div class="harman-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Beyond Sound: Creating Sonic <br/> Masterpieces
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.harman.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Harman Car Audio: Experience the Difference</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    As a subsidiary of Samsung, the brand integrates cutting-edge technology with a passion for design, ensuring that every product enriches life’s moments, whether on the road or in the home. With over 70 years of expertise in acoustic design and tuning, HARMAN’s automotive audio systems deliver unparalleled sound quality in vehicles, transforming in-cabin listening into a premium experience.
            <br/><br/> HARMAN International stands at the forefront of innovation, transforming the way we connect with technology in our daily lives. With a commitment to enhancing user experiences through sound and connectivity, HARMAN leverages its extensive expertise in the automotive, consumer, and professional audio sectors. 
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'> Premium Car Audio Sytems by Harman</h2>
            <HarmanKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5"> Explore Harman's Range of Car Audio Systems
</h3>

<HarmanProduct/>
            {/* <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Measurement Microphones</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Installed Sound Microphones</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Where Intelligibility Meets Performance</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <EarthWorksMeasurement/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <EarthWorksInst/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <BrightSignLS/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container> */}
        <div className="middle-button">
            <a target='_blank' href='https://www.harman.com/' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Advanced Audio Technologies
</h4>
            <div className='line-up'>
                <h4>HARMAN Ignite
                :</h4>
                <p>A comprehensive platform designed to enhance the vehicle ownership experience, HARMAN Ignite spans everything from pre-purchase assistance to post-purchase service, ensuring a seamless transition throughout the car ownership lifecycle.
</p>
            </div>
            <div className='line-up'>
                <h4>Car Audio Systems
                : </h4>
                <p>With over seven decades of experience, HARMAN’s car audio systems offer superior sound quality that elevates any in-cabin listening experience. Trusted by over 50 million vehicles globally, their audio solutions meld performance and luxury.
</p>
            </div>
            <div className='line-up'>
                <h4>ADAS Solutions
                :
</h4>
                <p>HARMAN’s advanced driver-assistance systems enhance vehicle safety and driving efficiency by integrating cutting-edge technology into automotive design, paving the way towards fewer accidents on the road.
</p>
            </div>
            <div className='line-up'>
                <h4>Telematics Solutions
                :</h4>
                <p>HARMAN provides expansive telematics systems that enhance connectivity and data capabilities in vehicles, allowing for more dynamic interactions and functionalities in line with 5G advancements.
</p>
            </div>

            <div className='line-up'>
                <h4>Digital Cockpit
                :</h4>
                <p>This advanced cockpit environment brings together entertainment, safety, and connectivity, making the driving experience not only more enjoyable but also more secure and informed.
</p>
            </div>

            <div className='line-up'>
                <h4>HARMAN Automotive Engineering Services
                :</h4>
                <p>These services offer comprehensive engineering solutions designed to overcome complexities in the automotive design landscape, helping manufacturers deliver integrated and innovative experiences to consumers.
</p>
            </div>

            <div className='line-up'>
                <h4>Lifestyle Solutions
                :</h4>
                <p>HARMAN’s range of lifestyle products, from personal audio devices to smart home systems, reflects their ongoing commitment to enhancing everyday experiences through connectivity and quality sound.
</p>
            </div>

            <div className='line-up'>
                <h4>DM6 - Kick Drum Microphone
                :</h4>
                <p>The DM6 provides deep, powerful bass response and clarity, essential for achieving a full and rich kick sound.
</p>
            </div>

            <div className='line-up'>
                <h4>Professional Solutions
                :</h4>
                <p>HARMAN’s professional audio engineering delivers high-fidelity sound in concert venues, theaters, and broadcasting environments, ensuring optimal sound for any large audience.
</p>
            </div>

            <div className='line-up'>
                <h4>Sustainability Initiatives
                :</h4>
                <p>HARMAN is committed to sustainability, implementing practices that reduce environmental impact while still driving innovation and enhancing user experience across their product lines.
</p>
            </div>

            <div className='line-up'>
                <h4>Huemen Design
                :</h4>
                <p>This design team focuses on creating intuitive and immersive experiences that resonate with users, integrating a thoughtful approach to product aesthetics with functionality.
</p>
            </div>
          </div>
    </div>

   

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

       

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Enjoy the uniqueness of Smart Audio Systems
</h5><br/>
            <p>HARMAN International envisions a future centered around innovative and meaningful integration of technology into everyday life, driven by advancements in connectivity and intelligent systems. HARMAN aims to redefine how consumers interact with technology—creating richer experiences that are not only efficient but also enjoyable. They aspire to lead the charge in sustainable practices, ensuring that innovation harmonizes with environmental responsibility and social impact.
</p>
            <a target='_blank' href='https://www.harman.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Harman and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
