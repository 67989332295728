import React from 'react';
import CarouselComponent from '../OwlCarousel/OwlCarousel';
import ProductBrand from '../OwlCarousel/ProductBrand.js';
import TechCarousel from '../OwlCarousel/TechCarousel';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
// import FAQSection from '../FAQ/Faq';
import { Helmet } from 'react-helmet-async';
import './Products.css'

const products = () => {
  return (
    <div>
     <div>
            <Helmet>
                <title>Nvincy Solutions</title>
                <meta name="Professional Audio Systems | AV Presentation Equipment" content="Discover Nvincy's Range of High-quality Audio-Visual Equipment, Including Professional Audio Systems And LED Digital Signage Displays. Shop Now." />
                <meta name="keywords" content="Audio Sound System
conference audio visual equipment, High-definition audio & Video, Dante AV Integration, Professional Audio System" />
            </Helmet>
        </div>
      <div class="product-sub_banner">
        <div className="product-banner-content">
            <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>Wowwwwwws - In Queue</span>
                <span class="ms-2 text-success">--</span>
            </div>
            <h1 className='product-h1'>
                AV - Absolutely the Best.
            </h1>
            {/* <div className="carousel-buttons">
                <button className="btn-primary">View All</button>
            </div> */}

          </div>
        </div>
      <div className='cust-container' style={{paddingTop: "150px"}}>
        <h1 className='htag'>Professional Audio Visual Systems In High Demand</h1>
        <CarouselComponent/>
      </div>

      <div className='cust-container' style={{paddingTop: "150px"}}>
        <h2 style={{textAlign: "center"}}>High-Definition <br/> <span className='font-weight1'>Audio Video Systems</span></h2>
        <ProductBrand/>
      </div>

      <div className='cust-container' style={{paddingTop: "150px"}}>
        <h2 style={{textAlign: "center"}}>Exclusive Brands</h2>
        <TechCarousel/>
      </div>

      <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.

</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        {/* <div className="container-fluid hero-section d-flex justify-content-center align-items-center">
          <div className="hero-content text-center">
            <h2 style={{fontSize: "60px"}}>Stay Updated</h2>
            <p style={{marginTop: "40px"}}>You are everything to us subscribe to get our special <br/> discounts, the latest news, and more at your fingertips.</p>
            <button className="button_3 font-weight1" style={{marginTop: "40px"}}>Hi five </button>
          </div>
        </div>  */}

        {/* <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div>  */}

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
                <div className="newsletter-section">
                  <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
                  <p className="newsletter-description py-4">
                    Join our subscribers list to get the latest news, updates, and special
                    <br />
                    offers delivered directly to your inbox.
                  </p>
                  <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
                </div>
              </div>
        </div> 
 
        
    </div>
  )
}

export default products
