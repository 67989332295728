import React from 'react';
import aoto1 from '../../Assets/Maxhub/maxhubpdt1.jpeg';
import aoto2 from '../../Assets/Maxhub/maxhubpdt2.jpeg';
import aoto3 from '../../Assets/Maxhub/maxhubpdt3.jpeg';
import aoto4 from '../../Assets/Maxhub/maxhubpdt4.jpeg';
import aoto5 from '../../Assets/Maxhub/maxhubpdt5.jpeg';

//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Nureva HDL pro series
</h3>
            <p className='thro'>Nureva's HDL Pro: Microphone Mist, 360° coverage, auto-adjusting, clear conversations.
</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>MAXHUB Displays</h3>
            <p className='thro'>MAXHUB: high-quality displays, advanced tech, intuitive controls, boosting productivity.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>
            MAXHUB Touch Control Panels
</h3>
            <p className='thro'>MAXHUB touch panels: simple, powerful controls for AV, lighting, systems.
</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"

            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>MAXHUB Mini PCs</h3>
            <p className='thro'>MAXHUB mini PCs: powerful, reliable, seamless integration for versatile installations.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>MAXHUB Cameras</h3>
            <p className='thro'>MAXHUB cameras: professional-grade, high-quality video, clear visuals for conferencing.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>INOGENI CAM230 Selector</h3>
            <p className='thro'>INOGENI CAM230: compact video switcher, seamless camera switching for presentations.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>


  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;