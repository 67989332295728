import React from 'react';
import './Home.css';

import VideoBanner from '../VideoBanner/VideoBanner';
import CarouselComponent from '../OwlCarousel/OwlCarousel';
import TechCarousel from '../OwlCarousel/TechCarousel';
import UpdateCarousel from '../OwlCarousel/UpdateCarousel';
import TopProductCarousel from '../OwlCarousel/TopProductCarousel';
import FAQSection from '../FAQ/Faq';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import service_1 from '../../Assets/service1.png';
import service_2 from '../../Assets/service2.png';
import service_3 from '../../Assets/service3.png';
import service_4 from '../../Assets/service4.png';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import CounterRow from '../Statistics/Statistics';


const Home = () => {
  // const texts = [
  //   'Top Brands. Top Quality. Capture every moment',
  //   'Your One-Stop Shop for Pro-Grade AV Gear',
  //   'Impressive Audios for Every Stunning Visuals',
  //   'Pro-Grade Displays Assured with Premium Quality'
  // ];

  // const classNames = [
  //   'class-one',  // Class for the first text
  //   'class-two',  // Class for the second text
  //   'class-three', // Class for the third text
  //   'class-four' // Class for the third text
  // ];
  // const isLargeScreen = window.innerWidth >= 1024; // Adjust the threshold as needed
  // const [currentTextIndex, setCurrentTextIndex] = useState(0);
  // const [isTyping, setIsTyping] = useState(true);

  // useEffect(() => {
  //   const typingInterval = setInterval(() => {
  //     setIsTyping(false);
  //     setTimeout(() => {
  //       setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
  //       setIsTyping(true);
  //     }, 500);
  //   }, 6000);

  //   return () => clearInterval(typingInterval);
  // }, [texts.length]);

  // const formatText = (text) => {
  //   const words = text.split(' ');
  //   const firstThreeWords = words.slice(0, 3).join(' ');
  //   const restOfText = words.slice(3).join(' ');
  //   return (
  //     <>
  //       {firstThreeWords} <br /> {restOfText}
  //     </>
  //   );
  // };

  // const [videoUrl, setVideoUrl] = useState(Banner_video); // Default video for larger screens

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth < 480) {
  //       setVideoUrl(Banner_video_mobile); // Video for screens less than 480px
  //     } else {
  //       setVideoUrl(Banner_video); // Video for larger screens
  //     }
  //   };

  //   // Call the function on mount and resize
  //   handleResize();
  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    
    <div>
        
        <VideoBanner />
        <div className="enquire-btn-container">
            <button className="btn btn-success enquire-btn">
                ENQUIRE NOW
            </button>
        </div>

        <div className='cust-container move-up' style={{ paddingTop: "150px"}}>
          <div className='product-list'> 
            <h1 className='htag'>Exceptionally Performing <span className="font-weight1">Audio Visual Products</span></h1>
            <CarouselComponent/>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
              <div className="white_bg">
                {/* <p className='sub-heading'>AV Products and Solution Experts</p> */} 

                <h2 className='heading font-weight1'>Why Choose Our Digital Signage <span className="font-weight2">Services & Audio Visual Solutions?</span></h2>
                <p className='para1 pt-5'>We're your one-stop shop for all your diverse needs. We offer a curated collection of top branded products at 
                competitive prices and excellent customer service. We work with a range of clients - 
                from individual creators to large corporates - ensuring each receives personalized attention, comprehensive assistance and utmost support.</p>
                <a href="About"><button className='button_2 center' style={{ marginTop: "30px"}}>Read More</button></a>
              </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}> 
              <h3 className="font-weight2 htag">Our Partners in Audio <span className="font-weight3">Visual Technology…</span></h3>
              <TechCarousel/>
        </div> 

        <div className='cust-container services' style={{ paddingTop: "150px" }}>
            <h4 className='heading htag'> 
              <span className="font-weight4">Our Offerings in</span> <span className="font-weight2">Digital Signage Services</span><br />
            </h4>
            <div className='row pt-5 no-gutters'> {/* Add no-gutters to remove spacing */}
            <div className='col-md-6 col-12 position-relative mt-5 animation'>
            <img 
        src={service_1} 
        alt='service1' 
        style={{ width: '100%', height: '100%' }} // Inline styling for full width
      />
              <h3 style={{ textAlign: 'left' }} className='on_image'>Digital Signage <br /> Solutions</h3>
              <div className='initial_overlay'></div>
              <div className='overlay'>
                <div className='text'>
                  <p style={{ textAlign: 'left' }} className="font-weight1 go-left">
                    Make your statement strong with engaging <br /> and informative visual communications.
                  </p>
              </div>
              </div>
            </div>
  <div className='col-md-6 col-12 position-relative mt-5 animation'>
    <img src={service_2} alt='service2' style={{ width: '100%', height: '100%' }}/>
    <h3 style={{ textAlign: 'left' }} className='on_image'>Digital Signage <br /> Content Creation</h3>
    <div className='initial_overlay'></div>
    <div className='overlay'>
      <div className='text'>
        <p style={{ textAlign: 'left' }} className="font-weight1 go-left2">
          Give life to your brand with engaging <br /> and visually stunning content creations.
        </p>
      </div>
    </div>
  </div>
</div>

<div className='row pt-5 no-gutters'> {/* Add no-gutters to remove spacing */}
  <div className='col-md-6 col-12 position-relative mt-5 animation'>
    <img src={service_3} alt='service3' style={{ width: '100%', height: '100%' }}/>
    <h3 className='on_image'>Wayfinding <br /> Solutions</h3>
    <div className='initial_overlay'></div>
    <div className='overlay'>
      <div className='text_2'>
        <p className="font-weight1 go-left2 hello">
          Clear and intuitive signage to guide <br /> your audiences through complex spaces.
        </p>
      </div>
    </div>
  </div>
  <div className='col-md-6 col-12 position-relative mt-5 animation'>
    <img src={service_4} alt='service4' style={{ width: '100%', height: '100%' }}/>
    <h3 className='on_image'>Application <br /> Development</h3>
    <div className='initial_overlay'></div>
    <div className='overlay'>
      <div className='text_3'>
        <p className="font-weight1 go-left hello">
          Empower your business with custom-built <br /> applications tailored to your specific needs.
        </p>
      </div>
    </div>
  </div>
</div>



        </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
              <div className="white_bg1">
                <h2 className='heading'><span className="font-weight2">Get ready to be amazed</span> <br/> <span className="font-weight1">by the crystal-clear sound and 
                stunning visuals of our latest AV/IT products</span></h2>
                <UpdateCarousel/>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
              <div className="row">
                  <div className="col-md-6"><h2 className="statistics"><b>Exceeding Expectations </b> <br/> <span className="font-weight1">and Making a Mark</span></h2></div>
                  <div className="col-md-6 stats"><CounterRow/></div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2><span className="font-weight2">Fresh Releases </span> </h2>
          <TopProductCarousel/>
        </div> 

        {/* <div className="container-fluid hero-section d-flex justify-content-center align-items-center">
          <div className="hero-content text-center">
            <h2 style={{fontSize: "60px"}}>Stay Updated</h2>
            <p style={{marginTop: "40px"}}>You are everything to us subscribe to get our special <br/> discounts, the latest news, and more at your fingertips.</p>
            <button className="button_3 font-weight1" style={{marginTop: "40px"}}>Hi five </button>
          </div>
        </div> */}

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.

</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
                <div className="newsletter-section">
                  <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
                  <p className="newsletter-description py-4">
                    Join our subscribers list to get the latest news, updates, and special
                    <br />
                    offers delivered directly to your inbox.
                  </p>
                  <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
                </div>
              </div>
        </div> 
 
    </div>
    
  )
}

export default Home
