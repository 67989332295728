import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of products does WyreStorm offer?",
      answer: "WyreStorm provides a wide range of audio-visual solutions, including matrixes, switchers, extenders, collaboration devices, and more tailored for corporate, educational, and residential use.",
    },
    {
      question: "Can WyreStorm products be integrated with existing AV/IT systems?",
      answer: "Yes, WyreStorm's solutions are designed to be flexible and compatible with a variety of existing AV/IT systems, facilitating easy integration and scalability.",
    },
    {
      question: "What is AV/IT over IP technology?",
      answer: "AV/IT over IP refers to the transmission of audio-visual signals over standard Ethernet networks, maximizing flexibility and allowing for high-quality video distribution across various devices.",
    },
    {
      question: "How does WyreStorm ensure high-quality video performance?",
      answer: "WyreStorm uses cutting-edge technology and rigorous testing protocols in its products to ensure optimal video performance, even in demanding environments.",
    },
    {
      question: "What support does WyreStorm offer for product installation?",
      answer: "WyreStorm provides extensive support, including installation guides, technical documentation, and customer service to assist with product setup and troubleshooting.",
    },
    {
      question: "Are WyreStorm products suitable for residential use?",
      answer: "Absolutely! WyreStorm offers numerous solutions tailored for home theaters, media rooms, and home offices, enhancing every aspect of residential AV/IT system setups",
    },
    
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
