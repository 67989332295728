import React from 'react';
import './Siemon.css';
import Aoto1 from '../OwlCarousel/Siemon';
import AotoCommercial from '../OwlCarousel/Siemon-DataCenters';
import AotoCreative from '../OwlCarousel/Siemon-SmartBuildings';
import AotoRental from '../OwlCarousel/Siemon-CellularNetworks';
import FAQ from '../FAQ/SiemonFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Siemon</title>
                <meta name="High Quality AV & High-Performance Cabling Solutions" content="Delivering Superior AV Performance With High Performance Copper Cabling & Fiber Network Solutions For All Audio Video Needs. Enjoy Best Audio Video Quality." />
                <meta name="keywords" content="High quality audio and video, Innovative infrastructure solutions, High performance copper, Copper cabling solutions,Fiber network solutions " />
            </Helmet>
        </div>

        <div class="siemon-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Building the Future of <br/> Connectivity
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.siemon.com/en/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Superior AV Infrastructure</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    At Siemon, they believe in revolutionizing the way the world connects through cutting-edge infrastructure solutions. With a 
                    legacy spanning decades, Siemon is a global leader committed to delivering high-performance copper and fiber network systems for 
                    data centers, smart buildings, and cellular networks. <br/><br/> Siemon’s innovative spirit drives us to design solutions that empower organizations to harness their full potential and meet the challenges of tomorrow's connectivity landscape. They are dedicated to elevating the standard of network infrastructure to new heights, transforming visions into reality, and ensuring that our customers thrive in a digitally connected world.</p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>High-Quality AV Performance</h2>
            <Aoto1/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Audio Visual Integrations</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Data Centers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Smart Buildings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Cellular Networks</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AotoCreative/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AotoRental/>
                </Tab.Pane>

            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.siemon.com/en/' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Fiber Network Solutions</h4>
            <div className='line-up'>
                <h4>Copper Cabling Systems:</h4>
                <p>Our advanced copper cabling solutions provide high-speed connectivity with unmatched reliability, ideal for data centers and corporate networks.</p>
            </div>
            <div className='line-up'>
                <h4>Fiber Optic Solutions:</h4>
                <p>Siemon’s fiber optic offerings deliver superior bandwidth and resilience, making them perfect for high-performance data communication in challenging environments.</p>
            </div>
            <div className='line-up'>
                <h4>Smart Building Solutions:</h4>
                <p>Streamline your network infrastructure with our smart building solutions that enhance operational efficiency and connectivity within modern corporate spaces.</p>
            </div>
            <div className='line-up'>
                <h4>Cellular Network Infrastructure:</h4>
                <p>Our tailored infrastructure solutions support diverse cellular network deployments, ensuring seamless connectivity in complex environments.</p>
            </div>
            <div className='line-up'>
                <h4>Data Center Systems:</h4>
                <p>Built for high-demand environments, Siemon’s data center solutions optimize space and performance, allowing clients to fully utilize their IT infrastructure.</p>
            </div>
            <div className='line-up'>
                <h4>Patch Panel Solutions:</h4>
                <p>Improve organization and performance with our versatile patch panel solutions, designed for easy access and management of network connections.</p>
            </div>
            <div className='line-up'>
                <h4>Global Project Services (GPS):</h4>
                <p>Leverage our professional services for planning and managing IT infrastructure deployments across multiple sites, ensuring consistency and quality.</p>
            </div>
            <div className='line-up'>
                <h4>OEM Technologies:</h4>
                <p>Custom manufacturing and design services for industry leaders looking for specialized connectivity solutions that meet their unique needs.</p>
            </div>
            <div className='line-up'>
                <h4>Network Accessories:</h4>
                <p>A variety of essential accessories including connectors and adapters to enhance and maintain strong network performance.</p>
            </div>
            <div className='line-up'>
                <h4>Testing and Certification Services:</h4>
                <p>Our industry-standard testing ensures that your network solutions comply with the highest performance criteria, providing assurance in their reliability.</p>
            </div>
            
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 


        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Infrastructure Updates</h5><br/>
            <p>Looking ahead, Siemon envisions a world where connectivity is not just an expectation but a seamless experience that empowers organizations and 
            enriches lives. We are committed to advancing our innovative infrastructure solutions by leveraging emerging technologies such as IoT and AI to create 
            smarter, more efficient networks. <br/><br/>Their goal is to remain at the forefront of the industry, continuously adapting to the dynamic landscape of connectivity while maintaining our dedication to quality, sustainability, and customer success. Together with our partners and clients, we aim to build a more connected future that enhances capabilities across all sectors.</p>
            <a target='_blank' href='https://www.siemon.com/en/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        
        <div className='cust-container'>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Allsee and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
