import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of environments are Xilica products best suited for?",
      answer: "Xilica solutions are ideal for a wide range of environments including conference rooms, classrooms, lecture halls, courtrooms, and houses of worship.",
    },
    {
      question: "How easy are Xilica systems to install and configure?",
      answer: "Xilica prioritizes ease of use, with intuitive interfaces and comprehensive documentation to simplify installation and configuration.",
    },
    {
      question: "Are Xilica products compatible with my existing equipment?",
      answer: "Xilica products are designed for broad compatibility, but checking specific product specifications is recommended.",
    },
    {
      question: "What is the scalability of Xilica systems?",
      answer: "Xilica systems are highly scalable, adaptable to expanding needs.",
    },
    {
      question: "Do you offer custom solutions?",
      answer: "While we offer standard product lines, Xilica can collaborate with you on custom solutions.",
    },
    {
      question: "What network protocols do Xilica products support?",
      answer: "Xilica products support various standard network protocols; check the specific product specifications for details.",
    },
    
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
