import React from 'react';
import aoto1 from '../../Assets/BrightSIgn/BrightsignLegacy1.png';
import aoto2 from '../../Assets/BrightSIgn/BrightsignLegacy2.png';
import aoto3 from '../../Assets/BrightSIgn/BrightsignLegacy3.png';
import aoto4 from '../../Assets/BrightSIgn/BrightsignLegacy4.png';
import aoto5 from '../../Assets/BrightSIgn/BrightsignLegacy5.png';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Unsurpassed Process</h3>
            <p className='thro'>BrightSign XT delivers top performance and cutting-edge technology for enterprises.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Win big with BrightSign XD</h3>
            <p className='thro'>Advanced video engine decodes two 1080p60 videos with superior scaling.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Robust and Feature Rich</h3>
            <p className='thro'>BrightSign's updated HD line offers reliable performance and complete HTML5 support.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Affordable and Fully Featured</h3>
            <p className='thro'>BrightSign offers affordable, feature-rich players, surpassing consumer devices in installations.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>World's First True 4K Player</h3>
            <p className='thro'>BrightSign 4K is a reliable, fan-less player supporting stunning HDR playback.
</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>


  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;