import React from 'react';
import catchbox_img from '../../Assets/HomeOwl2/catchbox.png';
import catchbox_svg from '../../Assets/HomeOwl2/catchbox_svg.svg';
import brightsign_img from '../../Assets/HomeOwl2/brightsign.png';
import brightsign_svg from '../../Assets/HomeOwl2/brightsign_svg.svg';
import netgear_img from '../../Assets/HomeOwl2/netgear_img.png';
import netgear_svg from '../../Assets/HomeOwl2/netgear_svg.svg';
import nviccta_img from '../../Assets/HomeOwl2/nviccta_img.png';
import nviccta_svg from '../../Assets/HomeOwl2/nviccta_svg.svg';
import ew_img from '../../Assets/HomeOwl2/EW_img.png';
import ew_svg from '../../Assets/HomeOwl2/EW_svg.svg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import './UpdateCarousel.css';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 200,
  responsiveClass: true,
  nav: true, // Set to true to enable navigation
  autoplay: true, // Enable autoplay
  autoplayTimeout: 3500, // Autoplay every 2 seconds (2000 milliseconds)
  autoplayHoverPause: true, // Pause on hover
  dots: true, // Enable dots for pagination
  loop: false,
  smartSpeed: 500, // Set a faster transition speed (500 milliseconds)
  items: 1, // Ensure only one item is displayed at a time
  responsive: {
    0: {
      items: 1, // 1 item for small screens
    },
    600: {
      items: 1, // 1 item for medium screens
    },
    1000: {
      items: 1, // 1 item for large screens
    },
  },
  // Enable infinite looping
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>',
    '<i class="fas fa-chevron-right right-arrow"></i>',
  ],
};

class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card d-flex flex-row column update_card" style={{ width: "100% !important", borderColor: "white" }}>
        <div className="col-md-4 update-left">
            <img
                src={catchbox_img}
                className="img-fluid"
                alt="React Icon"
               // Ensures the image covers the full height
            />
        </div>
        <div className="col-md-8" style={{ paddingLeft: "60px" }}>
            <div className="card-body update cb">
                <p className="card-text" style={{ marginTop: "32px" }}>
                    Complete Wireless Microphone - Plus
                </p>
                <img
                src={catchbox_svg}
                alt="React Icon" style={{ width: "116px" }}/>
                <h4>The mixed signals are balanced <br/> for speakers or DSPs, and
                mic-level<br/> signals for each channel</h4>
                <button className='button_2'>Explore More</button>
            </div>
        </div>
    </div>

</div>
  <div className="item">
    <div className="card d-flex flex-row column update_card" style={{ width: "100% !important", borderColor: "white" }}>
        <div className="col-md-4 update-left">
            <img
                src={brightsign_img}
                className="img-fluid"
                alt="React Icon"
                 // Ensures the image covers the full height
            />
        </div>
        <div className="col-md-8" style={{ paddingLeft: "60px" }}>
            <div className="card-body update">
                <p className="card-text" style={{ marginTop: "32px" }}>
                    The Future of Visual Experience
                </p>
                <img
                src={brightsign_svg}
                alt="React Icon" style={{ width: "400px" }}/>
                <h4>Powerful features for captivating <br/> visual communication, 
                including high-resolution <br/> playback & content management.</h4>
                <button className='button_2'>Explore More</button>
            </div>
        </div>
    </div>
  </div>

  <div className="item">
    <div className="card d-flex flex-row column update_card" style={{ width: "100% !important", borderColor: "white" }}>
        <div className="col-md-4 update-left">
            <img
                src={netgear_img}
                className="img-fluid"
                alt="React Icon"
                // Ensures the image covers the full height
            />
        </div>
        <div className="col-md-8" style={{ paddingLeft: "60px" }}>
            <div className="card-body update">
                <p className="card-text" style={{ marginTop: "32px" }}>
                    AV Line M4250-9G1F-Poe + (Gsm4210Pd)
                </p>
                <img
                src={netgear_svg}
                alt="React Icon" style={{ width: "400px" }}/>
                <h4>Seamless audio transmission <br/> over existing Ethernet networks, <br/>
                freeing you from traditional <br/> cabling limitations.</h4>
                <button className='button_2'>Explore More</button>
            </div>
        </div>
    </div>
  </div>

  <div className="item">
    <div className="card d-flex flex-row column update_card" style={{ width: "100% !important", borderColor: "white" }}>
        <div className="col-md-4 update-leftt">
            <img
                src={nviccta_img}
                className="img-fluid"
                alt="React Icon"
                // Ensures the image covers the full height
            />
        </div>
        <div className="col-md-8" style={{ paddingLeft: "60px" }}>
            <div className="card-body update">
                <p className="card-text" style={{ marginTop: "32px" }}>
                    Video Conferencing USB Camera
                </p>
                <img
                src={nviccta_svg}
                alt="React Icon" style={{ width: "400px" }}/>
                <h4>Reliable connections for <br/> high-definition audio, video <br/> transmission 
                and effective video conferencing.</h4>
                <button className='button_2'>Explore More</button>
            </div>
        </div>
    </div>
  </div>

  <div className="item">
    <div className="card d-flex flex-row column update_card" style={{ width: "100% !important", borderColor: "white" }}>
        <div className="col-md-4 update-left">
            <img
                src={ew_img}
                className="img-fluid"
                alt="React Icon"
                // Ensures the image covers the full height
            />
        </div>
        <div className="col-md-8" style={{ paddingLeft: "60px" }}>
            <div className="card-body update ew">
                <p className="card-text" style={{ marginTop: "32px" }}>
                    23kHz Reference Measurement Microphone
                </p>
                <img
                src={ew_svg}
                alt="React Icon" style={{ width: "400px" }}/>
                <h4>Professional's Choice <br/> for studio recordings, 
                live performances, <br/> and broadcast applications.</h4>
                <button className='button_2'>Explore More</button>
            </div>
        </div>
    </div>
  </div>
  
  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;