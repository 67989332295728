import React from 'react';
import '../Nureva/Nureva.css';
import './Services.css';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import promise_1 from '../../Assets/promise_1.png';
import promise_2 from '../../Assets/promise_2.png';
import promise_3 from '../../Assets/promise_3.png';
import promise_4 from '../../Assets/promise_4.png';
import FAQSection from '../FAQ/ServicesFAQ';
import ServicesCarousel from '../OwlCarousel/ServiceCarousel';
import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <div>
    <Helmet>
                <title>Nvincy Solutions</title>
                <meta name="Best Digital Signage & AV Services | App Development " content="Nvincy is among the best digital signage companies. We provide top-tier digital signage advertising, app development, and comprehensive audio-visual solutions." />
                <meta name="keywords" content="Digital signage advertising, App Development Services, Digital Signage Services, Digital Signage Displays, Best digital signage companies" />
            </Helmet>
        <div class="services-sub_banner">
          <div className="product-banner-content">
            <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>Wowwwwws - In Queue</span>
                <span class="ms-2 text-success">--</span>
            </div>
            <h1 className='product-h1'>
            We’ve got everything <br/> you need!
            </h1>
            {/* <div className="carousel-buttons">
                <button className="btn-primary">View All</button>
            </div> */}
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
              <div className="white_bg">
                {/* <p className='sub-heading'>AV Products and Solution Experts</p> */}
                <h1 className='heading htag'>Digital Signage & AV/IT Solutions
</h1>
                <p className='para2 pt-3'>We are passionate about helping you craft impactful communication strategies. From creating engaging 
                digital signage to developing innovative apps, we offer a comprehensive suite of services to elevate your brand message and achieve 
                your communication goals to the best extent. Ping us to know how we can help you unlock the power of communication and transform your vision into reality.</p>
              </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
        <div className="promise-section">
        <h2 className="promise-h2">Our Promise for Digital 
        <br/> Signage Advertising</h2>
            <div className="promise-grid">
                <div className="promise-card promise1">
                <div className="promise-icon"><img src={promise_1} alt="pr1"></img></div>
                <h4 className="promise-h4">One-stop Solutions</h4>
                </div>
                <div className="promise-card promise2">
                <div className="promise-icon"><img src={promise_2} alt="pr1"></img></div>
                <h4 className="promise-h4">Expert Support & Guidance</h4>
                </div>
                <div className="promise-card promise3">
                <div className="promise-icon"><img src={promise_3} alt="pr1"></img></div>
                <h4 className="promise-h4">Customer Focused Approach</h4>
                </div>
                <div className="promise-card promise4">
                <div className="promise-icon"><img src={promise_4} alt="pr1"></img></div>
                <h4 className="promise-h4">Proven Track Record</h4>
                </div>
            </div>
            </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
        <h3 className='htag'>Digital Signage Advertising
</h3>
            <ServicesCarousel/>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 col-12 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 col-12 trusted mx-3">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 col-12 trusted mx-3">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div>
                <div className="col-md-2 col-12 trusted mx-3">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 col-12 trusted mx-3">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 
    </div>
  )
}

export default About
