import React from 'react';
import aoto1 from '../../Assets/Aoto/AotoRental1.png';
import aoto2 from '../../Assets/Aoto/AotoRental2.png';
import aoto3 from '../../Assets/Aoto/AotoRental3.png';
import aoto4 from '../../Assets/Aoto/AotoRental4.png';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>CLD RS Series</h3>
            <p className='thro'>Industry applications: DIGITAL MEDIA/SPORTS/EVENTS Pixel Pitch (mm): 1.5 Calibrated Brightness (nits): 800-1000 nits LED type : Mini 4 in 1</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>MXE Series</h3>
            <p className='thro'>Industry applications: TV STUDIO/EVENTS/SPORTS Pixel Pitch (mm): 1.8/2.8/3.8/4.8/5.8/6.8/8/10/12 Calibrated Brightness (nits): 800-6000 nits LED type : SMD 3 in 1</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>RM Series</h3>
            <p className='thro'>Industry applications: EVENTS/TRANSPORTATION/TV STUDIO Pixel Pitch (mm): 1.5/1.92/2.31 Calibrated Brightness (nits): 800 nits/1200nits/1500nits LED type : Mini 4 in 1</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>SP Series</h3>
            <p className='thro'>Industry applications: SPORTS/EVENTS Pixel Pitch (mm): 10/12.25/16.67 Calibrated Brightness (nits): 6000 nits LED type : SMD 3 in 1</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>


  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;