import React from 'react';
import './Cyviz.css'
import CyvizKey from '../OwlCarousel/CyvizKey';
import CyvizSol from '../OwlCarousel/CyvizSol';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/CyvizFAQ';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Cyviz</title>
                <meta name="Cyviz: Workplace Collaboration with Advanced Technology" content="Streamling Teamwork and Employee Collaboration With Cyviz's Remote Management Solutions. Boost Your Team Efficiency With Cyviz's Advanced Technology. Buy Now" />
                <meta name="keywords" content="Workplace Collaboration, User Experience, Advanced Technology, Remote management, Collaboration Technology" />
            </Helmet>
        </div>

        <div class="cyviz-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Empowering People & Their Spaces with <br/> Better Workplace Collaborations
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.cyviz.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Collaborative Solutions for Modern Teams</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Cyviz is pioneering the future of workplace collaboration by seamlessly integrating communication, control, and interaction through advanced technology. By creating immersive spaces that foster rich and inclusive meeting experiences, Cyviz transforms traditional work environments into dynamic collaboration hubs.
 <br/><br/>Their innovative solutions, such as customizable meeting rooms and best-in-class operation centers, promote productivity and enhance user experience promoting productivity whether it's in-person gatherings or remote teamwork. With a commitment to pushing the boundaries of collaboration technology, Cyviz empowers organizations to thrive in an interconnected world.


                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Features Designed for Remote Users Collaboration</h2>
            <CyvizKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Enhancing Communication & Boosting Team Efficiency - Our Solutions</h3>
            <CyvizSol/>
        <div className="middle-button">
            <a target='_blank' href='https://www.cyviz.com/solutions/' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Explore Our Collaboration Solutions
</h4>
            <div className='line-up'>
                <h4>Cyviz Easy Platform
                :</h4>
                <p>A comprehensive management solution that streamlines the operation of workplace collaboration spaces, ensuring users have control over all their technologies in one interface, enhancing efficiency and productivity.
</p>
            </div>
            <div className='line-up'>
                <h4>Cyviz Easy Monitoring and Remote Management Platform
                : </h4>
                <p>This platform provides real-time monitoring and remote management capabilities, enabling tech teams to maintain and troubleshoot issues quickly, ensuring that collaboration spaces are always optimized and functional.

</p>
            </div>
            <div className='line-up'>
                <h4>Cyviz Easy MTR Agent
                :
</h4>
                <p>This solution enhances the Microsoft Teams Rooms experience, making it simpler for users to connect and collaborate effortlessly regardless of their location or device, thus improving hybrid meeting experiences.
</p>
            </div>
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Countless brands worldwide have relied on Nvincy for their audio-visual requirements. <br/>
            We have become synonymous with audio visual solutions and the brand value we <br/> have gained over the years is a testament to that.Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

       

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Join the Crew of the User-Friendly Interface
</h5><br/>
            <p>Cyviz envisions a future where every workspace around the globe leverages advanced technologies to create seamless collaboration experiences. Their goal is to become the go-to partner for organizations looking to transform ordinary meeting environments into extraordinary collaboration hubs that inspire innovation and enhance productivity. Cyviz seeks to lead the charge in redefining how businesses collaborate, ensuring that every voice is heard and every idea has the opportunity to flourish.

</p>
            <a target='_blank' href='https://www.cyviz.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Cyviz and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
