import React from 'react';
import './Audac.css'
import AudacKey from '../OwlCarousel/Audackey';
import AudacLoud from '../OwlCarousel/AudacLoud';
import AudacAmp from '../OwlCarousel/AudacAmp';
import AudacMicro from '../OwlCarousel/AudacMicro';
import AudacWall from '../OwlCarousel/AudacWall';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/AudacFAQ';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Audac</title>
                <meta name="Explore Audac's High Resolution Custom Sound Systems" content="Audac Provides High-quality Custom Audio Solutions & sound systems. Experience the Difference of Audac's High-resolution Sound For Immerserive Sound Experience." />
                <meta name="keywords" content="Audio Solutions, Audio Solutions, Sound systems, Custom Audio Solutions, High Resolution sound" />
            </Helmet>
        </div>

        <div class="audac-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Robust Sound & More Power <br/> to your Spaces
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://audac.eu/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Custom Audio Solutions Designed for You</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    AUDAC is a pioneering audio solutions brand that specializes in delivering high resolution sound experiences across various sectors, including hospitality, retail, and corporate environments. 
AUDAC empowers businesses to create captivating auditory atmospheres that enhance customer engagement and satisfaction. Their range of products is designed not just to fill spaces with sound, but to weave music into the very fabric of daily experiences, cultivating memorable moments that resonate long after the music has faded.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Experience the Future of Sound Quality</h2>
            <AudacKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5"> Explore Our Wide Range of Audio Systems

</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Loudspeakers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Amplifiers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Microphones</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Wall">Wall Plates</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AudacLoud/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AudacAmp/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AudacMicro/>
                </Tab.Pane>

                <Tab.Pane eventKey="Wall">
                    <AudacWall/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://audac.eu/' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Get The Right Sound Experience For You</h4>
            <div className='line-up'>
                <h4>AUDAC Touch™:</h4>
                <p>A user-friendly system control platform that enables you to manage your audio setup from anywhere. It allows for tailored interfaces suited to specific environments, enhancing accessibility and user engagement.
</p>
            </div>
            <div className='line-up'>
                <h4>Atellio Series: </h4>
                <p>This range offers a variety of flexible audio solutions designed to fit into any architecture seamlessly. With modular components, it caters to differing acoustic needs while maintaining aesthetic appeal.
</p>
            </div>
            <div className='line-up'>
                <h4>NPM200:
</h4>
                <p>A powerful audio distribution module that enhances scalability in sound systems, ensuring exceptional performance and ease of integration across different applications.

</p>
            </div>
            <div className='line-up'>
                <h4>LUNA Series:</h4>
                <p>These versatile loudspeakers are designed for both indoor and outdoor use, providing clear, engaging sound tailored for various events and atmospheres.
</p>
            </div>
            <div className='line-up'>
                <h4>Corporate Audio Solutions:</h4>
                <p>Tailored systems designed to improve productivity and communication within corporate environments, addressing both sound quality and functionality.
</p>
            </div>
            <div className='line-up'>
                <h4>Bar & Restaurant Audio Solutions:</h4>
                <p>Specifically designed to enhance the ambiance in hospitality venues, offering rich soundscapes that enhance guest experiences.</p>
            </div>
            <div className='line-up'>
                <h4>Retail Audio Solutions:</h4>
                <p>Custom audio systems that create an engaging shopping environment, enhancing customers' emotional connection to the products and overall experience.
</p>
            </div>
            <div className='line-up'>
                <h4>Hotel Audio Solutions:</h4>
                <p>Seamless and sophisticated audio installations that ensure maximum relaxation for guests, contributing to an inviting atmosphere throughout the property.
                </p>
            </div>
            <div className='line-up'>
                <h4>Education Sound Systems:</h4>
                <p>Designed to facilitate learning environments through clear communication, enhancing the educational experience with reliable sound distribution.

                </p>
            </div>
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

       

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Join the Audio Revolution with Audac
</h5><br/>
            <p>AUDAC envisions a future where sound is not merely a backdrop but an integral component of everyday experiences. By continuously pushing the boundaries of audio technology, AUDAC aims to create innovative solutions that transform environments into immersive experiences. Through relentless innovation and a focus on user experience, AUDAC will continue to lead in the audio industry, enhancing the way sound impacts our lives.

</p>
            <a target='_blank' href='https://audac.eu/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Audac and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
