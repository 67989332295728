import React from 'react';
import tech1 from '../../Assets/aoto.png';
import tech2 from '../../Assets/allsee.png';
import tech3 from '../../Assets/audac.png';
import tech4 from '../../Assets/audinate.png';
import tech5 from '../../Assets/brightsign.png';
import tech6 from '../../Assets/catchbox.png';
import tech7 from '../../Assets/cyviz.png';
import tech8 from '../../Assets/earthworks.png';
import tech9 from '../../Assets/harman.png';
import tech10 from '../../Assets/laia.png';
import tech11 from '../../Assets/maxhub.png';
import tech12 from '../../Assets/multibrackets.png';
import tech13 from '../../Assets/netgear.png';
import tech14 from '../../Assets/nexmosphere.png';
import tech15 from '../../Assets/nureva.png';
import tech16 from '../../Assets/nviccta.png';
import tech17 from '../../Assets/prysm.png';
import tech18 from '../../Assets/qsc.png';
import tech19 from '../../Assets/sennheiser.png';
import tech20 from '../../Assets/siemon.png';
import tech21 from '../../Assets/wyrestorm.png';
import tech22 from '../../Assets/xilica.png';
import tech23 from '../../Assets/xposcreens.png';
import './TechCarousel.css';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 200,
  responsiveClass: true,
  nav: false,
 autoplay: false, // Enable autoplay
  autoplayTimeout: 3500, // Autoplay every 2 seconds (2000 milliseconds)
  dots: true,
  loop: false,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 5,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
  <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech1}
        className="card-img-top"
        alt="Aoto"
      />
      
    </div>
    </div>
  
    <div className="item">
      <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
        <img
          src={tech2}
          className="card-img-top"
          alt="audac"
        />
        </div>
      </div>

      <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech3}
        className="card-img-top"
        alt="audinate"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech4}
        className="card-img-top"
        alt="brightsign"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech5}
        className="card-img-top"
        alt="catchbox"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech6}
        className="card-img-top"
        alt="cyviz"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech7}
        className="card-img-top"
        alt="earthworks"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech8}
        className="card-img-top"
        alt="harman"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech9}
        className="card-img-top"
        alt="laia"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech10}
        className="card-img-top"
        alt="maxhub"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech11}
        className="card-img-top"
        alt="multibrackets"
      />
      
    </div> </div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech12}
        className="card-img-top"
        alt="netgear"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech13}
        className="card-img-top"
        alt="nexmosphere"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech14}
        className="card-img-top"
        alt="nureva"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech15}
        className="card-img-top"
        alt="nviccta"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech16}
        className="card-img-top"
        alt="prysm"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech17}
        className="card-img-top"
        alt="qsc"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech18}
        className="card-img-top"
        alt="sennheiser"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech19}
        className="card-img-top"
        alt="siemon"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech20}
        className="card-img-top"
        alt="wyrestorm"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech21}
        className="card-img-top"
        alt="xilica"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech22}
        className="card-img-top"
        alt="xposcreens"
      />
      
    </div></div>

    <div className="item">
    <div className="card tech" style={{ width: "320px", borderColor: "white" }}>
      <img
        src={tech23}
        className="card-img-top"
        alt="Allsee"
      />
      
    </div>

  </div>
  
  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;