import React from 'react';
import './Footer.css'; // Import the CSS file for styles
import logo from '../../Assets/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-top">
                <img src={logo} alt="Logo" /> {/* Replace 'logo.png' with your logo path */}
                <p>Nvincy has been a leader in providing innovative digital signage solutions for over a decade, <br/> meeting global standards with our user-friendly technology support worldwide. 

Thus, we help <br/> captivate your audience, elevate your brand, and drive better results.</p>
            </div>
            <div className="footer-container">
                <FooterColumn title="Company" items={[
                    { name: "Home", link: "https://nvincy.com/" },
                    { name: "About us", link: "https://nvincy.com/About" },
                    { name: "Products", link: "https://nvincy.com/Products" },
                    { name: "Services", link: "https://nvincy.com/Services" },
                    { name: "Contact us", link: "https://nvincy.com/Careers" },
                    { name: "Careers", link: "https://nvincy.com/Contact" },
                ]} />
                
                <FooterColumn title="Sub Products" items={[
                    { name: "Aoto", link: "https://nvincy.com/Products/Aoto" },
                    { name: "Allsee", link: "https://nvincy.com/Products/Allsee" },
                    { name: "Audac", link: "https://nvincy.com/Products/Audac" },
                    { name: "Audinate", link: "https://nvincy.com/Products/Audinate" },
                    { name: "Brightsign", link: "https://nvincy.com/Products/BrightSign" },
                    { name: "CatchBox", link: "https://nvincy.com/Products/CatchBox" },
                    { name: "Cyviz", link: "https://nvincy.com/Products/Cyviz" },
                    { name: "Earthworks", link: "https://nvincy.com/Products/Earthworks" },
                    { name: "Harman", link: "https://nvincy.com/Products/Harman" },
                    { name: "Laia", link: "https://nvincy.com/Products/Laia" },
                    { name: "Maxhub", link: "https://nvincy.com/Products/Maxhub" },
                    { name: "Multibrackets", link: "https://nvincy.com/Products/MultiBrackets" },
                ]} />

                <FooterColumn title="Sub Products" items={[
                    
                    { name: "Nexmosphere", link: "https://nvincy.com/Products/Nexmosphere" },
                    { name: "Netgear", link: "https://nvincy.com/Products/Netgear" },
                    { name: "Nureva", link: "https://nvincy.com/Products/Nureva" },
                    { name: "Nviccta", link: "https://nvincy.com/Products/Nviccta" },
                    { name: "Prysm", link: "https://nvincy.com/Products/Prysm" },
                    { name: "QSC", link: "https://nvincy.com/Products/QSC" },
                    { name: "Sennheiser", link: "https://nvincy.com/Products/Sennheiser" },
                    { name: "Seimon", link: "https://nvincy.com/Products/Seimon" },
                    { name: "Wyrestorm", link: "https://nvincy.com/Products/Wyrestorm" },
                    { name: "Xilica", link: "https://nvincy.com/Products/Xilica" },
                    { name: "Xpo Screens", link: "https://nvincy.com/Products/XpoScreens" },
                ]} />

                <div className="footer-column legal">
                    <FooterColumn
                        title="Legal"
                        items={[
                        { name: "Privacy Policy", link: "/Privacypolicy" },
                        { name: "Terms & Conditions", link: "/Termsandconditions" },
                        ]}
                    />
                    
                    <FooterColumn
                        title="Contact Us"
                        items={[
                        { name: "+91 080 4115 1212", link: "tel:+9108041151212" },
                        { name: "+91 990 1043 434", link: "tel:+919901043434" },
                        { name: "+91 900 8302 818", link: "tel:+919008302818" },
                        ]}
                    />
                </div>

                <div className="footer-column legal">
                    <FooterAddress title="Address" address={[
                        "1st Floor, No.207, 5th Cross, 3rd Block, HRBR Layout, 1st Floor, No.207, Kalyan Nagar, Bangalore - 560 043",
                    ]} />
                    
                    <FooterColumn
                        title="Email"
                        items={[
                        { name: "sales@nvincy.com", link: "tel:+9108041151212" },
                        { name: "support@nvincy.com", link: "tel:+919901043434" },
                        ]}
                    />
                </div>
            </div>
            <footer style={styles.footer} className='footer-footer'>
                <div style={styles.leftSection}>
                    <p>© 2024 Nvincy Solutions Pvt Ltd/All rights reserved. Designed & Developed by <a target='_blank' style={{color: "white"}} href='https://www.digiworq.com/' rel="noreferrer">DIGIWORQ</a></p>
                </div>
                <div style={styles.rightSection}>
                    <span style={styles.followText}>Follow us on</span>
                    <a style={{fontSize: "22px"}} className='px-2' href="https://whatsapp.com/"><FontAwesomeIcon icon={faWhatsapp} color="#E5DDE7" /></a>
                    <a style={{fontSize: "22px"}} className='px-2' href="https://facebook.com/"><FontAwesomeIcon icon={faFacebook}  color="#E5DDE7" /></a>
                    <a style={{fontSize: "22px"}} className='px-2' href="https://instagram.com/"><FontAwesomeIcon icon={faInstagram}  color="#E5DDE7" /></a>
                    <a style={{fontSize: "22px"}} className='px-2' href="https://linkedin.com/"><FontAwesomeIcon icon={faLinkedin}  color="#E5DDE7" /></a>
                    <a style={{fontSize: "22px"}} className='px-2' href="https://youtube.com/"><FontAwesomeIcon icon={faYoutube} color="#E5DDE7" /></a>
                </div>
         </footer>
         </footer>
    );
};

const FooterColumn = ({ title, items }) => {
    return (
        <div className="footer-column">
            <h3>{title}</h3>
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        <a href={item.link}>{item.name}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
};

const FooterAddress = ({ title, address }) => {
    return (
        <div className="footer-column">
            <h3>{title}</h3>
            <ul>
                {address.map((line, index) => (
                    <li key={index}>{line}</li>
                ))}
            </ul>
        </div>
    );
};
const styles = {
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 20px',
      color: '#fff',
    },
    leftSection: {
      fontSize: '22px',
      color: '#999999',
    },
    rightSection: {
      display: 'flex',
      alignItems: 'center',
    },
    followText: {
      marginRight: '10px',
      color: '#00B1FF', // Color for "Follow us on"
      fontSize: '22px',
      marginTop: "20px"
    },
    icon: {
      color: '#999999',
      marginLeft: '10px',
      fontSize: '22px',
      transition: 'color 0.3s ease',
    },
    icons: {
        fontSize: "22px",
    }
  };

export default Footer;






