import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of products does Sennheiser offer?",
      answer: "Sennheiser offers a wide range of products, including microphones, headphones, wireless systems, and meeting/conference solutions, catering to various professional and consumer needs.",
    },
    {
      question: "Are Sennheiser microphones suitable for beginners?",
      answer: "Yes, many Sennheiser microphones are designed with user-friendly features, making them great options for beginners while maintaining professional audio quality.",
    },
    {
      question: "Can I use Sennheiser headphones for casual listening?",
      answer: "Absolutely! Sennheiser’s headphones are versatile and suitable for both casual and audiophile listening, providing exceptional sound quality for any occasion.",
    },
    {
      question: "What is the benefit of wireless systems like the EW-DP ME 2 Set?",
      answer: "Wireless systems offer freedom from cables, allowing greater mobility and flexibility during performances or presentations without sacrificing audio quality.",
    },
    {
      question: "How do Sennheiser products ensure sound clarity in meetings?",
      answer: "Products like the TeamConnect Ceiling Medium utilize advanced microphone array technology to capture voices clearly from all angles in a room, ensuring effective communication.",
    },
    {
      question: "Are Sennheiser headphones comfortable for long sessions?",
      answer: "Yes, Sennheiser headphones often feature ergonomically designed ear cups and lightweight materials, making them ideal for extended listening periods without discomfort.",
    },
    {
      question: "Can I connect Sennheiser microphones to other audio systems?",
      answer: "Yes, Sennheiser microphones are designed to be compatible with a wide range of audio systems and mixers, making integration easy for various setups.",
    },
    {
      question: "What applications are Sennheiser products best suited for?",
      answer: "Sennheiser products are ideal for live performances, studio recordings, broadcasting, corporate events, and more, due to their high-quality audio capabilities.",
    },
    
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
