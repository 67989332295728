import React from 'react';
import './Aoto.css';
import Aoto1 from '../OwlCarousel/Aoto1';
import AotoCommercial from '../OwlCarousel/AotoCommercial';
import AotoCreative from '../OwlCarousel/AotoCreative';
import AotoRental from '../OwlCarousel/AotoRental';
import AotoControl from '../OwlCarousel/AotoControl';
import FAQ from '../FAQ/AotoFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb cust-container">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Aoto
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Aoto</title>
                <meta name="LED Signage Electronic Display Boards Online By AOTO" content="AOTO offers high-quality Electronic Signage Displays, Video Walls, and LED Screens. Upgrade Your Entity With AOTO's Innovative LED Screens. Guaranteed Quality." />
                <meta name="keywords" content="Electronic Display Boards, LED Video Walls, Digital Signage, LED Signage Displays, LED Screens " />
            </Helmet>
        </div>

        <div class="aoto-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Discover the Brilliance <br/> of AOTO Displays
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://en.aoto.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Explore AOTO’s LED Signage Display Range</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    AOTO's journey began with a vision to revolutionize the display and audio landscape by providing advanced, high-performance products tailored to meet the evolving needs of marketing and advertising. Recognizing the 
                    significant role of visual technologies in modern communication and entertainment, AOTO dedicated itself to pushing the boundaries of what is possible.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Experience the AOTO’s Powerful LED Displays</h2>
            <Aoto1/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Explore Our High-Definition Displays</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Commercial LED Display</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Creative LED Display</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Rental LED Display</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Control">Control System</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AotoCreative/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AotoRental/>
                </Tab.Pane>

                <Tab.Pane eventKey="Control">
                    <AotoControl/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.aoto.com/products' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Premium LED Displays for Every Need </h4>
            <div className='line-up'>
                <h4>LED Video Walls:</h4>
                <p>Available in both indoor and outdoor designs, LED video walls are used for large-scale visual displays in venues like stadiums, concerts, and shopping malls.</p>
            </div>
            <div className='line-up'>
                <h4>Digital Signage: </h4>
                <p>Designed for retail environments, corporate communications, and informational displays, these solutions typically feature high brightness and visibility.</p>
            </div>
            <div className='line-up'>
                <h4>Rental Displays: </h4>
                <p>AOTO provides LED screens specifically designed for rental purposes, catering to the events industry for temporary setups.</p>
            </div>
            <div className='line-up'>
                <h4>Fine Pitch LED Displays:</h4>
                <p>These displays offer high resolution for close viewing distances, making them ideal for control rooms, broadcasting studios, and high-end commercial applications.</p>
            </div>
            <div className='line-up'>
                <h4>Speciality Displays:</h4>
                <p>AOTO also develops customized solutions tailored for specific applications, such as interactive displays, transparent LED screens, and curved LED screens.</p>
            </div>
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 


        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>AOTO’s Global Reach: Most Trusted Digital Signage Solutions</h5><br/>
            <p>As AOTO continues to expand its footprint in the audio and visual solutions space, the focus remains on innovation, quality, and creating value for customers. The brand is poised to lead in the 
            coming years, adapting to new technologies and market demands while maintaining the high standards that have defined it since its inception.</p>
            <a target='_blank' href='https://en.aoto.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Aoto and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
