import React from 'react';
import owl1 from '../../Assets/Home_Owl1/owl1.jpg';
import owl2 from '../../Assets/Home_Owl1/owl2.jpg';
import owl3 from '../../Assets/Home_Owl1/owl3.jpg';
import owl4 from '../../Assets/Home_Owl1/owl4.jpg';
import owl5 from '../../Assets/Home_Owl1/owl5.jpg';
import owl6 from '../../Assets/Home_Owl1/owl6.jpg';
import owl7 from '../../Assets/Home_Owl1/owl7.jpg';
import owl8 from '../../Assets/Home_Owl1/owl8.jpg';
import owl9 from '../../Assets/Home_Owl1/owl9.jpg';
import owl10 from '../../Assets/Home_Owl1/owl10.jpg';
import owl11 from '../../Assets/Home_Owl1/owl11.jpg';
import owl12 from '../../Assets/Home_Owl1/owl12.jpg';
import owl14 from '../../Assets/Home_Owl1/owl14.jpg';
import owl15 from '../../Assets/Home_Owl1/owl15.jpg';
import owl16 from '../../Assets/Home_Owl1/owl16.jpg';
import owl17 from '../../Assets/Home_Owl1/owl17.jpg';
import owl18 from '../../Assets/Home_Owl1/owl18.jpg';
import owl19 from '../../Assets/Home_Owl1/owl19.jpg';
import owl21 from '../../Assets/Home_Owl1/owl21.jpg';
import owl22 from '../../Assets/Home_Owl1/owl22.jpg';
import owl23 from '../../Assets/Home_Owl1/owl23.jpg';
import owl24 from '../../Assets/Home_Owl1/owl24.jpg';
import './OwlCarousel.css';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: true,
  autoplay: true,
  autoplayTimeout: 3500, // Autoplay every 2 seconds (2000 milliseconds)
  autoplayHoverPause: true, // Pause on hover
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500, // Faster transition speed (500 milliseconds)
  loop: false, // Enable looping
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};


class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
  <div className="card responsive" style={{ width: "430px", borderColor: "white" }}>
    <img
      src={owl1}
      className="card-img-top zooming-image"
      alt="React Icon"
    />
    <div className="overlay-owl">
      <h5>User Friendly & Versatile </h5>
    </div>
  </div>
</div>

  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl2}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      {/* <div className="overlay-owl owl1">
        <h5>User Friendly & Versatile </h5>
      </div> */}
    </div>
  </div>
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl3}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl2">
        <h5>World’s Fastest Microphones</h5>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl4}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl3">
        <h5>Customizable AV Solutions</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl5}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
     <div className="overlay-owl owl4">
        <h5>High-Performers 

On the GO</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl6}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl5">
        <h5>High-Performance 

Video Distribution</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl7}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl6">
        <h5>Everything 

From Switchers 

To Extenders</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl8}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl7">
        <h5>Powerful 

Digital Signal Processors</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl9}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl8">
        <h5>Automated 

Audio Management Made Easy</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl10}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl9">
        <h5>High-Impact 

Collaboration Solutions</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl11}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl10">
        <h5>Enhanced 

Hybrid Collaboration</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl12}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl11">
        <h5>Best-in-class Audios</h5>
      </div>
    </div>
  </div> 
  
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl14}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl13">
        <h5>Inspired Automation</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl15}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl14">
        <h5>Innovative & 

User-Friendly Design</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl16}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl15">
        <h5>Flexible and 

Adaptable Mounting Choices</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl17}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl16">
        <h5>Compatibility at its Best</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl18}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl17">
        <h5>Reliable & Robust</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl19}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl18">
        <h5>Control From 

Anywhere</h5>
      </div>
    </div>
  </div> 
  
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl21}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl20">
        <h5>High-Resolution Displays 

Guaranteed</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl22}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl21">
        <h5>Durable and 

Reliable A/V Systems</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl23}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl22">
        <h5>Microphone Mist Technology™ 

Redefined</h5>
      </div>
    </div>
  </div> 
  <div className="item">
    <div className="card responsive" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={owl24}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-owl owl23">
        <h5>Superior Audio Technology</h5>
      </div>
    </div>
  </div> 
</OwlCarousel>

</div>
)
};
}

export default App;