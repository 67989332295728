import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What does Allsee Technologies specialize in?",
      answer: "Allsee Technologies specializes in digital signage, touch screens, media players, and related audio-visual solutions.",
    },
    {
      question: "Do you offer custom solutions?",
      answer: "Yes, Allsee Technologies can tailor solutions to meet your specific needs and requirements.",
    },
    {
      question: "What types of digital signage displays do you offer?",
      answer: "We offer a wide range of indoor and outdoor displays, including ultra-narrow bezel displays and digital signage display options.",
    },
    {
      question: "Can I use your digital signage for outdoor applications?",
      answer: "Yes, we offer high-brightness outdoor displays designed to withstand harsh weather conditions.",
    },
    {
        question: "What types of touch screens do you offer?",
        answer: "We offer PCAP touch screens and Android-powered touch screens for various applications.",
      },
      {
        question: "Can I use your touch screens for self-service kiosks?",
        answer: "Yes, our 10 and 15 POS touch screens are ideal for self-service kiosk applications.",
      },
      {
        question: "What type of content can I play on your media players?",
        answer: "You can play a variety of content, including videos, images, animations, and interactive elements.",
      },
      {
        question: "Can I control my media players remotely?",
        answer: "Yes, many of our media players offer remote management capabilities.",
      },
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
