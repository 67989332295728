import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "Can I control AUDAC systems remotely?",
      answer: "Yes, with the AUDAC Touch™ platform, you can manage your audio setup from any device, allowing for convenient control remotely.",
    },
    {
      question: "What types of environments can AUDAC products be used in?",
      answer: "AUDAC products are versatile and can be utilized in hospitality, retail, corporate, and educational environments, ensuring high resolution sound across all applications.",
    },
    {
      question: "How does the AUDAC Touch™ simplify system management?",
      answer: "The AUDAC Touch™ allows users to create customized dashboards for specific applications, controlling multiple devices from a single interface, simplifying the user experience.",
    },
    {
      question: "Are AUDAC products suitable for outdoor use?",
      answer: "Yes, products like the LUNA Series are designed to deliver exceptional sound quality in both indoor and outdoor settings.",
    },
    {
        question: "Can AUDAC products collaborate with other systems?",
        answer: "Yes, AUDAC solutions are designed to integrate seamlessly with lighting, video, and other automation systems for a comprehensive management experience. ",
      },
      {
        question: "How often do you release firmware updates?",
        answer: "AUDAC regularly releases firmware updates to improve performance, add features, and ensure compatibility with new technologies. ",
      },
      {
        question: "Can I customize the AUDAC Touch™ interface?",
        answer: "Absolutely! The AUDAC Touch™ platform allows for extensive customization to meet the unique needs of different users and environments.",
      },
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
