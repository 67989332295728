import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "How does HARMAN incorporate IoT into its solutions?",
      answer: "HARMAN utilizes IoT to enhance connectivity and interaction among devices, integrating features such as conversational AI to create more intuitive user experiences across its product range.",
    },
    {
      question: "How is HARMAN addressing the shift to 5G in the automotive sector?",
      answer: "HARMAN facilitates the transition to 5G by ensuring automakers have a flexible and optimized network infrastructure, providing backward compatibility with earlier network technologies while preparing for future advancements.",
    },
    {
      question: "What is the HARMAN Ignite platform?",
      answer: "HARMAN Ignite is an integrated platform that provides OEMs with a scalable solution to enhance vehicle ownership experiences, facilitating engagement from pre-purchase through post-purchase support.",
    },
    {
      question: "What are the benefits of Advanced Driver-Assistance Systems (ADAS)?",
      answer: "HARMAN's ADAS solutions help eliminate human errors in driving, contributing to safer journeys by incorporating features that assist drivers in real-time and enhance overall vehicle safety.",
    },
    {
      question: "Can HARMAN’s products assist with digital connectivity in vehicles?",
      answer: "Yes, HARMAN’s digital cockpit solutions enhance digital connectivity, providing drivers with an integrated experience that includes navigation, entertainment, and communication features.",
    },
    {
      question: "Does HARMAN focus on sustainability?",
      answer: "Absolutely. HARMAN is committed to implementing sustainable practices throughout its product lifecycle, working to reduce environmental impact while driving innovation.",
    },
    {
        question: "How can OEMs benefit from HARMAN’s engineering services?",
        answer: "HARMAN offers expert engineering solutions that support OEMs in navigating challenges in the automotive ecosystem, either by enhancing existing capabilities or partnering for integrated solutions.",
      },
      {
        question: "What markets does HARMAN operate in besides automotive?",
        answer: "In addition to automotive, HARMAN operates within consumer electronics, professional audio, and lifestyle solutions, creating a diverse range of products that cater to various user needs.",
      },
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
