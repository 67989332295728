import React from 'react';
import './Privacypolicy.css'
const About = () => {
  return (
    <div>
        <div class="pp-sub_banner">
          <div className="product-banner-content">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>Captivate. Connect. Convert.</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
                Privacy Policy
            </h1>
            {/* <div className="carousel-buttons">
                <button className="btn-primary">View All</button>
            </div> */}
          </div>
        </div>
       

        <div className='cust-container' style={{paddingTop: "120px"}}>
            <div className='row'>
              <div className='col-12'>
                <p className='about1'>
                    <h3>Privacy Policy</h3>
                    Nvincy website is owned by nvincy, which is a data controller of your personal data.
                </p>
                <p className='about1'>nvincy website is owned by nvincy, which is a data controller of your personal data.</p>

                <p className='about1'>We have adopted this Privacy Policy, which determines how we are processing the information collected by nvincy, 
                which also provides the reasons why we must collect certain personal data about you. Therefore, you must read this Privacy Policy before using nvincy website.</p>

                <p className='about1'>We take care of your personal data and undertake to guarantee its confidentiality and security.</p>

                <h3>Personal information we collect:</h3>

                <p className='about1'>When you visit the nvincy, we automatically collect certain information about your device, including information about your web browser, 
IP address, time zone, and some of the installed cookies on your device. Additionally, as you browse the Site, we collect information about the 
individual web pages or products you view, what websites or search terms referred you to the Site, and how you interact with the Site. We refer to 
this automatically-collected information as “Device Information.” Moreover, we might collect the personal data you provide to us (including but not 
limited to Name, Surname, Address, payment information, etc.) during registration to be able to fulfill the agreement.</p>

<h3>Why do we process your data?</h3>

<p className='about1'>Our top priority is customer data security, and, as such, we may process only minimal user data, only as much as it is absolutely necessary to 
maintain the website. Information collected automatically is used only to identify potential cases of abuse and establish statistical information 
regarding website usage. This statistical information is not otherwise aggregated in such a way that it would identify any particular user of the system.</p>

<p className='about1'>You can visit the website without telling us who you are or revealing any information, by which someone could identify you as a specific, 
identifiable individual. If, however, you wish to use some of the website’s features, or you wish to receive our newsletter or provide other details by filling a form, 
you may provide personal data to us, such as your email, first name, last name, city of residence, organization, telephone number. You can choose not to provide us with your 
personal data, but then you may not be able to take advantage of some of the website’s features. For example, you won’t be able to receive our Newsletter or contact us directly 
from the website. Users who are uncertain about what information is mandatory are welcome to contact us via sales@nvincy.com.</p>

<h3>Your rights:</h3>

<p className='about1'>If you are a European resident, you have the following rights related to your personal data:</p>

<p className='about1'>The right to be informed.</p>

<p className='about1'>The right of access.</p>

<p className='about1'>The right to rectification.</p>

<p className='about1'>The right to erasure.</p>

<p className='about1'>The right to restrict processing.</p>

<p className='about1'>The right to data portability.</p>

<p className='about1'>The right to object.</p>

<p className='about1'>Rights in relation to automated decision-making and profiling.</p>

<p className='about1'>If you would like to exercise this right, please contact us through the contact information below.</p>

<p className='about1'>Additionally, if you are a European resident, we note that we are processing your information in order to fulfill contracts we might have with you 
(for example, if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your 
information might be transferred outside of Europe, including Canada and the United States.</p>

<h3>Links to other websites:</h3>

<p className='about1'>Our website may contain links to other websites that are not owned or controlled by us. Please be aware that we 
are not responsible for such other websites or third parties' privacy practices. We encourage you to be aware when you leave our website and 
read the privacy statements of each website that may collect personal information.</p>

<h3>Information security:</h3>

<p className='about1'>We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. 
We keep reasonable administrative, technical, and physical safeguards to protect against unauthorized access, use, modification, and personal 
data disclosure in its control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.</p>

<h3>Legal disclosure:</h3>

<p className='about1'>We will disclose any information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or 
similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or 
the safety of others, investigate fraud, or respond to a government request.</p>

<h3>Contact information:</h3>

<p className='about1'>If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to individual rights and your Personal 
Information, you may send an email to sales@nvincy.com.</p>
            </div>
            </div>
            </div>
    </div>
     )
    }
export default About
