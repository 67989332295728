import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What makes Earthworks microphones a solid choice for drummers?",
      answer: "Earthworks microphones are specifically engineered for fast transient response, ensuring they capture the full dynamics of drum performances with exceptional clarity and precision.",
    },
    {
      question: "Are Earthworks mics suitable for live performances?",
      answer: "Yes! Earthworks audio equipment is designed to withstand the demands of live sound environments, ensuring reliable performance without compromising on optimal sound quality.",
    },
    {
      question: "Can I use Earthworks microphones for studio recording?",
      answer: "Absolutely! Earthworks microphones are designed for both studio and live applications, providing high-fidelity sound reproduction for any setting.",
    },
    {
      question: "How do I choose the right microphone for my needs?",
      answer: "Assess your specific requirements such as type of instrument, intended use (live or studio), and budget. Earthworks offers expert guidance to help you make the best choice.",
    },
    {
        question: "Are Earthworks microphones compatible with all audio interfaces?",
        answer: "Yes, they feature standard XLR connections, allowing seamless integration with most audio interfaces and mixing systems.",
      },
      {
        question: "What kind of maintenance do Earthworks microphones require?",
        answer: "Regularly check for dust or debris on the mic elements, and store them properly in their cases to ensure longevity and performance.",
      },
      {
        question: "Do the microphones require any special setup?",
        answer: "While Earthworks microphones are user-friendly, proper placement and positioning are always recommended to achieve optimal sound quality based on your specific environment.",
      },
      
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
