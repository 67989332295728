import React from 'react';
import aoto1 from '../../Assets/Aoto/aotocomm1.png';
import aoto2 from '../../Assets/Aoto/aotocomm2.png';
import aoto3 from '../../Assets/Aoto/aotocomm3.png';
import aoto4 from '../../Assets/Aoto/aotocomm4.png';
import aoto5 from '../../Assets/Aoto/aotocomm5.png';
import aoto6 from '../../Assets/Aoto/aotocomm6.png';
import aoto7 from '../../Assets/Aoto/aotocomm7.png';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: false,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>CLD ES Series</h3>
            <p className='thro'>Industry applications: CONFERENCE ROOM/ TV STUDIO/CONTROL ROOM Pixel Pitch (mm): 0.9/1.2/1.5 Calibrated Brightness(nits): 600-1000 nits LED type: Mini 4 in 1</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>F Series</h3>
            <p className='thro'>Industry applications: TRANSPORTATION/DIGITAL MEDIA/CONFERENCE ROOM Pixel Pitch (mm): 4mm Calibrated Brightness(nits): 700 nits LED type : SMD3 in 1</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>CV Series</h3>
            <p className='thro'>Industry applications: CONFERENCE ROOM/ CONTROL ROOM/TV STUDIO Pixel Pitch (mm): 1.5 Calibrated Brightness(nits): 600 nits LED type : Mini 4 in 1</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>E Series</h3>
            <p className='thro'>Industry applications: TRANSPORTATION/TV STUDIO/DIGITAL MEDIA Pixel Pitch (mm): 2.5 - 12 Calibrated Brightness(nits): 1200-5500nits LED type : SMD 3 in 1</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>MXC Series</h3>
            <p className='thro'>Industry applications: CONTROL ROOM/CONFERENCE ROOM/TRANSPORTATION Pixel Pitch (mm): 1.2 - 10 Calibrated Brightness(nits): 600-6000NITS LED type : SMD 3 in 1</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>CV ALL-IN-ONE Series</h3>
            <p className='thro'>Industry applications: CONFERENCE ROOM/CONTROL ROOM/TV STUDIO Pixel Pitch (mm) : 1.5625</p>
        </div>
        <img
            src={aoto6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>CLD ALL-IN-ONE Series</h3>
            <p className='thro'>Industry applications: CONFERENCE ROOM/CONTROL ROOM/TV STUDIO Pixel Pitch (mm): 0.93/1.25/1.56 Calibrated Brightness(nits): 600-1000nits LED type : Mini 4 in 1</p>
        </div>
        <img
            src={aoto7}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>




  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;