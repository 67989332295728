import React from 'react';
import nurevaroom1 from '../../Assets/Maxhub/cover1.jpeg';
import nurevaroom2 from '../../Assets/Maxhub/cover2.jpeg';
import nurevaroom3 from '../../Assets/Maxhub/cover3.jpeg';
import './OwlCarousel.css';
import './NurevaCarousel1.css';
import './NurevaCarousel2.css';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500, // Autoplay every 2 seconds (2000 milliseconds)
  autoplayHoverPause: true, // Pause on hover
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500, // Faster transition speed (500 milliseconds)
  loop: true, // Enable looping
  responsive: {
    0: {
      items: 1, // 1 item for small screens
    },
    600: {
      items: 2, // 1 item for medium screens
    },
    1000: {
      items: 2, // 1 item for large screens
    },
  },
};


class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
  <div className="item">
    <div className="nureva rooms position-relative" style={{ width: "900px", borderColor: "white" }}>
      <img
        src={nurevaroom1}
        className="card-img-top"
        alt="React Icon"
      />
      <div className="overlay-text-room">
        <p><b>Signature Microsoft Teams Room</b></p>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="nureva rooms position-relative" style={{ width: "900px", borderColor: "white"}}>
      <img
         src={nurevaroom2}
        className="card-img-top"
        alt="React Icon"
      />
     <div className="overlay-text-room">
     <p><b>Large Teams Room</b></p>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="nureva rooms position-relative" style={{ width: "900px", borderColor: "white"}}>
      <img
         src={nurevaroom3}
        className="card-img-top"
        alt="React Icon"
      />
      <div className="overlay-text-room">
      <p><b>Extra Large Board Room</b></p>
      </div>
    </div>
  </div>
</OwlCarousel>

</div>
)
};
}

export default App;