import React from 'react';
import Audkey1 from '../../Assets/Audinate/Audinate-key1.jpg';
import Audkey2 from '../../Assets/Audinate/Audinate-key2.jpg';
import Audkey3 from '../../Assets/Audinate/Audinate-key3.jpg';
import Audkey4 from '../../Assets/Audinate/Audinate-key4.jpg';
import Audkey5 from '../../Assets/Audinate/Audinate-key5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Seamless Connectivity</h3>
            <p className='thro'>Dante replaces multiple audio and video cables with a single Ethernet connection, simplifying setup and 
            reducing clutter in any AV environment. This streamlining of connections not only saves time during installation 
            but also enhances reliability by minimizing points of failure.
</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Conservative Scalability
</h3>
            <p className='thro'>Designed to grow with your needs, Dante technology scales effortlessly to accommodate systems of any size. 
            Dante's architecture ensures that expansion is as simple as plugging in additional devices. 
</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Digital Fidelity

</h3>
            <p className='thro'>With Dante, audio and video transmission maintains perfect digital quality, free from interference and signal degradation. This commitment to superior sound and image clarity is vital for high-stakes applications such as concerts, conferences, and broadcast environments.

</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Wide Compatibility
</h3>
            <p className='thro'>With over 4,000 products from more than 600 manufacturers fully compatible with the Dante protocol, users benefit from an extensive ecosystem. This interoperability allows for seamless integration of diverse components, ensuring flexibility and choice in system design.


</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>User-Friendly Management

</h3>
            <p className='thro'>The Dante Controller software provides an intuitive interface for managing audio and video routes, making it easy for users to configure their systems. Its dynamic routing capabilities allow for quick adjustments to accommodate changing needs in live events or installations.
</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;