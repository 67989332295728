import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
        question: "What kind of work does Nvincy do?",
        answer: "We're storytellers and versatile AV/IT retailers, crafting impactful communication experiences through AV/IT, software, apps, and digital signage.",
      },
      {
        question: "Is Nvincy the right fit for me?",
        answer: "If you're passionate about technology and love creating something amazing, then yes!",
      },
      {
        question: "What types of roles are available?",
        answer: "We offer a diverse range, from app developers to AV/IT techies! Check out our job listings for details.",
      },
      {
        question: "Does Nvincy offer flexible work options?",
        answer: "We understand work-life balance! Some positions offer flexibility too—check the job description for more details!",
      },
      {
        question: "What's the interview process like?",
        answer: "We keep it engaging and informative. We'll chat about your skills and passion!",
      },
      {
        question: "What are the benefits of working at Nvincy?",
        answer: "We offer competitive pay, a collaborative environment, freedom of expression, real-time knowledge-gaining opportunities, and the chance to make a real impact!",
      },
      {
          question: "How can I apply?",
          answer: "Simply browse our open positions and apply online via LinkedIn, or fill out the form above and wait for our call back. We look forward to hearing from you!",
        },
        {
          question: "I’m looking for job opportunities, Can I apply?",
          answer: "Yes, you can apply now letting us keep your data safe. We’ll connect when we get a relevant opening for you.",
        },
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
