import React from 'react';
import aoto1 from '../../Assets/wyrestorm/Extenders-EX-40-G3-1-400x400.png';
import aoto2 from '../../Assets/wyrestorm/Extenders-RXV-35-4K_RearTlt_Ang_Rndr-Shadow-1-400x400.png';
import aoto3 from '../../Assets/wyrestorm/Extenders-SW-120-TX3-1-400x400.png';
import aoto4 from '../../Assets/wyrestorm/Extenders-Wyrestorm-Transmitter-Receiver-TX-35-IW-2-400x400.png';

//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>SKU: EX-40-G3</h3>
            <p className='thro'>40m 1080p UTP Extender</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>SKU: RXV-35-4K</h3>
            <p className='thro'>35m 4K60 HDBaseT™ Receiver for MXV</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>SKU: SW-120-TX3</h3>
            <p className='thro'>Synergy™ 2-input 4K60Hz HDBaseT™ 3.0 Switching Transmitter with USB-C Charging and CEC Trigger</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>SKU: TX-35-IW</h3>
            <p className='thro'>HDMI Single Gang In-Wall HDBaseT Transmitter</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;