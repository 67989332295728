import React from 'react';
import aoto1 from '../../Assets/wyrestorm/Best-in-Class AV Over IP Technology.jpg';
import aoto2 from '../../Assets/wyrestorm/Free Cloud Management.jpg';
import aoto3 from '../../Assets/wyrestorm/Innovative Technology.jpg';
import aoto4 from '../../Assets/wyrestorm/Collaborative.jpg';
import aoto5 from '../../Assets/wyrestorm/Flexible Presentation Devices.jpg';
import aoto6 from '../../Assets/wyrestorm/Versatile Matrixes and Switchers.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Best-in-Class AV Over IP Technology</h3>
            <p className='thro'>WyreStorm’s AV over IP solutions feature unique multi-technology integrations designed for professional applications. These solutions ensure high-quality transmission and simple scalability, making them ideal for modern corporate and educational environments.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Versatile Matrixes and Switchers</h3>
            <p className='thro'>Our advanced matrices and switchers facilitate the easy distribution of multiple audio-visual sources to various destinations. With modular options and seamless switching capabilities, they enhance flexibility and simplify system management for large venues and enterprises.</p>
        </div>
        <img
            src={aoto6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Free Cloud Management</h3>
            <p className='thro'>WyreStorm provides innovative cloud management solutions that allow users to remotely control and manage devices effortlessly. This feature enhances convenience, reduces downtime, and helps system administrators monitor and troubleshoot issues in real time.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Collaborative Solutions for Today’s Workspaces</h3>
            <p className='thro'>Our complete collaboration suite transforms meeting spaces into professional environments ready for impactful communication. WyreStorm offers speakerphones, video bars, and conferencing kits that promote engaging and productive discussions.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Flexible Presentation Devices</h3>
            <p className='thro'>WyreStorm’s presentation switchers, both wired and wireless, support seamless connectivity for a wide range of devices. This versatility caters to diverse presentation needs, whether in classrooms, conference rooms, or home theaters.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Innovative Technology</h3>
            <p className='thro'>With a strong commitment to innovation, WyreStorm consistently develops cutting-edge solutions, keeping pace with the evolving demands of the AV industry.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>





  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;