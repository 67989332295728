import React from 'react';
import aoto1 from '../../Assets/CatchBox/Catchbox-01.png';
import aoto2 from '../../Assets/CatchBox/Catchbox-02.png';
import aoto3 from '../../Assets/CatchBox/Catchbox-03.png';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: false,
 autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Catchbox Module</h3>
            <p className='thro'>Engage any audience with dynamic discussions and fast Q&A using Catchbox.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Catchbox Lite</h3>
            <p className='thro'>Catchbox Lite boosts participation, encouraging everyone in small group discussions.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Catchbox Plus</h3>
            <p className='thro'>Powerful, versatile and easy to use, Catchbox Plus is perfect for activities of any size</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>


  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;