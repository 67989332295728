import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';

import NavbarTop from './Main_Components/NavbarTop/NavbarTop';
import Navbar from './Main_Components/Navbar/Navbar';
import Home from './Main_Components/Home/Home';
import About from './Main_Components/About/About';
import Products from './Main_Components/Products/Products';
import Nureva from './Main_Components/Nureva/Nureva';
import Contact from './Main_Components/Contact/Contact';
import Footer from './Main_Components/Footer/Footer';
import Careers from './Main_Components/Career/Career';
import Services from './Main_Components/Services/Services';
import Aoto from './Main_Components/Aoto/Aoto';
import Alsee from './Main_Components/Alsee/Alsee';
import Audac from './Main_Components/Audac/Audac';
import Audinate from './Main_Components/Audinate/Audinate';
import CatchBox from './Main_Components/CatchBox/CatchBox';
import Brightsign from './Main_Components/BrightSign/BrightSign';
import Cyviz from './Main_Components/Cyviz/Cyviz';
import EarthWorks from './Main_Components/EarthWorks/EarthWorks';
import Harman from './Main_Components/Harman/Harman';
import Laia from './Main_Components/Laia/Laia';
import Netgear from './Main_Components/Netgear/Netgear';
import NexMosphere from './Main_Components/NexMosphere/NexMosphere';
import XpoScreens from './Main_Components/XpoScreens/XpoScreens';
import Xilica from './Main_Components/Xilica/Xilica';
import Multibrackets from './Main_Components/Microbrackerts/Microbrackerts';
import WyreStorm from './Main_Components/WyreStorm/WyreStorm';
import Siemon from './Main_Components/Siemon/Siemon';
import Sennheiser from './Main_Components/Sennheiser/Sennheiser';
import QSC from './Main_Components/QSC/QSC';
import Nvictta from './Main_Components/Nvictta/Nvictta';
import Maxhub from './Main_Components/Maxhub/Maxhub';
import Prysm from './Main_Components/Prysm/Prysm';
import PrivacyPolicy from './Main_Components/Privacypolicy/Privacypolicy';


const App = () => {
  return (
    <div>
        <NavbarTop/>
        <Navbar/>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/About" element={<About />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/Careers" element={<Careers />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Products/Nureva" element={<Nureva />} />
            <Route path="/Products/Aoto" element={<Aoto/>} />
            <Route path="/Products/Allsee" element={<Alsee/>} />
            <Route path="/Products/Audac" element={<Audac/>} />
            <Route path="/Products/Audinate" element={<Audinate/>} />
            <Route path="/Products/Brightsign" element={<Brightsign/>} />
            <Route path="/Products/CatchBox" element={<CatchBox/>} />
            <Route path="/Products/Cyviz" element={<Cyviz/>} />
            <Route path="/Products/EarthWorks" element={<EarthWorks/>} />
            <Route path="/Products/Harman" element={<Harman/>} />
            <Route path="/Products/Laia" element={<Laia/>} />
            <Route path="/Products/Netgear" element={<Netgear/>} />
            <Route path="/Products/Multibrackets" element={<Multibrackets/>} />
            <Route path="/Products/NexMosphere" element={<NexMosphere/>} />
            <Route path="/Products/XpoScreens" element={<XpoScreens/>} />
            <Route path="/Products/Xilica" element={<Xilica/>} />
            <Route path="/Products/WyreStorm" element={<WyreStorm/>} />
            <Route path="/Products/Siemon" element={<Siemon/>} />
            <Route path="/Products/Sennheiser" element={<Sennheiser/>} />
            <Route path="/Products/QSC" element={<QSC/>} />
            <Route path="/Products/Nvictta" element={<Nvictta/>} />
            <Route path="/Products/Maxhub" element={<Maxhub/>} />
            <Route path="/Products/Prysm" element={<Prysm/>} />
            <Route path="/Privacypolicy" element={<PrivacyPolicy/>} />
        </Routes>
        <Footer/>
    </div>
  )
}

export default App 








