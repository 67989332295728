import React from 'react';
import './Sennheiser.css';
import Aoto1 from '../OwlCarousel/Sennheiser';
import AotoCommercial from '../OwlCarousel/Sennheiser-Headphones';
import AotoCreative from '../OwlCarousel/Sennheiser-Microphones';
import AotoRental from '../OwlCarousel/Sennheiser-Wireless systems';
import AotoControl from '../OwlCarousel/Sennheiser-Monitoring';
import FAQ from '../FAQ/SennheiserFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Sennheiser</title>
                <meta name="High-Quality Audio Systems With Superior Sound Engineering" content="High-Quality Audio Solutions From Expert Sound Engineers, Using Top-Notch Audio Technology. Experience the Power of Wireless Systems And Advanced Audio Tech." />
                <meta name="keywords" content="Sound quality,Audio Technology,Sound engineering,Wireless systems" />
            </Helmet>
        </div>

        <div class="senn-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Delivering Sound That Matters: <br/> Hear the Difference
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.sennheiser.com/en-in' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Revolutionary Audio Technology</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Sennheiser is a name synonymous with excellence in audio across the globe, renowned for its unwavering commitment to quality and 
                    innovation. With a rich heritage dating back to 1945, Sennheiser has consistently pushed the boundaries of sound engineering, delivering 
                    products that cater to the diverse needs of audiophiles, musicians, and professionals alike. <br/><br/>From studio-grade microphones to high-fidelity 
                    headphones and cutting-edge wireless systems, Sennheiser's products are designed not just to meet expectations but to inspire and elevate the 
                    art of sound engineering. With a focus on immersive audio experiences and seamless connectivity, Sennheiser continues to shape the future of audio 
                    technology, ensuring that every performance, meeting, and listening session is extraordinary.</p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Excellence In Sound Engineering</h2>
            <Aoto1/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Discover Advanced Audio Solutions</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Headphones</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Microphones</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Control">Monitoring</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Wireless systems</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AotoCreative/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AotoRental/>
                </Tab.Pane>

                <Tab.Pane eventKey="Control">
                    <AotoControl/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.sennheiser.com/en-in/products-overview' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Immersive Sound Systems
</h4>
            <div className='line-up'>
                <h4>MD 421 Kompakt Microphone:</h4>
                <p>An industry standard, this versatile dynamic microphone excels in various applications, from live sound to studio recording, known for its excellent frequency response.</p>
            </div>
            <div className='line-up'>
                <h4>EW-DP ME 2 Set:</h4>
                <p>This wireless system simplifies setup while delivering pristine digital audio quality. Perfect for presentations and performances, it ensures crystal-clear communication without wire clutter.</p>
            </div>
            <div className='line-up'>
                <h4>TeamConnect Ceiling Medium:</h4>
                <p>It is designed for meetings and conferences, this innovative ceiling microphone array captures voice clearly from anywhere in the room, facilitating collaboration and productivity.</p>
            </div>
            <div className='line-up'>
                <h4>XS 1 Microphone:</h4>
                <p>This dynamic handheld microphone offers excellent sound reproduction and rugged design, making it suitable for both live performances and everyday use.</p>
            </div>
            <div className='line-up'>
                <h4>AMBEO Spatial Audio:</h4>
                <p>A revolutionary audio technology that creates an immersive sound experience, allowing listeners to feel as if they are in the center of the action, perfect for filmmakers and music producers.</p>
            </div>
            <div className='line-up'>
                <h4>Sennheiser HD 660 S:</h4>
                <p>An open-back, high-fidelity headphone that delivers an exceptional listening experience, blending comfort and acoustics for audiophiles seeking superior sound quality.</p>
            </div>
            <div className='line-up'>
                <h4>IE 900 In-Ear Monitors :</h4>
                <p>These premium in-ear headphones offer a detailed and refined listening experience, ideal for professionals and enthusiasts seeking unrivaled audio fidelity on the go.</p>
            </div>
            <div className='line-up'>
                <h4>Sennheiser MKE 400:</h4>
                <p>A compact shotgun microphone designed for video production, offering directional audio capture and versatility for vlogging and professional video work.</p>
            </div>
            <div className='line-up'>
                <h4>ADAPT 660:</h4>
                <p>Featuring hybrid active noise cancellation, these headphones provide a quiet environment for focus and productivity, blending work and leisure seamlessly.</p>
            </div>
            <div className='line-up'>
                <h4>Sennheiser G4 Wireless System:</h4>
                <p>This robust wireless system guarantees seamless operation across multiple channels, ideal for theater productions, concerts, and corporate events.</p>
            </div>
            
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

       

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Quality Meets Innovation In Every Aspect</h5><br/>
            <p>Looking ahead, Sennheiser envisions a world in which the boundaries of audio technology continue to blur, creating increasingly immersive experiences for users across all platforms. The brand aims to pioneer innovations that not only enhance sound quality but also promote connectivity and collaboration in an ever-evolving digital landscape.Sennheiser is committed to sustainable practices and aims to lead by example, ensuring that their growth aligns with environmental and social responsibility. As they explore new frontiers in audio technology, Sennheiser strives to empower creators and listeners alike, making high-quality audio accessible to everyone, and setting the stage for the next generation of audio excellence.</p>
            <a target='_blank' href='https://www.sennheiser.com/en-in' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container'>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Allsee and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
