import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What is Netgear known for?",
      answer: "Netgear is recognized for its wide range of networking products and solutions, designed for both home and business environments. It specializes in routers, switches, and wireless solutions.",
    },
    {
      question: "How can I set up my Netgear router?",
      answer: "You can easily set up your Netgear router by following the user manual or using the Nighthawk app, which provides step-by-step instructions for a smooth installation experience.",
    },
    {
      question: "Can I use multiple Netgear products together?",
      answer: "Absolutely! Netgear products are designed to work together seamlessly, providing an integrated networking solution that enhances performance and connectivity.",
    },
    {
      question: "Are Netgear products compatible with third-party devices?",
      answer: "Yes, Netgear products are generally compatible with a wide range of third-party devices, ensuring flexibility and ease of integration into your existing network.",
    },
    {
      question: "How do I reset my Netgear router?",
      answer: "You can reset your Netgear router by pressing the reset button located on the device for about 10 seconds, which will restore it to factory settings.",
      },
    {
      question: "What is AoIP, and how does it work with Netgear?",
      answer: "Audio over IP (AoIP) refers to transmitting audio signals over a network. Netgear provides solutions that enable high-quality audio streaming and management within commercial settings.",
    },
    {
      question: "Where can I find software and firmware updates for my Netgear product?",
      answer: "Software and firmware updates can be found on the Netgear support website, providing essential updates to enhance security and performance.",
    },
    {
      question: "Does Netgear support SDVoE technology?",
      answer: "Yes, Netgear is committed to SDVoE technology, offering solutions that improve video distribution and connectivity in various applications, particularly in AV/IT scenarios.",
    },
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
