import React from 'react';
import './EarthWorks.css'
import EarthWorksKey from '../OwlCarousel/EarthWorksKey';
import EarthWorksMeasurement from '../OwlCarousel/EarthWorksMeasurement';
import EarthWorksInst from '../OwlCarousel/EarthWorksInst';
// import BrightSignLS from '../OwlCarousel/BrightSignLS';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/EWFAQ';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | EarthWorks</title>
                <meta name="Best Sound: Stereo Soundscapes for Optimal Audio Quality" content="Experience the Best Sound Quality With Our Advanced Microphone Technology and Stereo Soundscapes. Top-tier Audio Equipment For Optimal Listening Now On Sale" />
                <meta name="keywords" content="Best sound experience, Microphone Technology, stereo soundscapes, Audio Equipment" />
            </Helmet>
        </div>

        <div class="ew-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Capture the Rhythm: Experience <br/> Unmatched Clarity
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://earthworksaudio.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Immersive Audio Solutions</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Earthworks Audio is a pioneering brand that redefines the standards of microphone technology, particularly in the realm of drum sound capture. Trusted by industry elites and acclaimed artists, Earthworks Microphones are synonymous with clarity, precision, and durability, ensuring the pure essence of music is captured effortlessly.
            <br/><br/> Earthworks’s innovative approach combines cutting-edge technology with a deep understanding of the nuances of sound, allowing them to create microphones that not only perform exceptionally but also resonate emotionally with listeners. With a commitment to delivering pristine audio quality, Earthworks has become the preferred choice for musicians, sound engineers, and audio professionals seeking to enjoy the best sound experience, whether in the studio, on stage, or while streaming. 
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Best-in-Class Microphone Technology</h2>
            <EarthWorksKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5"> Crystal-Clear Stereo Soundscapes
</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Measurement Microphones</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Installed Sound Microphones</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                    <Nav.Link eventKey="Rental">Where Intelligibility Meets Performance</Nav.Link>
                </Nav.Item> */}
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <EarthWorksMeasurement/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <EarthWorksInst/>
                </Tab.Pane>

                {/* <Tab.Pane eventKey="Rental">
                    <BrightSignLS/>
                </Tab.Pane> */}
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://earthworksaudio.com/' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Customizable Sound Profiles
</h4>
            <div className='line-up'>
                <h4>DM17 - Tom & Snare Drum Microphone
                :</h4>
                <p>The DM17 delivers exceptional clarity for toms and snares, providing a true-to-life audio experience.
</p>
            </div>
            <div className='line-up'>
                <h4>SR20sp Gen 2 - Stereo Pair of Drum Overhead Microphones
                : </h4>
                <p>This pair captures the full ambiance of your kit with stunning stereo soundscapes, ideal for professional recordings.
</p>
            </div>
            <div className='line-up'>
                <h4>DK6 - 6-Piece Drum Microphone Kit 
                :
</h4>
                <p>This kit includes all the essential mics to fully capture your drum set, ensuring a professional sound from every angle.
</p>
            </div>
            <div className='line-up'>
                <h4>DM20 Gen 2 - Tom & Snare Mic
                :</h4>
                <p>The DM20 offers increased sensitivity and frequency response, perfect for nuanced performances on toms and snares.
</p>
            </div>

            <div className='line-up'>
                <h4>SR25mp Gen 2 - Drum Overhead Mics
                :</h4>
                <p>These overhead mics capture a wide sonic range, making them essential for a comprehensive drum sound.
</p>
            </div>

            <div className='line-up'>
                <h4>SR25 Gen 2 - Drum Microphone
                :</h4>
                <p>The SR25 is known for its natural sound and ability to handle high SPL situations effectively, making it great for all types of drumming.
</p>
            </div>

            <div className='line-up'>
                <h4>DK7 Gen 2 - Drum Mic Kit
                :</h4>
                <p>This comprehensive mic kit covers every drum in your setup, delivering exceptional audio fidelity and versatility for both live and studio settings.
</p>
            </div>

            <div className='line-up'>
                <h4>DM6 - Kick Drum Microphone
                :</h4>
                <p>The DM6 provides deep, powerful bass response and clarity, essential for achieving a full and rich kick sound.
</p>
            </div>

            <div className='line-up'>
                <h4>SR25mp Gen 2 - Drum Overhead Mics
                :</h4>
                <p>This set excels in capturing detailed overhead sound, perfect for studio recordings and live performances alike.
</p>
            </div>

            <div className='line-up'>
                <h4>DK7 Gen 2 - Drum Microphone Kit 
                :</h4>
                <p>This kit is engineered for sound professionals, providing everything needed to achieve a polished drum mix across diverse applications.
</p>
            </div>
          </div>
    </div>

   

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

       

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Audio Solutions at its Best
</h5><br/>
            <p>Earthworks Audio aspires to be a leader in sound capture innovation, consistently enhancing their technology to meet the evolving needs of artists and audio professionals. By embracing principles of sustainability and advancing sound technology, the brand envisions a future where microphones deliver not just a representation of sound, but an elevated auditory experience that connects listeners with the emotions of music. 
</p>
            <a target='_blank' href='https://earthworksaudio.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Earthworks and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
