import React from 'react';
import aoto1 from '../../Assets/sennheiser/Wireless systems-1.png';
import aoto2 from '../../Assets/sennheiser/Wireless systems-2.png';
import aoto3 from '../../Assets/sennheiser/Wireless systems-3.png';
import aoto4 from '../../Assets/sennheiser/Wireless systems-4.png';

//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>EM 6000</h3>
            <p className='thro'>Digital 2-channel receiver for live productions and transmissions, even in the toughest environments. Huge spectral efficiency with equidistant frequency grid and true bit diversity.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>EW-D SK (Q1-6)</h3>
            <p className='thro'>Digital UHF bodypack transmitter for use with Evolution Wireless Digital.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>EW-DX EM 2 (Q1-9)</h3>
            <p className='thro'>Two-channel digital half-rack (9,5") receiver for use with Evolution Wireless Digital handheld, bodypack and tablestand transmitters.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>EW-DX EM 2 DANTE (Q1-9)</h3>
            <p className='thro'>Two-channel digital half-rack (9,5") Dante®-enabled receiver for use with Evolution Wireless Digital handheld, bodypack and tablestand transmitters.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;