import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What are the key features of Prysm's LPD 6K Series displays?",
      answer: "Prysm's LPD 6K Series displays offer impressive 6K resolution, bezel-less design, and interactive functionalities for dynamic presentation and collaboration.",
    },
    {
      question: "What benefits does Prysm Application Suite offer?",
      answer: "Prysm Application Suite streamlines the presentation and collaboration process by integrating all content, tools, and applications onto a single digital canvas, fostering continuous workflow and efficiency.",
    },
    {
      question: "How does Prysm support remote collaboration?",
      answer: "Prysm facilitates remote collaboration with its intuitive collaboration software tools, including video conferencing, digital whiteboards, and real-time content sharing.",
    },
    {
      question: "What industries can benefit from Prysm solutions?",
      answer: "Prysm solutions cater to a variety of industries, including corporate, retail, finance, healthcare, and education.",
    },
    {
      question: "Is Prysm customizable for my specific business needs?",
      answer: "Yes, Prysm solutions offer flexibility in terms of configuration, display size, and software integrations, allowing for tailored solutions that fit specific business requirements.",
    },
    {
        question: "How do I purchase Prysm products?",
        answer: "You can explore our product offerings and purchase options through our website or by contacting our sales team.",
      },
      {
        question: "Can I get a trial for Prysm Application Suite and LPD 6K Series displays?",
        answer: "Yes, Prysm offers free trials for both Prysm Application Suite and LPD 6K Series displays. Please contact our team to schedule a demo.",
      },
      {
        question: "How is customer support provided for Prysm products?",
        answer: "Our dedicated support team offers assistance through phone, email, and online chat, with comprehensive resources and tutorials on our website.",
      },
      {
        question: "Are Prysm solutions compatible with various software platforms?",
        answer: "Yes, Prysm technology is compatible with multiple platforms and integrates seamlessly with popular collaboration tools and software.",
      },
      {
        question: "What sets Prysm aside from traditional collaboration solutions?",
        answer: "Prysm stands out through its groundbreaking 6K display technology and intuitive collaboration software, delivering immersive visual experiences that elevate the collaboration and presentation experience.",
      },
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
