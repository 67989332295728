import React from 'react';
import aoto1 from '../../Assets/Nviccta/nviccta-key1.jpg';
import aoto2 from '../../Assets/Nviccta/nviccta-key2.jpg';
import aoto3 from '../../Assets/Nviccta/nviccta-key3.jpg';
import aoto4 from '../../Assets/Nviccta/nviccta-key4.jpg';
import aoto5 from '../../Assets/Nviccta/nviccta-key5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Comprehensive Product Range</h3>
            <p className='thro'>Nvictta offers an extensive lineup of advanced audio-visual products, including HDMI cables, power conditioners, cable managers, and wall mounts. This diverse range ensures that customers can find complete solutions that meet their installation and project requirements without compromise.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>High-Quality Assurance</h3>
            <p className='thro'>Each product in the Nvictta lineup is crafted with high-grade materials and designed to meet rigorous industry standards. This commitment to quality ensures optimal performance and durability, giving clients peace of mind for their audio-visual installations.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Dedicated Technical Support</h3>
            <p className='thro'>Nvictta prides itself on providing exceptional technical and sales support. With a skilled team ready to assist, customers can rely on expert guidance throughout their purchasing and installation process, ensuring they make informed decisions tailored to their specific needs.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Value-Added Reseller Partnership </h3>
            <p className='thro'>As a true value-added reseller, Nvictta establishes strong partnerships with system integration companies, offering them tailored solutions and comprehensive service that enhance their business capabilities and project outcomes.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Focus on Innovation</h3>
            <p className='thro'>Nvictta stays ahead of the curve by continually researching and integrating the latest technologies into its product offerings. This focus on innovation allows clients to benefit from cutting-edge solutions that improve efficiency and user experience in all audio-visual applications.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;