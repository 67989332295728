import React from 'react';
import Audkey1 from '../../Assets/Earthworks/ea-1.jpg';
import Audkey2 from '../../Assets/Earthworks/ea-2.jpg';
import Audkey3 from '../../Assets/Earthworks/ea-3.jpg';
import Audkey4 from '../../Assets/Earthworks/ea-4.jpg';
import Audkey5 from '../../Assets/Earthworks/ea-5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Innovative IoT Solutions
</h3>
            <p className='thro'>HARMAN is at the leading edge of the Internet of Things (IoT), embedding advanced technologies like conversational AI into applications that enhance user experience. By crafting unique IoT solutions, HARMAN enables seamless connectivity and interaction across devices, positioning itself as a key player in this rapidly evolving market.
</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Lightning-Fast Response
</h3>
            <p className='thro'>Engineered for rapid transient response, Earthworks microphones rise and settle within 
            microseconds, allowing for detailed capture of every nuance. 
            This exceptional speed means artists can rely on Earthworks for an authentic representation of their performance.
</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>5G Integration and Future Mobility
</h3>
            <p className='thro'>As the automotive industry transitions to 5G technology, HARMAN ensures that automakers are equipped 
            with an optimized network infrastructure. Their expertise in backward compatibility with previous networks, alongside a 
            roadmap to future technologies, facilitates faster production times and improved customer satisfaction.
</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Advanced Driver-Assistance Systems (ADAS)
</h3>
            <p className='thro'>HARMAN is pioneering advancements in ADAS, focusing on reducing human error and enhancing safety. By integrating powerful cockpit architectures, they enable a future of zero-accident mobility, ensuring safer journeys for all.
</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Seamless Digital Cockpit Experiences
</h3>
            <p className='thro'>The HARMAN Digital Cockpit serves as an experiential hub, combining navigation, entertainment, and control functionalities into a single user-friendly platform. This design not only enhances safety but also facilitates a connected lifestyle, making it an essential component for modern vehicles.
</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;