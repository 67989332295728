import React from 'react';
import FAQSection from '../FAQ/CareerFAQ';
import CareerCarousel from '../OwlCarousel/CareerCarousel';
// import CareerForm from '../Forms/Forms'
import './Career.css';
import { Helmet } from 'react-helmet-async';

const About = () => {
  return (
    <div>
     <Helmet>
                <title>Nvincy Solutions</title>
                <meta name="Nvincy Careers | Find New Job Opportunities Best For You" content="Looking for a Job Change? Nvincy is Hiring. Join our team at Nvincy Today! Find new job opportunities and career advancements with us. Apply Now." />
                <meta name="keywords" content="New Job Opportunities, Job Postings, Looking for a job, remote Job Online" />
            </Helmet>
        <div class="career-sub_banner">
          <div class="career-banner-content">
            <h1 class="career-h1">Wanna Join Nvincy's <br/> Talent Network?</h1>
  </div>
</div>


        <div className='cust-container' >
          <h3 class="text-center htag">Job Application Form</h3>
          <p class="text-center mb-5">Ready to take the first step towards a fulfilling career with nvincy? <br/>
          Fill out our application form to apply for open positions or express your interest in internships.</p>
          <div>
          <iframe title="Contact Us" aria-label='Contact Us' frameborder="0" style={{height: "900px", width: "100%", border: "none"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/CareerForm/formperma/4fyD2iOX1p8M8JxQEhQRcU4fh-_KoaDHPWpHyjJrwik'></iframe>
          </div>
          
        </div>

        <div className='cust-container' >
          <h2 class="text-center">Discover How You Can <br/> Make an Impact at Nvincy</h2>
          <CareerCarousel/>
        </div>
        
        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>FAQ (Curriculum)</h2>
          <FAQSection/>
        </div> 


        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Stay Updated</h1>
              <p className="newsletter-description py-4">
              You are everything to us; subscribe to get our special discounts, <br/> the latest news, and more at your fingertips.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 
      </div>
  )
}

export default About
