import React from 'react';
import Audkey1 from '../../Assets/Maxhub/maxhubkey1.jpg';
import Audkey2 from '../../Assets/Maxhub/maxhubkey2.jpg';
import Audkey3 from '../../Assets/Maxhub/maxhubkey3.jpg';
import Audkey4 from '../../Assets/Maxhub/maxhubkey4.jpg';
import Audkey5 from '../../Assets/Maxhub/maxhubkey5.jpg';
import Audkey6 from '../../Assets/Maxhub/maxhubkey6.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Microphone Mist™ Technology 
</h3>
            <p className='thro'>Nureva’s groundbreaking Microphone Mist technology approach allows every voice to be easily captured, regardless of where participants 
            are positioned in the room. With multiple patented technologies, this creates a more inclusive and collaborative environment.

</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Automated Camera Switching 
</h3>
            <p className='thro'>The integrated automated camera switching feature enhances the video conferencing experience by tracking who is speaking in larger rooms. Using sophisticated sound location data, the system activates the appropriate cameras to focus on the person in the speaking zone. 
</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Teams-Certified Products 
</h3>
            <p className='thro'>Nureva and MAXHUB offer a range of products that are certified for Microsoft Teams, facilitating simple and effective setup for Teams Rooms. This certification guarantees that all elements, from speakers to touch screen displays, are optimized for seamless operation with Microsoft’s collaboration platform. 
</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Immersive Spatial Audio 
</h3>
            <p className='thro'>The combination of Nureva’s spatial audio capabilities and MAXHUB’s audio-visual devices creates an immersive video conferencing atmosphere that simulates the experience of being face-to-face. Participants can enjoy a natural and engaging audio experience, where conversations are richer and more engaging.
</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Comprehensive AV Solutions 
</h3>
            <p className='thro'>Nureva + MAXHUB provides a full range of audio-visual solutions designed for various room sizes and layouts. With options ranging from compact setups for small rooms to extensive configurations for large boardrooms, these holistic solutions combine audio systems, touch screen displays, control panels, and more for a one-stop-shop experience in meeting room design.
</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>User-Friendly Setup and Management 
</h3>
            <p className='thro'>The intuitive design of Nureva + MAXHUB products simplifies setup and management for users, regardless of technical expertise. Easy-to-follow instructions and user-centered interfaces enable staff to quickly install and configure systems without the need for extensive training or IT involvement. 

</p>
        </div>
        <img
            src={Audkey6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;