import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What sizes of spaces do MAXHUB solutions support?",
      answer: "MAXHUB solutions support a wide range of space sizes, from small to extra-large rooms.",
    },
    {
      question: "Do MAXHUB solutions support spatial audio?",
      answer: "The AI tracking technology uses advanced processing algorithms to follow subjects in real-time, keeping them centered within the frame regardless of their movement.",
    },
    {
      question: "How do I set up camera switching on MAXHUB?",
      answer: "MAXHUB's camera switching feature is easy to set up and can be configured using the MAXHUB control panel or software tools for the best video conferencing experience.",
    },
    {
      question: "What is sound location data?",
      answer: "Sound location data is a technology that tracks the location of voices in a room and automatically switches cameras to capture the speaker.",
    },
    {
      question: "Can I customize MAXHUB solutions to meet my organization's specific needs?",
      answer: "Yes, MAXHUB offers customizable solutions to meet the unique needs of each organization.",
    },
    {
      question: "What types of collaboration platforms does MAXHUB support?",
      answer: "MAXHUB solutions support multiple collaboration platforms, including Microsoft Teams.",
    },
      
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
