import React from 'react';
import Audkey1 from '../../Assets/CatchBox/CatchBox-key1.jpg';
import Audkey2 from '../../Assets/CatchBox/CatchBox-key2.jpg';
import Audkey3 from '../../Assets/CatchBox/CatchBox-key3.jpg';
import Audkey4 from '../../Assets/CatchBox/CatchBox-key4.jpg';
import Audkey5 from '../../Assets/CatchBox/CatchBox-key5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Easy-to-Use Wireless Microphone System
</h3>
            <p className='thro'>Catchbox offers an intuitive DSP wireless microphone system that requires minimal technical expertise. Users can set up and operate the system quickly, allowing educators and corporate leaders to focus on their message rather than technical details.

</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Interactive Design

</h3>
            <p className='thro'>The signature throwable microphone encourages audience participation and engagement in a fun, interactive manner. This unique design transforms the traditional static microphone experience, fostering open dialogue in classrooms and meetings.

</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Versatile Applications


</h3>
            <p className='thro'>Perfectly suited for a wide range of environments, including universities, corporate offices, and conference settings, Catchbox can be tailored to meet specific audio needs across various industries, making it a versatile choice for any organization.


</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Broadcast Quality Audio

</h3>
            <p className='thro'>Despite its playful design, Catchbox delivers exceptional audio quality, ensuring crystal clear sound capture in large rooms or noisy environments. This focus on quality makes it ideal for both in-person and hybrid meetings.



</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Robust and Durable Build
</h3>
            <p className='thro'>Designed for regular use in busy environments, Catchbox microphones are rugged and reliable, capable of withstanding the rigors of daily life. Their durability ensures consistent performance over time, even in high-traffic areas.
</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;