import React from 'react';
import alsee1 from '../../Assets/Alsee/alsee-key1.jpg';
import alsee2 from '../../Assets/Alsee/alsee-key2.jpg';
import alsee3 from '../../Assets/Alsee/alsee-key3.jpg';
import alsee4 from '../../Assets/Alsee/alsee-key4.jpg';
import alsee5 from '../../Assets/Alsee/alsee-key5.jpg';
import alsee6 from '../../Assets/Alsee/alsee-key6.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: false,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp key" style={{ width: "430px", borderColor: "white" }}>
        <div className="card-body card-height">
            <h3>High-Quality Displays</h3>
            <p className='thro'>The high-resolution displays ensure visibility even in bright environments with high-brightness options. You can create seamless video walls with 
            minimal bezels for an immersive experience.</p>
        </div>
        <img
            src={alsee1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp key" style={{ width: "430px", borderColor: "white" }}>
        <div className="card-body card-height">
            <h3>Interactive Touch Screens</h3>
            <p className='thro'>With the advanced PCAP technology usage, you can experience precise and responsive touch screens and run various android-powered 
            applications making it an ideal choice for high-traffic areas with durable construction.</p>
        </div>
        <img
            src={alsee2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Powerful Media Players</h3>
            <p className='thro'>The 4K resolution support delivers stunning visuals with exceptional detail. 
            You can also get versatile connectivity options to various devices and networks and easily manage your media players from a central location remotely.</p>
        </div>
        <img
            src={alsee3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Flexible Software Solutions</h3>
            <p className='thro'>With the user-friendly interface, you can create and manage content with ease, set schedules for content playback and automate tasks. And, easily connect with your existing software and hardware.
</p>
        </div>
        <img
            src={alsee4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Quick Customizable Solutions</h3>
            <p className='thro'>You can get a variety of display sizes, configurations, and accessories that you can customize the appearance of your digital signage to match your brand. You can also get professional help designing and creating captivating content.

</p>
        </div>
        <img
            src={alsee5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Reliable Hardware Resources
</h3>
            <p className='thro'>Built to withstand harsh environments and daily use with need for absolutely low maintenance and minimum downtime. Now, you can protect your hardware with your investment with our optional extended warranties too.


</p>
        </div>
        <img
            src={alsee6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;