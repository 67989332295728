import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of installations do Multi Brackets products support?",
      answer: "Micro Brackets products are designed for a wide variety of installations, including residential, corporate, and commercial environments, making them suitable for home entertainment systems, office spaces, and digital signage displays.",
    },
    {
      question: "Are the products easy to install?",
      answer: "Yes, our products are engineered for simplicity and ease of installation, allowing most customers to set them up without the need for professional help.",
    },
    {
      question: "Can I find replacement parts if needed?",
      answer: "Absolutely! We offer replacement parts for many of our products, ensuring they can be maintained and serviced over time.",
    },
    {
      question: "What is the weight limit for your wall mounts?",
      answer: "Weight limits vary by product model. Detailed specifications for each mount can be found in our product descriptions on the website.",
    },
    {
      question: "Can I use Micro Brackets products for outdoor displays?",
      answer: "While many products are suitable for indoor use, we do offer specific solutions designed for outdoor environments. Please check the product specifications for suitability.",
    },
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
