import React from 'react';
import aoto1 from '../../Assets/Audac/AudacWall1.jpeg';
import aoto2 from '../../Assets/Audac/AudacWall2.jpeg';
import aoto3 from '../../Assets/Audac/AudacWall3.jpeg';
import aoto4 from '../../Assets/Audac/AudacWall4.jpeg';

//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>WP205</h3>
            <p className='thro'>ARES5A wall panel - Microphone & line input - 80 x 80 mm</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>VC3036
            </h3>
            <p className='thro'>100V volume controller 36W 45 x 45 mm</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>CF45DH
           
           </h3>
            <p className='thro'>  Cover frame double 45 x 45 mm with hooks</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>WB45D/FG</h3>
            <p className='thro'>Flush mount box for double 45 x 45 mm wall panel - Hollow wall</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>








  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;