import React from 'react';
import aoto1 from '../../Assets/Nexmosphere/NexKey1.jpg';
import aoto2 from '../../Assets/Nexmosphere/NexKey2.jpg';
import aoto3 from '../../Assets/Nexmosphere/NexKey3.jpg';
import aoto4 from '../../Assets/Nexmosphere/NexKey4.jpg';
import aoto5 from '../../Assets/Nexmosphere/NexKey5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item ">
            <h3>Comprehensive Sensor Range</h3>
            <p className='thro'>Nexmosphere boasts an extensive selection of sensors—including presence, motion, weight, and light sensors—that cater to diverse applications. This versatility allows businesses to implement tailored solutions that fit their unique operational needs and enrich user experiences.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item ">
            <h3>Seamless Integration</h3>
            <p className='thro'>Each sensor and controller in the Nexmosphere lineup is designed for easy integration with existing systems. This ensures a smooth installation process, allowing businesses to enhance their environments without the need for extensive downtime or complicated setups. </p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item ">
            <h3>Intelligent Interactivity</h3>
            <p className='thro'>Nexmosphere products enable environments to respond intelligently to user behavior. By leveraging advanced technology, like Lidar and RFID, they facilitate real-time interaction, which enhances customer engagement and creates dynamic, responsive spaces.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item ">
            <h3>User-Centric Design</h3>
            <p className='thro'>With a focus on usability, every device and interface is designed with the end-user in mind. This commitment to user-centric design ensures that both installation technicians and consumers can easily navigate, understand, and utilize these technologies.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item ">
            <h3>Robust Support and Resources</h3>
            <p className='thro'>Nexmosphere provides comprehensive support and resources, including product documentation, technical assistance, and a dedicated integrator portal. This ensures that clients have all the tools they need for successful deployment and operation.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;