import React from 'react';
import './BrightSign.css'
import BrightSignKey from '../OwlCarousel/BrightSignKey';
import BrightSignXT from '../OwlCarousel/BrightSignXT';
import BrightSignXD from '../OwlCarousel/BrightSignXD';
import BrightSignLS from '../OwlCarousel/BrightSignLS';
import BrightSignSoc from '../OwlCarousel/BrightSignSoc';
import BrightSignLegacy from '../OwlCarousel/BrightSignLegacy';
import grpicon from '../../Assets/BrightSIgn/grp-icon.jpg';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/BrightSignFAQ';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | BrightSign</title>
                <meta name="Best Digital Signage Solutions - Bright Sign Media Players" content="BrightSign: Leading Provider of User-Friendly, Commercial Digital SIgnage Solutions. Create Engaging Dispplays With BrightSign's HTML5 Media Players Easily. " />
                <meta name="keywords" content="High Resolution Audio, AV systems, Audio Video Technology, Dante Network, High Resolution AV systems" />
            </Helmet>
        </div>

        <div class="BRIGHTSIGN-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Recognised “Global Manufacturer <br/> of the Year”
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.brightsign.biz/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Best Commercial Digital Signage Solutions</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    BrightSign is a global leader in digital signage. They offer a variety of products for commercial digital signage, including media players, digital signage, and networking solutions. Their products are known for their performance, reliability, ease of use, and affordability.
<br/><br/>BrightSign is renowned for its unmatched performance, reliability, ease of use, and affordability. BrightSign has become the go-to choice for businesses worldwide. They have established themselves as the global market leader in digital signage solutions with offices in Europe and Asia.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Lift Your Digital Signage Processes to the Next Level</h2>
            <BrightSignKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5"> HTML5 Powered for Enhanced Visuals
</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">XT Product Line</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">XD Product Line</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">LS Product Line</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Wall">Built In Soc</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Solution">Legacy Products</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <BrightSignXT/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <BrightSignXD/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <BrightSignLS/>
                </Tab.Pane>

                <Tab.Pane eventKey="Wall">
                    <BrightSignSoc/>
                </Tab.Pane>
                
                <Tab.Pane eventKey="Solution">
                    <BrightSignLegacy/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.brightsign.biz/' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Streamlined Digital Signage Solutions for Effective Communication
</h4>
            <div className='line-up'>
                <h4>BrightSign XT Series 
                :</h4>
                <p>High-performance players designed for demanding applications, offering advanced features and reliable operation. Perfect for large-scale deployments and complex installations.
</p>
            </div>
            <div className='line-up'>
                <h4>BrightSign XD Series
                : </h4>
                <p>Versatile players suitable for a wide range of applications, combining performance with ease of use. A great option for businesses and organizations seeking reliable and affordable digital signage solutions.
</p>
            </div>
            <div className='line-up'>
                <h4>BrightSign LS Series
                :
</h4>
                <p>Compact and cost-effective players designed for smaller-scale installations. Ideally suited for point-of-sale displays and commercial digital signage needs.
</p>
            </div>
            <div className='line-up'>
                <h4>BrightSign HTML5 Players
                :</h4>
                <p>Players with advanced HTML5 capabilities that enable the creation of rich, interactive digital signage experiences. These players unlock a wealth of creative possibilities for dynamic content and user engagement.
</p>
            </div>
          </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
        <h2>What sets Brightsign apart?</h2> <br/><br/>
        <img className='center' src={grpicon} alt="icon"></img>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

       

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Simplifying Digital Signage Processes
</h5><br/>
            <p>BrightSign's future vision is to continue pushing the boundaries of digital signage technology. We envision a future where digital experiences are even more engaging, interactive, and personalized. This involves continued innovation in hardware and software, including advancements in processing power, higher resolution displays, enhanced interactivity, and expanded content management capabilities. We'll also focus on integration with emerging technologies, such as AI and IoT, to create truly dynamic and responsive digital signage solutions that provide businesses with invaluable insights and drive superior engagement.
</p>
            <a target='_blank' href='https://www.brightsign.biz/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about BrightSign and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
