import React from 'react';
import './Netgear.css';
import NetgearKey from '../OwlCarousel/Netgear';
import AotoCommercial from '../OwlCarousel/Netgear-MeshWiFiSystems';
import AotoCreative from '../OwlCarousel/Netgear-WiFiRouters';
import AotoRental from '../OwlCarousel/Netgear-CableModems';
import AotoControl from '../OwlCarousel/Netgear-5GMobileRouters';
import FAQ from '../FAQ/NetgearFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Netgear</title>
                <meta name="Best AV/IT Solutions With Quick And Easy Connectivity Options" content="Enjoy High-Speed Performance And Easy-Connectivity With Net Gear's Pro AV Solutions. Get Expert Trouble Shooting Assistance and AI & IoT Integration Too." />
                <meta name="keywords" content="High-speed performance, Trouble shooting assistance, AV/IT applications, Ai & IoT, Easy connectivity" />
            </Helmet>
        </div>

        <div class="netgear-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Speed.
Simplicity.

<br/> Seamless Integration.
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.netgear.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Netgear's Pro AV/IT Solutions</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Netgear is a pioneer in networking and connectivity solutions, dedicated to transforming how individuals and businesses connect and 
                    communicate in an increasingly digital world. With a robust portfolio that spans consumer, business, and service provider markets, Netgear offers 
                    cutting-edge products crafted to meet the demands of modern lifestyles. <br/><br/>From enhancing home entertainment systems to securing enterprise networks, 
                    the brand embodies innovation and reliability. With a commitment to exceptional customer service and user-friendly designs, Netgear ensures that seamless 
                    connectivity isn't just a luxury, but an integral part of everyday life.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Seamless AV/IT Connectivity Solutions</h2>
            <NetgearKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Explore Our High-Definition Displays</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Mesh WiFi Systems</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">WiFi Routers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Control">5G Mobile Routers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Cable Modems</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AotoCreative/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AotoRental/>
                </Tab.Pane>

                <Tab.Pane eventKey="Control">
                    <AotoControl/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.netgear.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Premium LED Displays for Every Need </h4>
            <div className='line-up'>
                <h4>Nighthawk Routers:</h4>
                <p>Designed for high performance in gaming and streaming, Nighthawk routers offer advanced features like dynamic QoS and powerful Wi-Fi coverage.</p>
            </div>
            <div className='line-up'>
                <h4>Orbi Mesh Wi-Fi Systems:</h4>
                <p>These systems deliver reliable whole-home Wi-Fi coverage through a unique mesh network, eliminating dead zones and ensuring a strong signal in every room.</p>
                 </div>
            <div className='line-up'>
                <h4>Switches: </h4>
                <p>Netgear switches provide scalable, high-performance connectivity for networks, facilitating efficient data transfer among devices.</p>
            </div>
            <div className='line-up'>
                <h4>Wireless Access Points:</h4>
                <p>These access points extend your existing network with reliable connectivity, perfect for environments that require seamless internet access. </p>
            </div>
            <div className='line-up'>
                <h4>Network Attached Storage (NAS):</h4>
                <p>Netgear’s NAS devices offer secure data storage solutions that enhance file sharing and backup capabilities across networks.</p>
            </div>
            <div className='line-up'>
                <h4>Smart Home Devices:</h4>
                <p>Enable smart living with NETGEAR’s range of smart home products, allowing users to manage their home devices effortlessly through mobile apps.</p>
            </div>
            <div className='line-up'>
                <h4>Powerline Adapters:</h4>
                <p>These adapters utilize existing electrical wiring in your home to extend your network, providing reliable connections in every corner of your space.</p>
            </div>
            <div className='line-up'>
                <h4>ProSafe VPN Firewalls:</h4>
                <p>Ideal for small to medium-sized businesses, these firewalls provide essential security and VPN capabilities, protecting sensitive information from unauthorized access.</p>
            </div>
            <div className='line-up'>
                <h4>Audio over IP Solutions:</h4>
                <p>Netgear’s AoIP solutions enable high-quality audio streaming across networks, perfect for commercial audio applications.</p>
            </div>
            <div className='line-up'>
                <h4>SDVoE Technology:</h4>
                <p>Netgear’s commitment to SDVoE technology allows for advanced video over Ethernet solutions, enhancing the capabilities of digital signage and other AV applications.</p>
            </div>
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Experience the Future of High Resolution AV/IT Technology</h5><br/>
            <p>Netgear's future vision is centered on empowering individuals and businesses to thrive in a connected world. The brand aims to foster innovation by embracing emerging technologies, such as AI and IoT, which will further enhance user experiences and operational efficiency. By focusing on community engagement and continuous improvement, Netgear envisions a future where seamless connectivity is available to everyone, enabling smarter living and enhanced productivity across the globe.</p>
            <a target='_blank' href='https://www.netgear.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Netgear and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
