import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "How does Cyviz improve collaboration in hybrid environments?",
      answer: "By offering customizable solutions designed specifically for both in-person and remote interactions, Cyviz facilitates seamless participation, ensuring all members can engage effectively.",
    },
    {
      question: "What industries does Cyviz serve?",
      answer: "Cyviz serves a diverse range of industries including government and defense, corporate enterprises, and energy sectors, adapting its solutions to meet the unique needs of each sector.",
    },
    {
      question: "Are Cyviz products easy to use?",
      answer: "Yes, Cyviz products are designed with user-friendliness in mind, allowing for quick setup and minimal training requirements, ensuring everyone can utilize the technology effectively.",
    },
    {
      question: "Can Cyviz products be integrated with existing systems?",
      answer: "Absolutely! Cyviz solutions are built to integrate seamlessly with other technologies, particularly leveraging partnerships with major platforms like Microsoft for enhanced functionality.",
    },
    {
        question: "What types of spaces can Cyviz solutions be used in?",
        answer: "Cyviz offers solutions suitable for various environments, including meeting rooms, innovation centers, operation centers, and command and control rooms, making them versatile for different use cases.",
      },
      {
        question: "How does Cyviz ensure data security?",
        answer: "Cyviz is committed to maintaining high security standards across its platforms to protect user data and ensure that communication within collaboration spaces is confidential and secure.",
      },
      
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
