import React from 'react';
import './WyreStorm.css';
import Aoto1 from '../OwlCarousel/WyreStorm';
import AotoCommercial from '../OwlCarousel/WyreStorm-WyreStorm Office';
import AotoCreative from '../OwlCarousel/WyreStorm-Extenders';
import AotoRental from '../OwlCarousel/WyreStorm-Converters';
import AotoControl from '../OwlCarousel/WyreStorm-Cables';
import FAQ from '../FAQ/WyreStormFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | WyreStorm</title>
                <meta name="Best AV System Setup With High Quality Video Performance" content="WyreStorm Brings Seamless AV Systems with Simple AV Setups For Optimal Video Quality, User-Friendly Interfaces and High Quality Video Performaces.  Shop Now." />
                <meta name="keywords" content="Effective AV setup,user-friendly AV interfaces,Audio visual,AV systems" />
            </Helmet>
        </div>

        <div class="wyre-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Building the Future of Connectivity <br/> With the Smart Revolution
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.wyrestorm.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Update to High Quality Video Performance</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    WyreStorm is at the forefront of the audio-visual revolution, dedicated to providing exceptional products that transform how individuals and 
                    organizations communicate and collaborate. WyreStorm develops innovative technologies tailored to meet the diverse needs of corporate 
                    environments, educational institutions, residential setups, and entertainment venues. <br/><br/>Wyrestorm’s mission is to bridge the gap between advanced 
                    technology and user-friendly AV/IT interfaces, ensuring that every client can harness the power of superior audio-visual experiences and seamless connectivity.</p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Effective AV/IT Setup Redefined
</h2>
            <Aoto1/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Switch to user-Friendly AV Interfaces</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">WyreStorm Office</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Extenders</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Control">Cables</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Converters</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AotoCreative/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AotoRental/>
                </Tab.Pane>

                <Tab.Pane eventKey="Control">
                    <AotoControl/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.wyrestorm.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Advanced Audio Visual Technology</h4>
            <div className='line-up'>
                <h4>AV Over IP Solutions:</h4>
                <p>Designed for professional-grade performance, our NetworkHD systems deliver unparalleled video quality and reliability across a network, perfect for both large installations and small setups.</p>
            </div>
            <div className='line-up'>
                <h4>600 Series Matrix Switchers:</h4>
                <p>High-performance matrix switchers that support multiple sources and outputs with seamless switching capabilities, catering to extensive media setups in corporate and entertainment environments.</p>
            </div>
            <div className='line-up'>
                <h4>500 Series Extenders:</h4>
                <p>Compact and efficient extenders that allow users to transmit high-resolution video and audio over long distances with ease, ideal for both residential and commercial applications.</p>
            </div>
            <div className='line-up'>
                <h4>400 Series Presentation Switchers:</h4>
                <p>These switchers streamline the presentation process, allowing multiple device connections and supporting wired and wireless presentations for dynamic meeting environments.</p>
            </div>
            <div className='line-up'>
                <h4>Control Solutions:</h4>
                <p>WyreStorm’s controllers simplify the management of complex AV/IT systems. They provide intuitive control for users, enhancing the experience of switching and network management.</p>
            </div>
            <div className='line-up'>
                <h4>Unified Communication Devices:</h4>
                <p>Our speakerphones and video bars facilitate high-quality audio-visual communication, perfect for collaborative spaces in offices and educational settings.</p>
            </div>
            <div className='line-up'>
                <h4>In-Desk Connectivity Solutions:</h4>
                <p>Designed for modern meeting spaces, our in-desk connectivity options ensure all devices can be easily connected and managed from a central point, promoting organization and efficiency.</p>
            </div>
            <div className='line-up'>
                <h4>Cables and Adapters:</h4>
                <p>WyreStorm provides a range of professional-grade cables and adapters that ensure robust connections and compatibility across all AV/IT solutions.</p>
            </div>
            <div className='line-up'>
                <h4>All-in-One Bundles:</h4>
                <p>Comprehensive bundles that include everything needed for effective AV/IT setups, from presentation tools to network components, tailored to fit various environments.</p>
            </div>
            <div className='line-up'>
                <h4>Huddle Space Solutions:</h4>
                <p>Specialized kits designed for collaboration in smaller group settings, integrating audio, video, and presentation technologies efficiently.</p>
            </div> 
            
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

      

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Complete AV Systems For Every Perfect Experience
</h5><br/>
            <p>WyreStorm is committed to delivering exceptional audio-visual solutions that empower businesses, educational institutions, and residential users to communicate more effectively and collaborate seamlessly. With our advanced technology, expert support, and innovative products, we are your trusted partner in fulfilling all your Effective AV setup needs.</p>
            <a target='_blank' href='https://www.wyrestorm.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 
        <div className='cust-container'>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div>

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Allsee and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div>  
    </div>
  )
}

export default About
