import React from 'react';
import servicepage_1 from '../../Assets/servicepage_1.png';
import servicepage_2 from '../../Assets/servicepage_2.png';
import servicepage_3 from '../../Assets/servicepage_3.png';
import './AboutMyTeam.css';
import './ServiceCarousel.css';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: false,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 3,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp services" style={{ width: "430px", borderColor: "white" }}>
    <img
            src={servicepage_1}
            className="card-img-top zooming-image"
            alt="React Icon"
        />
        <div className="card-body">
            <h3>Digital Signage Solutions</h3>
            <h5>Giving Life To Walls as Dynamic Storytellers</h5>
            <p className='throw-service'>Nvincy's digital signage solutions breathe life into static displays, transforming them 
            into dynamic platforms for impactful communication. We craft captivating content that grabs attention, 
            creating a strong brand awareness that informs them of the brand, later converting them to leads, then as an absolute conversion as a lifetime user.</p>
        </div>
    </div>
</div>
<div className="item">
    <div className="card resp services" style={{ width: "430px", borderColor: "white" }}>
        <img
            src={servicepage_2}
            className="card-img-top zooming-image"
            alt="React Icon"
        />
        <div className="card-body">
            <h3>Digital Signage Content Creation</h3>
            <h5>Where Creativity Meets Impact</h5>
            <p className='throw-service'>Nvincy's team of creative experts are visual storytellers. Our team turns your vision into audience-pleasing content. Our expertise in video production, animation, and graphic design ensures your digital signage displays are not just visually stunning but also strategically made to achieve your content delivery goals as you expect them to be reached. </p>
        </div>
    </div>
</div>

<div className="item">
    <div className="card resp services" style={{ width: "430px", borderColor: "white" }}>
    <img
            src={servicepage_3}
            className="card-img-top zooming-image"
            alt="React Icon"
        />
        <div className="card-body">
            <h3>Wayfinding Solutions</h3>
            <h5>No More Confusing Layouts and Frustrated Visitors</h5>
            <p className='throw-service'>Nvincy's wayfinding solutions provide clear and intuitive guidance for navigating complex spaces. We create user-friendly signage systems, incorporating interactive elements and digital displays, 
            ensuring your visitors find their destination with ease, leaving a positive and lasting impression. Now, anyone can navigate their location with ease.</p>
        </div>
        
    </div>
</div>
<div className="item">
    <div className="card resp services" style={{ width: "430px", borderColor: "white" }}>
    <img
            src={servicepage_3}
            className="card-img-top zooming-image"
            alt="React Icon"
        />
        <div className="card-body">
            <h3>Application Development</h3>
            <h5>Empower Your Business with Custom-Built Apps</h5>
            <p className='throw-service'>In today's mobile-first world, apps are the key to unlocking new possibilities, both for service and business purposes. 
            Nvincy doesn't believe in one size fits all. We develop custom-built applications that seamlessly integrate with your brand and vision, 
            empowering your business to achieve its full potential. </p>
        </div>
    </div>
</div>

  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;