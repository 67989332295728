import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of audio-visual products does Nvictta offer?",
      answer: "Nvictta provides a wide range of products, including HDMI cables, power conditioners, cable management systems, wall mounts, and cameras, catering to diverse installation needs.",
    },
    {
      question: "Are Nvictta products compatible with all audio-visual equipment?",
      answer: "Yes, Nvictta products, especially HDMI cables and power conditioners, are designed to be widely compatible with most audio-visual equipment, supporting various brands and models.",
    },
    {
      question: "What makes Nvictta cables better than others on the market?",
      answer: "Nvictta cables are constructed with high-quality materials and undergo rigorous testing to ensure optimum performance, durability, and reliable signal transfer.",
    },
    {
      question: "Does Nvictta provide warranty on its products?",
      answer: "Yes, all Nvictta products come with a warranty, ensuring quality and reliability for your audio-visual installations.",
    },
    {
      question: "Does Nvictta have distribution partners?",
      answer: "Yes, Nvictta collaborates with system integration partners and resellers to distribute its products, enhancing service offerings across various markets.",
    },
    
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
