import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "How does Catchbox improve audience interaction?",
      answer: "By encouraging audience members to pass the microphone around, Catchbox fosters a more interactive and engaging dialogue, making it perfect for discussions and Q&A sessions.",
    },
    {
      question: "Where can Catchbox be used?",
      answer: "Catchbox is versatile and can be used in universities, corporations, conferences, and other spaces where quality audio and audience interaction are essential.",
    },
    {
      question: "Is Catchbox easy to set up?",
      answer: "Yes, Catchbox is designed with user-friendliness in mind, requiring minimal technical expertise for setup and operation so that anyone can use it with ease.",
    },
    {
      question: "What type of audio quality can I expect?",
      answer: "Catchbox delivers broadcast-quality audio, ensuring clear sound capture even in larger venues or challenging acoustic environments.",
    },
    {
        question: "How durable is the Catchbox microphone?",
        answer: "The Catchbox microphone is built to be robust and durable, designed to withstand the wear and tear of regular use in busy environments.",
      },
      {
        question: "Can Catchbox be used in hybrid meetings?",
        answer: "Absolutely! Catchbox is ideal for hybrid meetings, providing clear audio for both in-person and virtual attendees.",
      },
      
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
