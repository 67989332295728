import React from 'react';
import Audkey1 from '../../Assets/Prysm/prysmkey1.jpg';
import Audkey2 from '../../Assets/Prysm/prysmkey2.jpg';
import Audkey3 from '../../Assets/Prysm/prysmkey3.jpg';
import Audkey4 from '../../Assets/Prysm/prysmkey4.jpg';
import Audkey5 from '../../Assets/Prysm/prysmkey5.jpg';
import Audkey6 from '../../Assets/Prysm/prysmkey6.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Immersive Visual Experience
</h3>
            <p className='thro'>Prysm's LPD 6K Series displays offer stunning 6K resolution, a high contrast ratio, and interactivity, creating an immersive experience that engages audiences like never before. Thus, it encourages audience participation with its interactive Touch screen features.
</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Content Integration
</h3>
            <p className='thro'>The Prysm Application Suite brings together all content, tools, and applications onto a single digital canvas, streamlining the presentation and collaboration process. It righteously eliminates the need to switch between multiple tools or applications.
</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Interactive Touch Screen for Immersive Engagement
</h3>
            <p className='thro'>Prysm's advanced features and user-friendly interface are designed to engage audiences and facilitate seamless interaction. Thus create additional support during remote collaborations for virtual teams.
</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>High-Scale Customization
</h3>
            <p className='thro'>Prysm accommodates diverse business needs by offering flexible configurations and customization options. It supports multiple display sizes and layouts offering integration with a range of collaboration softwares too.
</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Scalability and Mobility
</h3>
            <p className='thro'>Prysm solutions are designed to adapt to changing business needs, supporting both physical and virtual workspaces. Their Products support flexible scaling to accommodate different meeting and work styles. It also provides consistent experiences across various physical and virtual environments.
</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Enhanced Collaboration Software
</h3>
            <p className='thro'>Prysm Application Suite is designed to support various use cases, including everyday meetings, training sessions, product development, and dynamic presentations. It also supports various file sharing and real-time collaboration features.
</p>
        </div>
        <img
            src={Audkey6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>




  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;