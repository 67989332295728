import React from 'react';
import Audkey1 from '../../Assets/Cyviz/CyvizSol1.jpg';
import Audkey2 from '../../Assets/Cyviz/CyvizSol2.jpg';
import Audkey3 from '../../Assets/Cyviz/CyvizSol3.jpg';
import Audkey4 from '../../Assets/Cyviz/CyvizSol4.jpg';
import Audkey5 from '../../Assets/Cyviz/CyvizSol5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Innovation centers
</h3>
            <p className='thro'>Cyviz’s Innovation Centers foster creativity and innovation by integrating people, resources, and advanced technologies, serving as catalysts for growth and positive change through strategic design and effective management.


</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Operation centers
</h3>
            <p className='thro'>Cyviz’s Operation Centers provide adaptable hubs for real-time monitoring and decision-making, enhancing operational resilience through seamless data sharing and communication, ensuring efficiency in routine and emergency situations.
</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Meeting Rooms
</h3>
            <p className='thro'>Cyviz’s high-impact meeting rooms foster innovation with advanced technology and flexible layouts, enhancing collaboration, communication, and creative problem-solving to drive productivity and achieve strategic business goals effectively.

</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Command and Control Rooms
</h3>
            <p className='thro'>Cyviz Control Rooms offer secure, dynamic spaces for command operations, integrating real-time data sharing and communication, enabling effective decision-making with turnkey solutions that meet stringent security standards across various sectors.
</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Signature Microsoft Team Rooms
</h3>
            <p className='thro'>Cyviz's Signature Teams Rooms enhance hybrid meetings with dynamic environments for seamless collaboration. Adaptable layouts support diverse meeting types, optimizing participation and content sharing while ensuring effortless maintenance and rapid support.

</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;