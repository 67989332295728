import React from 'react';
import aoto1 from '../../Assets/Aoto/AotoCreative1.png';
import aoto2 from '../../Assets/Aoto/AotoCreative2.png';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: false,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: false,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>C Series</h3>
            <p className='thro'>Industry applications: TV STUDIO/ CONFERENCE ROOM/TRANSPORTATION Pixel Pitch (mm): 1.8/2.5/3 Calibrated Brightness (nits): 1000 nits LED type : SMD 3 in 1</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>T Series</h3>
            <p className='thro'>Industry applications: DIGITAL MEDIA/EVENTS/TRANSPORTATION Pixel Pitch (mm): 3.9/7.8/ 10 Calibrated Brightness (nits): 5500 nits LED type : SMD 2516/SMD 4312/ SMD 4312</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>


  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;