import React from 'react';
import Audkey1 from '../../Assets/Laia/LaiaKey1.jpg';
import Audkey2 from '../../Assets/Laia/LaiaKey2.jpg';
import Audkey3 from '../../Assets/Laia/LaiaKey3.jpg';
import Audkey4 from '../../Assets/Laia/LaiaKey4.jpg';
import Audkey5 from '../../Assets/Laia/LaiaKey5.jpg';
import Audkey6 from '../../Assets/Laia/LaiaKey6.jpg';
import Audkey7 from '../../Assets/Laia/LaiaKey7.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>AI-Powered Tracking 
</h3>
            <p className='thro'>Laia’s advanced AI tracking technology allows for precise and automatic monitoring of speakers and subjects during live events. This ensures that every movement is captured without manual adjustment, making the experience smoother and more intuitive for users.
</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Superior Image Quality
</h3>
            <p className='thro'>With 4K UHD resolution and high-quality CMOS sensors, Laia cameras provide vibrant and crystal-clear images. The detail captured enhances presentations and broadcasts, ensuring that every nuance is visible to the audience.
</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Versatile Connectivity Options
</h3>
            <p className='thro'>Designed for modern professional environments, Laia cameras come with multiple connectivity interfaces, including HDMI, USB 3.0, and IP outputs. This compatibility ensures easy integration with a variety of platforms and technologies for seamless operation.
</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>User-Friendly Operation
</h3>
            <p className='thro'>Our cameras are built with simplicity in mind, allowing users to initiate streaming and recording with just a click. This is particularly beneficial in fast-paced situations where ease of use is paramount.
</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>High Optical Zoom and Low-Light Performance
</h3>
            <p className='thro'>Equipped with robust optical zoom capabilities, Laia cameras can capture detailed images from great distances. Additionally, with low-light performance enhancements, users can count on exceptional clarity, even in challenging lighting conditions.
</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Privacy and Security Features
</h3>
            <p className='thro'>Privacy covers are included with our cameras, allowing users to maintain control over when their camera is recording. This feature is crucial for events that require discretion and security, providing peace of mind in various environments.
</p>
        </div>
        <img
            src={Audkey6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>
Multiple Control Protocols
</h3>
            <p className='thro'>Laia cameras support a wide range of control protocols including VISCA, Pelco-D, and RS422, facilitating seamless integration into existing setups. This flexibility makes them suitable for various operational requirements.
</p>
        </div>
        <img
            src={Audkey7}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;