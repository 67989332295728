import React from 'react';
import aoto1 from '../../Assets/Netgear/CableModems/Cable Modems-1.png';
import aoto2 from '../../Assets/Netgear/CableModems/Cable Modems-2.png';
import aoto3 from '../../Assets/Netgear/CableModems/Cable Modems-3.png';
import aoto4 from '../../Assets/Netgear/CableModems/Cable Modems-4.png';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: false,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Nighthawk® Mid/high-Split Multi-Gig 2.5Gbps Cable Modem</h3>
            <p className='thro'>Engineered for high-speed cable internet, this modem offers 2.5Gbps download speeds.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Nighthawk® Mid/high-Split Multi-Gig 2Gbps Cable Modem</h3>
            <p className='thro'>This DOCSIS® 3.1 modem offers 2Gbps download and 1Gbps upload speeds.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Nighthawk® Multi-Gig 2.5Gbps Cable Modem for Xfinity Voice</h3>
            <p className='thro'>Experience cable Internet speeds 2.5X faster for smooth browsing and streaming.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Nighthawk® Multi-Gig Cable Modem</h3>
            <p className='thro'>Own this Multi-Gig DOCSIS® 3.1 modem for true Multi-Gig speeds.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>




  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;