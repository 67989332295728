import React from 'react';
import './Laia.css'
import LaiaKey from '../OwlCarousel/LaiaKey';
import LaiaBusiness from '../OwlCarousel/LaiaBusiness';
import LaiaTeam from '../OwlCarousel/LaiaTeam';
import LaiaBroad from '../OwlCarousel/LaiaBroad';
import LaiaPod from '../OwlCarousel/LaiaPod';
// import BrightSignLS from '../OwlCarousel/BrightSignLS';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/LaiaFAQ';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Laia</title>
                <meta name="Intuitive HD Video Conferencing with AI Tracking Technology" content="Laia Offers High-Definition Video Conferencing Solutions with AI Tracking Technology, Optical Zoom, and Multiple Connectivity Options At Best Prices Online" />
                <meta name="keywords" content="high-definition video, AI tracking Technology, optical zoom capabilities, innovative AV/IT Solution, Multiple Connectivity " />
            </Helmet>
        </div>

        <div class="laia-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Smart Solutions for Excellent <br/> Presentations
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://laiatech.com/en/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>The Future of AI Tracking Technology</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    At Laia, we are committed to revolutionizing high-definition video capture experiences through innovative camera technologies tailored for diverse environments and applications. The brand represents the perfect fusion of advanced artificial intelligence and sophisticated optics, delivering seamless video capture, intelligent tracking, and high-definition performance resulting in innovative AV solutions. 
            <br/><br/> Laia empowers users with high end solutions that enhance communication, collaboration, and creativity no matter if it’s a corporate meeting, an event or any professional context. We strive to make every production not just a presentation but a captivating experience, ensuring that our customers are always connected and engaged.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Why Choose Laia's AV/IT Solutions?</h2>
            <LaiaKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5"> Elevate your Viewing Experiences 
</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Business and Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">My Team</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Broad">Broadcaster</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">T Pod</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <LaiaBusiness/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <LaiaTeam/>
                </Tab.Pane>

                <Tab.Pane eventKey="Broad">
                    <LaiaBroad/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <LaiaPod/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://laiatech.com/en/' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>High-Quality Video Experience
</h4>
            <div className='line-up'>
                <h4>Broadcaster 4K AI
                :</h4>
                <p>This high-performance 4K camera utilizes intelligent tracking technology to ensure that presenters are always in focus, ideal for medium to large conferencing environments.
</p>
            </div>
            <div className='line-up'>
                <h4>t-Joy IP Broadcaster 
                : </h4>
                <p>A versatile camera designed for live streaming and broadcasting, offering high-definition video quality and support for various streaming protocols.
</p>
            </div>
            <div className='line-up'>
                <h4>Broadcaster 12X/20X AI 
                :
</h4>
                <p>This camera offers powerful optical zoom capabilities combined with intelligent tracking features, making it perfect for large venues and dynamic presentations.
</p>
            </div>
            <div className='line-up'>
                <h4>Broadcaster 30X
                :</h4>
                <p>With advanced zoom functionality and high-definition video output, this camera is tailored for applications needing impressive detail from a distance.
</p>
            </div>

            <div className='line-up'>
                <h4>Laia Wall Mount
                :</h4>
                <p>A designed solution for securely mounting Laia cameras on walls, allowing users to optimize their conference room setup.
</p>
            </div>

            <div className='line-up'>
                <h4>Laia Ceiling Support
                :</h4>
                <p>This durable ceiling bracket provides a professional mounting option for enhancing the positioning of Laia cameras.
</p>
            </div>
          </div>
    </div>

   

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Discover the Future of Best-In-Class Connectivity
</h5><br/>
            <p>The future vision seems to be about creating a compelling and cohesive presentation of innovative AV/IT solutions that resonate with users, backed by strong messaging and a commitment to quality and innovation.
</p>
            <a target='_blank' href='https://laiatech.com/en/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Laia and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
