/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './VideoBanner.css';
import Banner_video from '../../Assets/BannerVideo.mp4';
import Banner_video_mobile from '../../Assets/BannerVideo.mp4';
import ReactPlayer from 'react-player';

const VideoBanner = () => {
   const texts = [
    'Top Brands. Top Quality. Capture every moment',
    'Your One-Stop Shop for Pro-Grade AV Gear',
    'Impressive Audios for Every Stunning Visuals',
    'Pro-Grade Displays Assured with Premium Quality'
  ];

  const classNames = [
    'class-one',  // Class for the first text
    'class-two',  // Class for the second text
    'class-three', // Class for the third text
    'class-four' // Class for the third text
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const typingInterval = setInterval(() => {
      setIsTyping(false);
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setIsTyping(true);
      }, 500);
    }, 6000);

    return () => clearInterval(typingInterval);
  }, [texts.length]);

  const formatText = (text) => {
    const words = text.split(' ');
    const firstThreeWords = words.slice(0, 3).join(' ');
    const restOfText = words.slice(3).join(' ');
    return (
      <>
        {firstThreeWords} <br /> {restOfText}
      </>
    );
  };

  const [videoUrl, setVideoUrl] = useState(Banner_video); // Default video for larger screens

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 480) {
        setVideoUrl(Banner_video_mobile); // Video for screens less than 480px
      } else {
        setVideoUrl(Banner_video); // Video for larger screens
      }
    };

    // Call the function on mount and resize
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='banner'>
            <ReactPlayer
              url={videoUrl}
              playing
              muted
              loop
              controls={false}
              width="100%"
              height="100%"
              className="banner-video"
            />
            <div className="banner-content">
      {/* <p>Leading digital signage software for engaging experiences.</p> */}
      <h1
        className={`typing-text ${isTyping ? 'typing' : ''} ${classNames[currentTextIndex]}`}
      >
        {formatText(texts[currentTextIndex])}
      </h1>
      <button className="banner-button">Explore More</button>
    </div>
    </div>
  );
};

export default VideoBanner;
