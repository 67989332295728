import React from 'react';
import aoto1 from '../../Assets/sennheiser/Exceptional Sound Quality 1.jpg';
import aoto2 from '../../Assets/sennheiser/Innovative Wireless Solutions.jpg';
import aoto3 from '../../Assets/sennheiser/Legacy of Reliability.jpg';
import aoto4 from '../../Assets/sennheiser/User-Centric Design.jpg';
import aoto5 from '../../Assets/sennheiser/Versatile Product Range.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Exceptional Audio Quality</h3>
            <p className='thro'>Sennheiser is renowned for its superior sound reproduction, ensuring clarity and precision across all frequencies. The brand's commitment to audio excellence is evident in every product, offering listeners a truly immersive experience whether in a studio, at a concert, or in a corporate meeting environment.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Innovative Wireless Solutions</h3>
            <p className='thro'>With products like Spectera, Sennheiser leads the way in bidirectional wireless technology, providing unmatched freedom and flexibility for audio transmission. This innovative system ensures reliable, high-quality audio without the constraints of traditional wired setups, making it ideal for various applications.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Versatile Product Range</h3>
            <p className='thro'>Sennheiser's comprehensive selection of microphones, headphones, and conference systems caters to a wide array of industries, including music production, broadcasting, corporate environments, and live events. This versatility allows users to select the right tools for their specific needs, without compromising on quality.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>User-Centric Design</h3>
            <p className='thro'>Sennheiser places a strong emphasis on ease of use and comfort in its products. The ergonomic design of headphones and user-friendly interface of wireless systems ensure that users can enjoy high-quality audio without unnecessary complications</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Legacy of Reliability</h3>
            <p className='thro'>With decades of experience, Sennheiser has built a reputation for durability and reliability. Whether on stage or in the studio, users can depend on Sennheiser products to perform flawlessly under demanding conditions, ensuring that every sound is captured and delivered as intended.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;