import React from 'react';
import Audkey1 from '../../Assets/BrightSIgn/BrightSign-key1.jpg';
import Audkey2 from '../../Assets/BrightSIgn/BrightSign-key2.jpg';
import Audkey3 from '../../Assets/BrightSIgn/BrightSign-key3.jpg';
import Audkey4 from '../../Assets/BrightSIgn/BrightSign-key4.jpg';
import Audkey5 from '../../Assets/BrightSIgn/BrightSign-key5.jpg';
import Audkey6 from '../../Assets/BrightSIgn/BrightSign-key6.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 380,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>High-Performance Hardware</h3>
            <p className='thro'>BrightSign media players boast robust hardware built for continuous 24/7 operation. 
            Their high-end processors and ample memory ensure smooth playback even with demanding content. 
            It also supports various display resolutions and formats.

</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Intuitive Software
</h3>
            <p className='thro'>BrightSign's software is user-friendly, requiring no coding experience to create and manage engaging displays. 
            The BrightAuthor authoring tool simplifies content creation and scheduling. 
</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Versatile Content Playback
</h3>
            <p className='thro'>BrightSign players support a wide array of content formats, including video, images, HTML5, and web pages. 
            The opportunity to Integrate with third-party APIs and services expands content possibilities. 
</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Reliable and Secure
</h3>
            <p className='thro'>BrightSign players are designed for maximum uptime and reliability, minimizing downtime and 
            ensuring consistent performance. Their robust security features protect against unauthorized access and malicious software. 
           
</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Scalability and Flexibility
</h3>
            <p className='thro'>BrightSign offers a range of players to fit diverse needs, from small single-screen 
            installations to large-scale deployments. The modular design allows for easy expansion and adaptation as requirements change supporting various network protocols. 
</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Hand-in-Hand Support 
</h3>
            <p className='thro'>BrightSign offers a variety of support options, including online documentation, a knowledge base, and technical support from their team of experts.
</p>
        </div>
        <img
            src={Audkey6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;