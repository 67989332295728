import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of network systems does Siemon provide?",
      answer: "Siemon offers high-performance copper and fiber infrastructure solutions for data centers, smart buildings, and cellular networks.",
    },
    {
      question: "How can I find a distributor for Siemon products?",
      answer: "You can locate a distributor through our website’s “Find a Distributor” section, where you can search by location.",
    },
    {
      question: "What is Global Project Services (GPS)?",
      answer: "GPS is Siemon’s team that manages global projects, providing professional services, infrastructure support, and quality assurance for multi-site implementations.",
    },
    {
      question: "How does Siemon ensure high-quality audio and video solutions?",
      answer: "Our solutions are designed with rigorous testing standards and innovative technology, ensuring optimal performance for audio and video applications.",
    },
    {
      question: "Does Siemon offer custom solutions?",
      answer: "Yes, through our OEM Technologies division, we collaborate with partners to create market-centric, custom connectivity solutions.",
    },
    {
      question: "What are the benefits of fiber optic solutions?",
      answer: "Fiber optic systems offer high bandwidth, long-distance transmission capabilities, and excellent resistance to electromagnetic interference.",
    },
    {
      question: "What sets Siemon apart from competitors?",
      answer: "Siemon’s commitment to innovation, quality, customer service, and sustainability distinguishes us as a leader in the connectivity industry.",
    },
    {
      question: "Are Siemon products environmentally friendly?",
      answer: "Yes, we prioritize sustainability by designing energy-efficient products and practices that reduce environmental impact.",
    },
    {
      question: "How can I contact Siemon for support?",
      answer: "You can reach our customer service team through our website or directly via our customer service email.",
    },
    {
      question: "What is Siemon’s approach to technology advancements?",
      answer: "Siemon continuously invests in research and development to introduce solutions that align with evolving market trends and technological advancements.",
    },
    
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
