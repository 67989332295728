import React from 'react'

const EnquiryForm = () => {
  return (
    <div>
          <iframe title="Contact Us" aria-label='Contact Us' frameborder="0" style={{height: "690px", width: "100%", border: "none"}} 
          src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/EnquiryForm/formperma/aD0yrA_oeQP2bv5ix_N8qyuAtvQdWcRFK7n_QzcSOU4'></iframe>
    </div>
  )
}

export default EnquiryForm

