import React from 'react';
import aoto1 from '../../Assets/QSC/qsc-key1.jpg';
import aoto2 from '../../Assets/QSC/qsc-key2.jpg';
import aoto3 from '../../Assets/QSC/qsc-key3.jpg';
import aoto4 from '../../Assets/QSC/qsc-key4.jpg';
import aoto5 from '../../Assets/QSC/qsc-key5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
<div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item ">
            <h3>Exceptional Sound Quality </h3>
            <p className='thro'>QSC's products are engineered with precision to deliver outstanding audio fidelity, enabling clear and impactful sound reproduction. Their commitment to superior sound quality and design sets them apart in the competitive audio market.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
<div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item ">
            <h3>Innovative Technology</h3>
            <p className='thro'>Equipped with advanced technology, QSC products integrate advanced features such as networked capabilities and digital mixing, allowing for dynamic control and seamless operation. This innovation ensures that users have the latest technology tools at their disposal to enhance their audio experiences.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
<div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item ">
            <h3>Robust Build Quality</h3>
            <p className='thro'>Designed for durability and reliability, QSC products are constructed to withstand the rigors of both touring and permanent installation environments. The robust design ensures longevity, making QSC a trustworthy choice for sound professionals.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
<div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item ">
            <h3>User-Friendly Operation</h3>
            <p className='thro'>With intuitive controls and customizable features, QSC products are designed for ease of use, allowing sound engineers to focus on their craft rather than navigating complex systems. This user-centric approach enhances workflow efficiency during setup and live performances.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
<div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item ">
            <h3>Comprehensive Support and Training</h3>
            <p className='thro'>QSC offers extensive customer support and training programs, ensuring users are equipped with the knowledge and skills to maximize the potential of their audio systems. This commitment to education fosters a community of empowered professionals capable of delivering exceptional sound.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;