import React from 'react';
import './Nureva.css';
import trusted_1 from '../../Assets/trusted_3.png';
import trusted_2 from '../../Assets/Nureva/aver.png';
import trusted_3 from '../../Assets/Nureva/baarco.png';
import trusted_4 from '../../Assets/Nureva/logitech.png';
import trusted_5 from '../../Assets/Nureva/sony.png';
import trusted_6 from '../../Assets/Nureva/zoom.png';
import microphone from '../../Assets/mic_mist.mp4';
import ReactPlayer from 'react-player';
import trusted_7 from '../../Assets/maxhub.png';
import col1 from '../../Assets/col1.png';
import col2 from '../../Assets/col2.png';
import col3 from '../../Assets/col3.png';
import nureva1_1234 from '../../Assets/nureva1_1234.png';
import nureva2_1234 from '../../Assets/nureva2_1234.png';
import nureva3_1234 from '../../Assets/nureva3_1234.png';
import Nureva1 from '../../Assets/nureva1.png';
// import FAQSection from '../FAQ/Faq';
import NurevaCarousel1 from '../OwlCarousel/NurevaCarousel1';
import NurevaCarousel2 from '../OwlCarousel/NurevaCarousel2';
import { Helmet } from 'react-helmet-async';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <Helmet>
                <title>Nvincy | Nureva</title>
                <meta name="Audio Conference Systems with Microphone Mist Technology" content="Nureva Offers Crystal-Clear Audio Conferencing Systems, Equipment & Solutions With Microphone Mist Technology. Buy Now For Unparalelled Clarity and Ease of Use." />
                <meta name="keywords" content="Audio Conferencing Systems, Easy Microphone Pickup, Audio Conferencing Solutions, Microphone Mist Technology, Audio Conferencing Equipment" />
            </Helmet>
        <div class="nureva-sub_banner">
          <div className="product-banner-content move-down">
            <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>Advanced Audio Conferencing</span>
                <span class="ms-2 text-success">--</span>
            </div>
            <h1 className='product-h1'>
            Crystal Clear Voices <br/> Everywhere Inside.
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.nureva.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg_bg'>
            <div className='row '>
                <h1 className='htag'>Nureva's Microphone Mist Technology</h1>
                <div className='col-12 col-md-6'>
                    <p className='font-weight1 about1'>
                    Have you ever wondered how a room with thousands of virtual microphones works together seamlessly? <br/><br/>

Yes. That's the power of Microphone Mist<sup>TM</sup>. Unlike traditional microphone arrays with limited pickup zones, Microphone Mist<sup>TM</sup> creates a dense virtual microphone field that captures every voice, 
everywhere in the room, expelling chances for any audio glitches and creating a best-in-class Audio conferencing system.
                    </p>
                </div>
                <div className='col-12 col-md-6'>
                    <img src={Nureva1} alt="about1" className='img-fluid'></img>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px", paddingBottom: "150px"}}>
          <p>Products and Services</p>
            <h2>Audio Conferencing Equipment</h2>
            <NurevaCarousel1/>
        </div>

        <div className="microphone-mist-section">
      <div className="cust-container">
        <div className="row align-items-center">
          {/* Text Section */}
          <div className="col-md-6 text-white">
            <h2 className="mb-4">Microphone Mist™ technology</h2>
            <p className="mb-4">
              Fill your spaces with thousands of virtual microphones, so every voice is picked up, everywhere. Patented Microphone Mist technology is the 
              easiest and most cost-effective way to get full-room audio coverage in larger spaces — and it’s only available from Nureva.
            </p>
          </div>

          {/* Image/Graphic Section */}
          <div className="col-md-6 text-center">
            <div className="circle-video">
            <ReactPlayer
              url={microphone}
              playing
              muted
              loop
              controls={false}
              width="100%"
              height="100%"
            />
            </div>
          </div>
        </div>
      </div>
    </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
            <p>Hear it. Believe it.</p>
            <h3 className='htag'>Accommodating All Space Needs</h3>
            <NurevaCarousel2/>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
        <div class="row cust-row">
    
            <div class="col-lg-4 col-md-4 col-sm-12 mb-4" id="three-col">
            <div class="card1 h-100 text-white">
                <div className="card-body">
                <div className="d-flex">
                    <img src={col1} alt="col1" style={{width: "80px"}}></img>
                    <h5 class="card-title mx-2">Research-bound Technology</h5>
                </div>
                    <p class="card-text mt-5 mb-5">Microphone Mist™ technology is a revolutionary approach that replaces traditional microphones with a virtual cloud of 4,096 intelligent mics.</p>
                </div>
            </div>
            </div>


            <div class="col-lg-4 col-md-4 col-sm-12 mb-4">
            <div class="card2 h-100 text-white">
            <div className="card-body">
                <div className="d-flex">
                    <img src={col2} alt="col1" style={{width: "109px"}}></img>
                    <h5 class="card-title mx-2">Carefree Background Noise Rejection</h5>
                </div>
                    <p class="card-text mt-5 mb-5">Position-based gain control analyzes each sound source individually, while unwanted noises such as keyboard clicks and distant chatter fade away.</p>
                </div>
            </div>
            </div>


            <div class="col-lg-4 col-md-4 col-sm-12 mb-4">
            <div class="card1 h-100 text-white">
            <div className="card-body">
                <div className="d-flex">
                    <img src={col3} alt="col1" style={{width: "80px"}}></img>
                    <h5 class="card-title mx-2">Effortless Adaptation Every Time</h5>
                </div>
                    <p class="card-text mt-5 mb-5">Microphone MistTM features continuous autocalibration so that you can focus on collaboration, not technical difficulties like microphone positions or room acoustics.</p>
                </div>
            </div>
            </div>
        </div>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <img src={nureva1_1234} alt="about1" style={{width: "600px"}}></img>
                </div>
                <div className='col-12 col-md-6'>
                <h2>Unleash Camera Freedom with the HDL200</h2>
                    <p className='font-weight1 about1'>
                    Do you still prefer a mounted camera? No problem! The HDL200 offers a convenient magnetic 
                    mounting bracket for a seamless setup. Unlike traditional all-in-one microphone bars, you can place HDL200 where you need it for optimal coverage.
                    </p>
                </div>
            </div>
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
            <div className='row'>
                <div className='col-12 col-md-6'>
                <h2>A Thoughtful Design for Intimate Spaces</h2>
                    <p className='font-weight1 about1'>
                    Is your room size small? No problem. We designed the HDL200 with smaller meeting rooms in mind. 
                    Its sleek design boasts unique acoustic fabric coverings and rounded edges that complement any décor, ensuring it looks as good as it sounds.
                    </p>
                </div>
                <div className='col-12 col-md-6 thought'>
                    <img src={nureva2_1234} alt="about1" style={{marginLeft: "80px", width: "600px"}}></img>
            </div>
            </div>
            
        </div>

        <div className='cust-container' style={{paddingTop: "60px"}}>
            <div className='row'>
                <div className='col-12 col-md-6'>
                    <img src={nureva3_1234} alt="about1" style={{width: "600px"}}></img>
                </div>
                <div className='col-12 col-md-6'>
                <h2>Stay Informed with a Configurable Display</h2>
                    <p className='font-weight1 about1'>
                    Wanna know updates from time to time? Here it is. The built-in LCD keeps you informed with useful details. 
                    Upon first use, it displays the autocalibration progress. You'll see call status, time of day, and more features during operation.
                    </p>
                </div>
            </div>
        </div>

        <div className="container-fluid hero-section d-flex justify-content-center align-items-center">
          <div className="hero-content text-center">
            <h2 style={{fontSize: "60px"}} className="demo">Book a free demo to know more</h2>
            <p style={{marginTop: "40px"}}>We are the sole authorized distributor of Nureva audio <br/> conferencing solutions in India. Schedule a demo with us to experience 
            the full-room sound.</p>
            <button className="button_3 font-weight1" style={{marginTop: "40px"}}>Book Now </button>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Our Solutions,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Nureva collaborates extensively with leading technology providers to offer seamless integration of its audio conferencing solutions across popular UC&C platforms, 
            camera systems, and room peripherals, delivering comprehensive and flexible hybrid collaboration experiences tailored to organizational needs.</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted">
                <a target='_blank' rel="noreferrer" href='https://www.nureva.com/ecosystem/microsoft-teams'  >
                  <img src={trusted_1} alt="trusted_1" className='zooming-image'></img></a>
                </div>

                <div className="col-md-2 trusted">
                  <a target='_blank' rel="noreferrer" href='https://www.nureva.com/ecosystem/aver' ><img src={trusted_2} alt="trusted_2" className='zooming-image'></img></a>
                </div>
                <div className="col-md-2 trusted ">
                <a target='_blank' rel="noreferrer" href='https://www.nureva.com/ecosystem/barco' ><img src={trusted_3} alt="trusted_3" className='zooming-image'></img></a>
                </div> 
                <div className="col-md-2 trusted ">
                <a target='_blank' rel="noreferrer" href='https://www.nureva.com/ecosystem/logitech' ><img src={trusted_4} alt="trusted_4" className='zooming-image'></img></a>
                </div>
                <div className="col-md-2 trusted">
                <a target='_blank' rel="noreferrer" href='https://www.nureva.com/ecosystem/sony' ><img src={trusted_5} alt="trusted_5" className='zooming-image'></img></a>
                </div>
                <div className="col-md-2 trusted">
                <a target='_blank' rel="noreferrer" href='https://www.nureva.com/ecosystem/zoom' ><img src={trusted_6} alt="trusted_5" className='zooming-image'></img></a>
                </div>
                <div className="col-md-2 trusted">
                <a target='_blank' rel="noreferrer" href='https://www.nureva.com/ecosystem/maxhub' ><img src={trusted_7} alt="trusted_5" className='zooming-image'></img></a>
                </div>
            </div>
          </div>
        </div> 

        {/* <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div>  */}

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 
    </div>
  )
}

export default About
