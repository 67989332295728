import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What services does Nvincy offer?",
      answer: "Nvincy provides a wide range of solutions to help businesses communicate effectively. This includes audio-visual (AV/IT) products, software solutions, custom app development, and digital signage products.",
    },
    {
      question: "Do you offer installation services?",
      answer: "You can navigate between the slides by waiting for the automatic transition or by clicking on the video names in the progress bar below each video.",
    },
    {
      question: "What types of AV/IT products do you offer?",
      answer: "Nvincy offers a wide range of AV/IT products like high-definition displays, sound systems, projectors, microphones, and more. They partner with leading brands to provide the best solutions for your needs.",
    },
    {
      question: "How do I choose the right AV/IT equipment?",
      answer: "Nvincy's experienced team can help you choose the right AV/IT equipment based on your specific requirements, budget, and space.",
    },
    {
      question: "What types of software products do you offer?",
      answer: "Nvincy offers software solutions for managing your digital signage content, scheduling meetings and potentially other software related to your specific needs.",
    },
    {
      question: "Do I need technical expertise to use your software?",
      answer: "Nvincy's software is designed to be user-friendly and intuitive. Most software offers clear instructions and Nvincy can provide training or demos if needed.",
    },
    {
        question: "Can you develop apps for any platform?",
        answer: "Nvincy can develop custom apps for various platforms like smartphones, tablets, and even desktops.",
      },
      {
        question: "What kind of apps can you develop?",
        answer: "Nvincy can develop a wide range of apps to meet your specific needs, from business productivity tools to interactive experiences for customers.",
      },
      {
        question: "Do you offer any services specifically for retail businesses?",
        answer: "Nvincy can provide solutions tailored to the needs of retail businesses, such as interactive displays for product information, digital signage for promotions, and audio systems for in-store music.",
      },
      {
        question: "How can Nvincy help my retail business?",
        answer: "Nvincy can help your retail business create a more engaging and immersive shopping experience for customers, ultimately leading to increased sales.",
      },
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
