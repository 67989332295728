import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of applications can Nexmosphere products be used for?",
      answer: "Nexmosphere products are versatile and can be applied in retail, exhibitions, smart buildings, and any environment that benefits from enhanced interactivity and sensing",
    },
    {
      question: "Are Nexmosphere sensors easy to integrate into existing systems?",
      answer: "Yes, Nexmosphere products are designed with seamless integration in mind, ensuring compatibility with existing systems and a smooth installation process.",
    },
    {
      question: "What support options are available for Nexmosphere customers?",
      answer: "Nexmosphere offers a variety of support resources, including technical assistance, product manuals, an integrator portal, and a dedicated customer service team.",
    },
    {
      question: "Can Nexmosphere sensors work in outdoor environments?",
      answer: "Many Nexmosphere sensors are suitable for outdoor conditions; however, it's best to check product specifications to ensure they meet environmental requirements.",
    },
    {
      question: "What is the purpose of the RFID systems offered by Nexmosphere?",
      answer: "Nexmosphere’s RFID systems enhance inventory control and asset tracking by providing real-time data on product availability and movement.",
    },
    {
      question: "Are Nexmosphere products customizable?",
      answer: "Yes, many products can be customized to fit specific project needs, enabling tailored solutions for different applications.",
    },
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
