import React from 'react';
import './Prysm.css'
import PrysmKey from '../OwlCarousel/PrysmKey';
import PrysmPdt from '../OwlCarousel/PrysmPdt';
// import BrightSignLS from '../OwlCarousel/BrightSignLS';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/PrysmFAQ';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Prysm</title>
                <meta name="Unlock Collaboration Technology With Large Format Displays " content="Discover Innovative Collaboration Technology, Interactive Touch Screens, Interactive LED Panels and Digital Canvases For A More Engaging Workspace experience." />
                <meta name="keywords" content="Large Format Displays, Interactive LED Panel, Interactive Touch Screen, Digital Canvas, Collaboration Technology" />
            </Helmet>
        </div>

        <div class="prysm-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Prysm Displays for Every <br/> Ultra-Modern Workplace
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.prysmsystems.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Revolutionize Your Workspace with Our Displays
</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Prysm is an innovative technology company that empowers businesses to create immersive and engaging visual experiences through its advanced large format displays and collaboration software. From boardrooms to huddle rooms, Prysm transforms any space into a dynamic, interactive, and productive environment. 
<br/><br/>With their flagship LPD 6K Series displays and intuitive Prysm Application Suite, they are redefining the way teams collaborate and communicate, making meetings more impactful and engagements more memorable.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Interactive Touch Screens for Engaging Experiences</h2>
            <PrysmKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5"> Large Format Displays: The Secret For Better Collaboration
</h3>
<PrysmPdt/>
            {/* <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Business and Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">My Team</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Broad">Broadcaster</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">T Pod</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <LaiaBusiness/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <LaiaTeam/>
                </Tab.Pane>

                <Tab.Pane eventKey="Broad">
                    <LaiaBroad/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <LaiaPod/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container> */}
        <div className="middle-button">
            <a target='_blank' href='https://www.prysmsystems.com/' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Discover the Power of Interactive LED Displays
</h4>
            <div className='line-up'>
                <h4>LPD 6K Series Displays
                :</h4>
                <p>Prysm's flagship displays offer a 6K bezel-free experience, designed for collaboration and immersive presentation. Ideal for executive boardrooms and presentation spaces.
</p>
            </div>
            <div className='line-up'>
                <h4>Prysm Go Software
                : </h4>
                <p>A cloud-based collaboration technology tool for seamless file sharing, video conferencing, and content integration. Suitable for everyday meetings, training, and remote collaboration.
</p>
            </div>
            <div className='line-up'>
                <h4>Prysm Application Suite
                :
</h4>
                <p>An enterprise-grade suite that combines content, tools, and applications onto a single digital canvas. Ideal for corporate innovation, product development, and team collaboration.
</p>
            </div>
            <div className='line-up'>
                <h4>Executive Boardrooms Solutions
                :</h4>
                <p>A comprehensive solution that combines Prysm's advanced displays and collaboration software for dynamic and immersive executive meetings.
</p>
            </div>

            <div className='line-up'>
                <h4>Customer Experience Centers
                :</h4>
                <p>Customized spaces designed for dynamic presentation and collaboration. Perfect for showcasing innovative ideas and creating immersive customer experiences.
</p>
            </div>

            <div className='line-up'>
                <h4>Collaboration Software Integrations
                :</h4>
                <p>Prysm seamlessly integrates with a range of collaboration technology tools and platforms, supporting flexibility and customization in meeting environments.
</p>
            </div>

            <div className='line-up'>
                <h4>Training and Onboarding Solutions
                :</h4>
                <p>Prysm offers comprehensive solutions for streamlined training and onboarding processes. These solutions integrate interactive LED panel displays, software tools, and content share capabilities.
</p>
            </div>
            <div className='line-up'>
                <h4>Digital Signage Solutions
                :</h4>
                <p>Prysm displays are perfectly suited for deploying dynamic and impactful digital signage in corporate environments, resulting in lasting impressions and enhanced communication.
</p>
            </div>
          </div>
    </div>

   

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Expertly Designed Digital Canvases for Enhanced Engagement
</h5><br/>
            <p>At Prysm, our vision is to keep driving the evolution of modern workspaces, cultivating immersive and effective collaboration experiences that spark innovation, creativity, and growth. We envision a future where every workspace and communication touchpoint is as engaging and memorable as it is productive. 


<br/><br/>By continuously pushing the boundaries of technology, collaboration, and design, we will remain at the forefront of delivering best-in-class solutions for an ever-changing business landscape. This forward-thinking approach will empower teams, foster seamless communication, and profoundly enhance how businesses engage with their customers, employees, and partners across the globe.

</p>
            <a target='_blank' href='https://www.prysmsystems.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Prysm and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
