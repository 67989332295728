import React from 'react';
import './Alsee.css'
import AlseeKey from '../OwlCarousel/AlseeKey';
import AllseeTouch from '../OwlCarousel/AllseeTouch';
import AllseeSmall from '../OwlCarousel/AllseeSmall';
import AllseeWide from '../OwlCarousel/AllseeWide';
import AllseeDigi from '../OwlCarousel/AllseeDigital';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/AllseeFAQ';
// import { Link } from 'react-router-dom';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Allsee</title>
                <meta name="Alsee: Digital Signage Media Players With Touch Screen" content="Allsee provides innovative digital signage, touch screens, and 
                media players. Enhance your business with Allsee's high-quality digital displays. Check Out Now." />
                <meta name="keywords" content="digital signage, Touch screens, Media Players, Digital Displays, Digital Signage displays" />
            </Helmet>
        </div>

        <div class="alsee-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            AllSee Tech – Where Innovation <br/> Meets Vision!
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.allsee-tech.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Alsee's Advanced Digital Signage Solutions</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    In a world where visibility is key, Allsee Technologies emerged in 2007 with a mission: to transform how businesses communicate and engage through stunning digital displays.
With over 16 years of innovation and expertise, we specialize in creating high-quality, sunlight-readable LCD and LED screens that deliver dynamic messaging across various industries—from retail and hospitality to education and healthcare.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Why Choose our Digital Signage Displays?</h2>
            <AlseeKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5"> Discover our Digital Signage Display Range
</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Touch Screens</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">Small Sized Display</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Wide Format Display</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Control">Digital Posters</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AllseeTouch/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AllseeSmall/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AllseeWide/>
                </Tab.Pane>

                <Tab.Pane eventKey="Control">
                    <AllseeDigi/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.allsee-tech.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Explore our Touch Screen Technology</h4>
            <div className='line-up'>
                <h4>Indoor Displays:</h4>
                <p>Perfect for indoor environments, showcasing content with high-quality visuals.</p>
            </div>
            <div className='line-up'>
                <h4>Outdoor Displays: </h4>
                <p>Designed to withstand harsh weather conditions, ideal for outdoor advertising and information displays.
</p>
            </div>
            <div className='line-up'>
                <h4>Ultra-Narrow Bezel Displays:
</h4>
                <p>Create seamless video walls with minimal bezels for an immersive experience.
</p>
            </div>
            <div className='line-up'>
                <h4>Fine Pitch LED Displays:</h4>
                <p>These displays offer high resolution for close viewing distances, making them ideal for control rooms, broadcasting studios, and high-end commercial applications.</p>
            </div>
            <div className='line-up'>
                <h4>Touch Screen Displays:</h4>
                <p>Enable interactive experiences, perfect for kiosks and point-of-sale applications.</p>
            </div>
            <div className='line-up'>
                <h4>Large Format Displays:</h4>
                <p>Captivate audiences with large-scale displays for impactful messaging.
</p>
            </div>
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.

</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 


        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Get Started with Digital Signage Today</h5><br/>
            <p>At Allsee, we believe that digital signage should be accessible to all, regardless of budget. This vision earned us the prestigious Queen’s Award for Enterprise in the category of Innovation, a testament to our groundbreaking solutions that empower every business to shine bright. Our integrated android displays eliminate the need for bulky external devices, making setup a breeze; simply load your content onto a USB, and watch it come to life.
</p>
            <a target='_blank' href='https://www.allsee-tech.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Allsee and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
