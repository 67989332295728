import React from 'react';
import Audkey1 from '../../Assets/Earthworks/ea-1.jpg';
import Audkey2 from '../../Assets/Earthworks/ea-2.jpg';
import Audkey3 from '../../Assets/Earthworks/ea-3.jpg';
import Audkey4 from '../../Assets/Earthworks/ea-4.jpg';
import Audkey5 from '../../Assets/Earthworks/ea-5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import './AlseeKey.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
    margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item audac-item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Massive Headroom 
</h3>
            <p className='thro'>Earthworks microphones are designed with an impressive maximum sound pressure level (SPL) rating, 
            allowing them to handle the loudest drum hits without distortion. This feature ensures that the microphones can capture the 
            dynamic range of any performance accurately.

</p>
        </div>
        <img
            src={Audkey1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Lightning-Fast Response
</h3>
            <p className='thro'>Engineered for rapid transient response, Earthworks microphones rise and settle within 
            microseconds, allowing for detailed capture of every nuance. 
            This exceptional speed means artists can rely on Earthworks for an authentic representation of their performance.
</p>
        </div>
        <img
            src={Audkey2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Durable Construction
</h3>
            <p className='thro'>Forged from sturdy stainless steel, these microphones are built to 
            withstand the rigors of touring and frequent use. Their robust design ensures longevity, 
            making them a reliable choice for musicians working both in studios and live venues.
</p>
        </div>
        <img
            src={Audkey3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Versatility Across Applications
</h3>
            <p className='thro'>Whether for studio recordings, live performance, or streaming, Earthworks microphones are tailored to 
            meet various sound capture needs. This versatility makes them an ideal choice for artists and engineers, who can trust Earthworks 
            to provide outstanding results.
</p>
        </div>
        <img
            src={Audkey4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item ">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>High-Resolution Sound Capture
</h3>
            <p className='thro'>The design of Earthworks drum microphones focuses on delivering a hi-res representation of your drum set, 
            ensuring that every tone and hit is captured with clarity and precision. This results in the best sound experience that elevates any production.
</p>
        </div>
        <img
            src={Audkey5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>



  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;