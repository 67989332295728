import React from 'react';
import feature1 from '../../Assets/nurevaFeature1.png';
import feature2 from '../../Assets/nurevaFeature2.png';
import feature3 from '../../Assets/nurevaFeature3.png';
import feature4 from '../../Assets/feature4.png';
import feature7 from '../../Assets/feature7.png';
import feature8 from '../../Assets/feature8.png';
import feature9 from '../../Assets/feature9.png';
import feature10 from '../../Assets/feature10.png';
import feature11 from '../../Assets/feature11.png';
import feature12 from '../../Assets/feature12.jpg';
import './OwlCarousel.css';
import './NurevaCarousel1.css';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
  autoplayTimeout: 3500, // Autoplay every 2 seconds (2000 milliseconds)
  autoplayHoverPause: true, // Pause on hover
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500, // Faster transition speed (500 milliseconds)
  loop: false, // Enable looping
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 2,
    },
    600: {
      items: 2,
    },
    700: {
      items: 3,
    },
    1000: {
      items: 4,
    },
  },
};


class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white" }}>
      <img
        src={feature3}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text">
        <h3><b>HDL410</b></h3>
        <h3 className="font-weight4">Pro AV Performance in Extra-Large Spaces</h3>
      </div>
    </div>
  </div>
  
  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={feature1}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text">
        <h3 style={{color: "black"}}><b>HDL200 system</b></h3>
        <h3 className="font-weight4" style={{color: "black"}}>Powerful Audio System for Medium-Sized Spaces.</h3>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={feature2}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text">
        <h3><b>HDL300 system</b></h3>
        <h3 className="font-weight4">Effortless Microphone Pickup for All Meeting Rooms</h3>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={feature4}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text">
        <h3><b>HDL410 system</b></h3>
        <h3 className="font-weight4">Pro AV Performance in Extra-Large Spaces.</h3>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={feature7}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text">
        <h3><b>HDL200 system</b></h3>
        <h3 className="font-weight4">Flexible audio system for
        medium-sized rooms.</h3>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={feature8}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text">
        <h3><b>HDL310 system</b></h3>
        <h3 className="font-weight4">Audio conferencing for
        large rooms up to 30ʼ x 30ʼ</h3>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={feature9}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text" style={{color: "black"}}>
        <h3><b>Nureva Pro</b></h3>
        <h3 className="font-weight4">Added Value for Your Nureva Audio System.</h3>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={feature10}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text" style={{color: "black"}}>
        <h3><b>Nureva Developer Toolkit
        </b></h3>
        <h3 className="font-weight4">Easy-to-use APIs that let you integrate your devices and automate tasks</h3>
      </div>
    </div>
  </div>
  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={feature11}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text" style={{color: "black"}}>
        <h3><b>Nureva App</b></h3>
        <h3 className="font-weight4">Control Your Audio Experience from Your Phone</h3>
      </div>
    </div>
  </div>

  <div className="item">
    <div className="card responsive nureva" style={{ width: "430px", borderColor: "white"}}>
      <img
         src={feature12}
        className="card-img-top zooming-image"
        alt="React Icon"
      />
      <div className="overlay-text" style={{color: "black"}}>
        <h3><b>Nureva Console </b></h3>
        <h3 className="font-weight4">Simple, Centralized Management for Your Audio System</h3>
      </div>
    </div>
  </div>
  
</OwlCarousel>

</div>
)
};
}

export default App;