import React from 'react';
import './Xilica.css';
import Aoto1 from '../OwlCarousel/Xilica';
import AotoCommercial from '../OwlCarousel/Xilica-products';
import FAQ from '../FAQ/XilicaFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Xilica</title>
                <meta name="High Performance digital signal processors Available Online" content="Shop The High performance Digital Signage Processors, High-Quality Speakers And Advanced Audio Processing Systems With Excellent AV Technology. Shop Now." />
                <meta name="keywords" content="High Performance digital signal processors,High Quality speakers,Digital signage processors,Advanced audio processing" />
            </Helmet>
        </div>

        <div class="xilica-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Revolutionizing Audio-Visual  <br/> Collaboration
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://www.xilica.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Advanced Audio Visual Systems
</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Xilica isn't just about providing audio-visual equipment; it's about crafting seamless, intuitive, and powerful solutions that transform the way people connect. We design and manufacture cutting-edge digital signal processors, network endpoints, and speakers, enabling effortless collaboration in any environment, from boardrooms to lecture halls.Our commitment to innovation and reliability ensures that Xilica systems are not only technologically advanced but also exceptionally easy to use and manage, empowering users to focus on their work, not their technology.</p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Digital Signage Processors
</h2>
            <Aoto1/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Media Players For High Traffic Public Areas
</h3>
            <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                
            </Tab.Content>
        </Tab.Container>
        <div className="middle-button">
            <a target='_blank' href='https://www.xilica.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Energy Efficient Technology Support</h4>
            <div className='line-up'>
                <h4>Room Kits:</h4>
                <p>Pre-configured kits that combine processors, high-quality speakers, and other components for easy installation and setup in various room sizes and configurations. Designed for seamless integration and optimized performance.</p>
            </div>
            <div className='line-up'>
                <h4>Processors (Solaro DSPs):</h4>
                <p>High-performance digital signal processors that provide advanced audio control and processing capabilities. Offer sophisticated features and intuitive interfaces for streamlined system management.</p>
            </div>
            <div className='line-up'>
                <h4>User Interfaces (Touch Panels, Software):</h4>
                <p>Intuitive interfaces for controlling and monitoring audio-visual systems. Provide simple and effective management of complex systems.</p>
            </div>
            <div className='line-up'>
                <h4>Network Endpoints:</h4>
                <p>Networked devices that allow for remote control and monitoring of audio-visual systems. Enable centralized management and seamless integration with existing IT infrastructure.</p>
            </div>
            <div className='line-up'>
                <h4>Speakers (Sonia):</h4>
                <p>High-quality speakers designed for superior audio reproduction in various applications. Offer exceptional clarity and performance, optimized for different environments.</p>
            </div>
            
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

      

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Enhance Your Audio Experience
</h5><br/>
            <p>Xilica's future vision is to continue leading the innovation in professional audio-visual collaboration solutions. We will focus on 
            developing more intelligent and intuitive systems leveraging AI and machine learning for advanced audio processing and room optimization. <br/><br/>We will continue expanding our product portfolio with cutting-edge technologies, such as improved network capabilities, seamless integration with other Audio visual systems, and enhanced user experiences through intuitive interfaces and cloud-based management. Our goal is to provide solutions that not only enhance communication and collaboration but also simplify the complexities of AV technology.</p>
            <a target='_blank' href='https://www.xilica.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container'>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Allsee and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
