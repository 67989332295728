import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './ProductBrand.css';
import Brand1 from '../../Assets/brand1.png'; // Replace with your image path
import Brand2 from '../../Assets/brand2.png'; 
import Brand3 from '../../Assets/brand3.png'; 
import Brand4 from '../../Assets/brand4.png'; 
import Brand5 from '../../Assets/brand5.png'; 


const CarouselComponent = () => {
  return (
    <Carousel
      showArrows={true}
      showThumbs={false}
      showStatus={false}
      infiniteLoop={false}
      autoPlay={true}
      interval={3500}
    >
      {/* Slide 1 */}
      <div className="carousel-slide slide">
        <div className="carousel-content content">
          {/* Left Section: Image */}
          <div className="image-section section">
            <img src={Brand1} alt="Product" className="carousel-image" />
            <div className="image-overlay">
              <p>The Future of Visual Excellence</p>
              <h3>BRIGHTSIGN</h3>
              <h3 className='one'>XC5 The ultimate PC alternative</h3>
              <a href='/Products/BrightSign'><button className='learn'>Learn More</button></a>
           </div>
          </div>

          {/* Right Section: Features */}
          <div className="features-section">
            <h2>Features of the Product</h2>
            <ul>
              <li>Unmatched Performance</li>
              <li>Intuitive Content Management</li>
              <li>Unbeatable Reliability</li>
              <li>Scalability for Every Need</li>
              <li>Hand-in-Hand Support</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="carousel-slide slide">
        <div className="carousel-content content">
          {/* Left Section: Image */}
          <div className="image-section section">
            <img src={Brand2} alt="Product" className="carousel-image" />
            <div className="image-overlay">
              <p>Digital Signage Sensors</p>
              <h3>NEXMOSPHERE</h3>
              <h3 className='one'>Personalize your Story Telling now</h3>
              <a href='/Products/Nexmosphere'><button className='learn learn1'>Learn More</button></a>
           </div>
          </div>

          {/* Right Section: Features */}
          <div className="features-section">
            <h2>Features of the Product</h2>
            <ul>
            <li>Wide Sensor Range</li>
            <li>Commercial Applications</li>
            <li>Robust Construction  </li>
            <li>Detection Optimization</li>
            <li>Effortless Control</li>
            <li>Easy Installation</li>
            </ul>
            
          </div>
        </div>
      </div>

      <div className="carousel-slide slide">
        <div className="carousel-content content">
          {/* Left Section: Image */}
          <div className="image-section section">
            <img src={Brand3} alt="Product" className="carousel-image" />
            <div className="image-overlay">
              <p>Flawless 8k audio and Video</p>
              <h3>NVICCTA</h3>
              <h3 className='one'>Premium 100% copper connectors</h3>
              <a href='/Products/Nviccta'><button className='learn learn2'>Learn More</button></a>
           </div>
          </div>

          {/* Right Section: Features */}
          <div className="features-section">
            <h2>Features of the Product</h2>
            <ul>
              <li>Supports High-Definition Audio & Video</li>
              <li>Durable Construction</li>
              <li>Multi-Layer Shielding</li>
              <li>Multiple Length Options</li>
              <li>Future-Proof Performance</li>
              <li>Flexible Design</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="carousel-slide slide">
        <div className="carousel-content content">
          {/* Left Section: Image */}
          <div className="image-section section">
            <img src={Brand4} alt="Product" className="carousel-image" />
            <div className="image-overlay">
              <p>HDL Pro Series, Now for Zoom Rooms</p>
              <h3>NUREVA</h3>
              <h3 className='one'>Easy Meeting setup and learning space</h3>
              <a href='/Products/Nureva'><button className='learn learn3'>Learn More</button></a>
           </div>
          </div>

          {/* Right Section: Features */}
          <div className="features-section">
            <h2>Features of the Product</h2>
            <ul>
              <li>Effortless Pickup</li>
              <li>Superior Speech Clarity</li>
              <li>Freedom of Movement</li>
              <li>Easy Installation</li>
              <li>Continuous Autocalibration</li>
              <li>Sustainability-Focused Design</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="carousel-slide slide">
        <div className="carousel-content content">
          {/* Left Section: Image */}
          <div className="image-section section">
            <img src={Brand5} alt="Product" className="carousel-image" />
            <div className="image-overlay">
              <p>Network HD 510, For Large Meeting Rooms</p>
              <h3>WYRESTORM</h3>
              <h3 className='one'>Dante AV: The future of AV collaboration</h3>
              <a href='/Products/WyreStorm'><button className='learn learn4'>Learn More</button></a>
           </div>
          </div>

          {/* Right Section: Features */}
          <div className="features-section">
            <h2>Features of the Product</h2>
            <ul>
              <li>High-Resolution Support </li>
              <li>Dante AV Integration</li>
              <li>Future-Proof Technology </li>
              <li>User-Friendly Design</li>
              <li>Reliable Performance</li>
              <li>Multiple Connectivity Options</li>
            </ul>
          </div>
        </div>
      </div>

      {/* Additional slides can be added similarly */}
    </Carousel>
  );
};

export default CarouselComponent;
