import React from 'react';
import aoto1 from '../../Assets/Harman/Harmanpd1.png';
import aoto2 from '../../Assets/Harman/Harmanpd2.png';
import aoto3 from '../../Assets/Harman/Harmanpd3.png';
import aoto4 from '../../Assets/Harman/Harmanpd4.png';
import aoto5 from '../../Assets/Harman/Harmanpd5.png';
import aoto6 from '../../Assets/Harman/Harmanpd6.png';
import aoto7 from '../../Assets/Harman/Harmanpd7.png';
import aoto8 from '../../Assets/Harman/Harmanpd8.png';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>AKG by Harman</h3>
            <p className='thro'>AKG microphones and headphones combine design, technology, and acoustics for authenticity.
</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>AMX by Harman</h3>
            <p className='thro'>AMX pioneered control panels, driving automation and video distribution innovation.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>BSS by Harman</h3>
            <p className='thro'>BSS is renowned for audio signal processing for professional musicians' needs.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>dbx by Harman</h3>
            <p className='thro'>dbx, a leading dynamics processing company, provides equalization and speaker management systems.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>JBL Professional by Harman</h3>
            <p className='thro'>JBL stands alone in the history of music and motion picture recording.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Martin by Harman</h3>
            <p className='thro'>Martin provides innovative, high-quality solutions backed by 25 years of expertise.</p>
        </div>
        <img
            src={aoto6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Soundcraft by Harman</h3>
            <p className='thro'>Soundcraft pioneered "the British Sound" in the 70s, leading console technology.</p>
        </div>
        <img
            src={aoto7}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>SVSI</h3>
            <p className='thro'>AMX SVSI offers AV over IP encoders, decoders, audio transceivers, and recorders.</p>
        </div>
        <img
            src={aoto8}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>


  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;