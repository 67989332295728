import React, { useEffect, useState } from "react";
import './Statistics.css';
const CounterBox = ({ label1, label2, target }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const speed = 50; // Adjust speed for slower or faster counting
    const increment = target / speed;
    let currentCount = 0;

    const updateCount = () => {
      if (currentCount < target) {
        currentCount = Math.ceil(currentCount + increment);
        setCount(currentCount);
        setTimeout(updateCount, 20); // Adjust the speed of the count
      } else {
        setCount(target); // Ensure it stops exactly at the target
      }
    };

    updateCount();
  }, [target]);

  return (
    <div className="counter-box">
      {label1 && <div className="counter-label">{label1}</div>}
      <div className="counter-number">{count}</div>
      {label2 && <div className="counter-label">{label2}</div>}
    </div>
  );
};

const CounterRow = () => {
  return (
    <div>
      <div className="counter-row">
        {/* Counter 1 */}
        <CounterBox
          label1="Success Stories WORLDWIDE"
          target={500}
        />
        {/* Counter 2 */}
        <CounterBox
          label1="Projects DELIVERED"
          target={1000}
        />
      </div>

      {/* Second Row of Counters */}
      <div className="counter-row second-row">
        {/* Counter 3 */}
        <CounterBox
          label1="Trusted by"
          label2="+ global brands"
          target={200}
        />
        {/* Counter 4 */}
        <CounterBox
          label1="Active Years"
          target={10}
        />
      </div>
    </div>
  );
};

export default CounterRow;
