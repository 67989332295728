import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of products does QSC offer?",
      answer: "QSC offers a wide range of audio products, including powered loudspeakers, active subwoofers, digital mixers, and networked audio solutions with superior sound quality.",
    },
    {
      question: "Are QSC products suitable for both live performances and installations?",
      answer: "Yes, QSC products are designed to excel in both portable and installed applications, making them versatile for various environments and events.",
    },
    {
      question: "What sets QSC apart from other audio brands?",
      answer: "QSC stands out due to its combination of superior sound quality, innovative technology, robust build quality, and user-friendly operation, backed by comprehensive support.",
    },
    {
      question: "Can I connect multiple QSC products together?",
      answer: "Yes, many QSC products feature networked capabilities, allowing easy integration and connection between multiple devices for enhanced control and audio distribution.",
    },
    {
      question: "Is there warranty coverage on QSC products?",
      answer: "Yes, QSC provides warranty coverage on their products, ensuring quality and offering peace of mind for users.",
    },
    {
      question: "Where can I find training resources for QSC products?",
      answer: "QSC offers a variety of training resources, including videos and documentation, available on their website to help users maximize their product capabilities.",
    },
    {
      question: "Can I use QSC speakers for home audio systems?",
      answer: "While QSC products are primarily designed for professional use, users can integrate them into high-performance audio solutions, enjoying best sound quality in residential settings.",
    },
    {
      question: "Are the K.2 Series loudspeakers suitable for outdoor events?",
      answer: "Yes, the K.2 Series loudspeakers are built for durability and can perform exceptionally well in outdoor settings, provided they are secured and protected from the elements.",
    },
    {
      question: "What should I consider when choosing between QSC loudspeakers?",
      answer: "Factors to consider include the application (portable vs. installed), desired audio quality, coverage area, and whether additional features like network capabilities are needed for your specific needs.",
    },
    
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
