import React from 'react';
import aoto1 from '../../Assets/MultiBrackets/multipdt1.png';
import aoto2 from '../../Assets/MultiBrackets/multipdt2.png';
import aoto3 from '../../Assets/MultiBrackets/multipdt3.png';
import aoto4 from '../../Assets/MultiBrackets/multipdt4.png';
import aoto5 from '../../Assets/MultiBrackets/multipdt5.png';
import aoto6 from '../../Assets/MultiBrackets/multipdt6.png';
import aoto7 from '../../Assets/MultiBrackets/multipdt7.png';
import aoto8 from '../../Assets/MultiBrackets/multipdt8.png';
import aoto9 from '../../Assets/MultiBrackets/multipdt9.png';
import aoto10 from '../../Assets/MultiBrackets/multipdt10.png';
import aoto11 from '../../Assets/MultiBrackets/multipdt11.png';
import aoto12 from '../../Assets/MultiBrackets/multipdt12.png';
import aoto13 from '../../Assets/MultiBrackets/multipdt13.png';

//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>M Public Video Wall Mount Push HD</h3>
            <p className='thro'>Wall mount for LCD / plasma panel - iron - black - screen size: 40” - 70” Max 50Kgs
</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>M Public Video Wall Mount Push HD Max 90kg</h3>
            <p className='thro'>Wall mount for LCD / plasma panel - iron - black - screen size: 50” - 95” Max 90Kg</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>
            Multibrackets M Universal Wallmount Medium</h3>
            <p className='thro'>Wall mount for LCD / plasma panel - black - screen size: 32” - 46”
</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Multibrackets M Universal Wallmount Large</h3>
            <p className='thro'>Wall mount for LCD / plasma panel - black - screen size: 46” - 63”</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Multibrackets M VESA Wallmount Razor Thin 4/5/600</h3>
            <p className='thro'>Wall mount for plasma / LCD / TV - aluminum - black - screen size: 46” - 63”</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>M Universal Flexarm Pro Super Duty</h3>
            <p className='thro'>Wall mount for LCD / plasma panel - black - screen size: 55” - 110” Max 125 Kg</p>
        </div>
        <img
            src={aoto6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Multibrackets M Universal Swing Arm 180 Degrees Large</h3>
            <p className='thro'>Wall mount for LCD / plasma panel - metal - black - screen size: 48” - 69”</p>
        </div>
        <img
            src={aoto7}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Multibrackets M Universal Swing Arm 180 Degrees X Large</h3>
            <p className='thro'>Wall mount for LCD / plasma panel - metal - black - screen size: 70” - 84”</p>
        </div>
        <img
            src={aoto8}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Multibrackets M Motorized Swing Mount Large</h3>
            <p className='thro'>Mounting kit ( swing arm ) for LCD TV - black - screen size: 32” - 60” - wall-mountable</p>
        </div>
        <img
            src={aoto9}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Multibrackets M Motorized Tilt Mount</h3>
            <p className='thro'>Wall mount for LCD TV - black - screen size: 26” - 52”</p>
        </div>
        <img
            src={aoto10}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Multibrackets M Motorized Tilt Mount</h3>
            <p className='thro'>Wall mount for LCD TV - black - screen size: 40” - 52”</p>
        </div>
        <img
            src={aoto11}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Multibrackets M Motorized Turn Mounting</h3>
            <p className='thro'>Wall mount for LCD / plasma panel - black - screen size: 32” - 55”</p>
        </div>
        <img
            src={aoto12}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp audac-loud" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-card">
            <h3>Multibrackets M Public Display Stand 110 Tilt & Table</h3>
            <p className='thro'>Stand for LCD display - aluminum - black - screen size: 32” - 55” - mounting interface: 700 x 400 mm</p>
        </div>
        <img
            src={aoto13}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>


  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;