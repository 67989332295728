import React from 'react';
import aoto1 from '../../Assets/XpoScreens/Customizable Solutions.jpg';
// import aoto2 from '../../Assets/XpoScreens/Engaging Content Options.jpg';
import aoto3 from '../../Assets/XpoScreens/High Visibility Displays.jpg';
import aoto4 from '../../Assets/XpoScreens/Robust Build Quality.jpg';
import aoto5 from '../../Assets/XpoScreens/Smart Integration Capabilities.jpg';
import aoto6 from '../../Assets/XpoScreens/Sustainable Practices.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Customizable Solutions</h3>
            <p className='thro'>XPO Screens offers a wide range of digital display configurations tailored specifically to meet client needs. Each product can be modified in terms of size, functionality, and design to fit seamlessly into any environment, ensuring that your brand’s message is uniquely represented.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>High Visibility Displays</h3>
            <p className='thro'>All XPO screens are designed with high-brightness technology, ensuring maximum visibility in various lighting conditions. 
            This feature is particularly beneficial in bustling environments like elevators and retail spaces, where capturing attention quickly is essential.</p>
        </div>
        <img
            src={aoto6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Sustainable Practices</h3>
            <p className='thro'>XPO is committed to sustainability, using eco-friendly materials and energy-efficient technologies in the 
            manufacture of their products. This dedication not only minimizes environmental impact but also contributes to the long-term savings for clients on energy consumption.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Smart Integration Capabilities</h3>
            <p className='thro'>XPO Screens can be seamlessly integrated with existing IT and multimedia systems, enabling real-time content updates and management. This allows businesses to adapt their messaging quickly and efficiently, enhancing operational flexibility.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Engaging Content Options</h3>
            <p className='thro'>Clients can leverage advanced multimedia content features, including video playback, animations, and interactive elements. These capabilities help make information more engaging and can significantly boost viewer retention and engagement rates.</p>
        </div>
        <img
            src={aoto6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Robust Build Quality</h3>
            <p className='thro'>Designed for high-traffic public areas, XPO screens feature durable constructions that can withstand the rigors of daily use. Their resilience ensures a long lifespan, reducing the need for frequent replacements and maintenance.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;