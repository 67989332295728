import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What is Dante™?",
      answer: "Dante™ is a digital media networking technology that transmits high resolution audio and video over standard Ethernet networks, replacing traditional AV/IT connections.",
    },
    {
      question: "How does Dante™ improve AV/IT setups?",
      answer: "By allowing one simple Ethernet connection to carry multiple channels of audio and video, Dante™ simplifies installations and reduces cable clutter.",
    },
    {
      question: "What kind of products are compatible with Dante™?",
      answer: "Dante™ technology is compatible with thousands of products, including mixers, interfaces, microphones, amplifiers, and more from over 600 manufacturers.",
    },
    {
      question: "Can Dante™ scale for larger installations?",
      answer: "Yes, Dante™ is designed to scale effortlessly, accommodating small to large systems without compromising performance or audio integrity.",
    },
    {
        question: "What is the latency associated with Dante™?",
        answer: "Dante™ technology is known for its low latency, typically under a millisecond, making it suitable for live performances and real-time audio applications. ",
      },
      {
        question: "How do I manage my Dante™ network?",
        answer: "You can use the Dante™ Controller software, which provides an intuitive interface for managing audio routing and configuring your Dante devices.",
      },
      {
        question: "Is Dante™ secure?",
        answer: "Yes, with features like Dante™ Domain Manager, users can enhance security by managing access and monitoring network activity.",
      },
      {
        question: "What are Dante™-enabled microphones?",
        answer: "These are microphones that integrate directly into Dante™ systems, allowing for direct connection to the network without the need for additional hardware.",
      },
      {
        question: "Do I need special cables for Dante™?",
        answer: "No, standard Ethernet cables can be used for Dante™, simplifying installations and reducing costs on cabling.",
      },
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
