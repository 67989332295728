import React from 'react';
import aoto1 from '../../Assets/Netgear/netgearkey1.jpg';
import aoto2 from '../../Assets/Netgear/netgearkey2.jpg';
import aoto3 from '../../Assets/Netgear/netgearkey3.jpg';
import aoto4 from '../../Assets/Netgear/netgearkey4.jpg';
import aoto5 from '../../Assets/Netgear/netgearkey5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = { 
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Advanced Technology Integration</h3>
            <p className='thro'>Netgear products incorporate the latest technology, ensuring high performance and reliable connectivity. This commitment to innovation enhances user experiences across a variety of applications, from streaming to gaming and business operations.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>User-Friendly Design</h3>
            <p className='thro'>With a focus on ease of use, Netgear devices feature intuitive interfaces and simple setup processes. This empowers users to enjoy seamless connections without the frustration that often accompanies complex technology.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Robust Performance and Reliability</h3>
            <p className='thro'>Netgear is synonymous with consistent, high-speed performance. Their products are engineered to handle heavy data loads and multiple devices, ensuring stability and uninterrupted service in any environment.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Comprehensive Support Services </h3>
            <p className='thro'>Netgear offers extensive customer support, including professional installation services, troubleshooting assistance, and a vast online resource library. This dedication to customer care elevates the brand experience.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Versatile Product Range</h3>
            <p className='thro'>Catering to various needs, Netgear’s portfolio includes solutions for home users, small businesses, and large enterprises. This adaptability ensures that there is a product for every type of customer.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;