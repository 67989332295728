import React from 'react';
import './Microbrackerts.css';
import Aoto1 from '../OwlCarousel/Microbrackerts';
import MultiProducts from '../OwlCarousel/MultiProducts';
import FAQ from '../FAQ/MicrobrackertsFAQ';
import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
// import { Tab, Nav } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
// import FAQSection from '../FAQ/Faq';
// import { Link } from 'react-router-dom';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | Multibrackets</title>
                <meta name="MultiBrackets: Buy High-Quality HD Video Mounts & Displays" content="Upgrade Your AV/IT Setup With MultiBrakcets High-Definition Video Mounts. Enjoy Easy Integration, Optimal Zoom Capabilities, Multiple Connectivity Options and More" />
                <meta name="keywords" content="AV/IT Mounting Technology, Unparalelled mounting Solutions, Digital Signage, AV/IT Equipment, Height Adjustable Mounts" />
            </Helmet>
        </div>

        <div class="multi-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See Beyond Limits</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Tune into Tomorrow:  <br/> Experience Perfected Sounds
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://products.multibrackets.com/en/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Latest AV/IT Mounting Solutions</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Multi Brackets is a trailblazer in the AV/IT mount industry, expertly blending Scandinavian design with innovation to cater to modern display needs. For over two decades, we have been committed to delivering unparalleled mounting solutions that not only enhance visual experiences but also prioritize user comfort and accessibility.
 <br/>Multi Brackets stands at the forefront, providing versatile and customizable solutions designed to optimize space and functionality. You can expect the same experience both in home entertainment, office entertainment or digital signage, the crystal clear sounds would always remain the same. Their passion for excellence is matched only by our dedication to customer satisfaction, and we continuously strive to push the boundaries of what's possible in AV mounting technology.
                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>Innovative AV Mounting Technology Solutions</h2>
            <Aoto1/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5">Discover Our Premium Solutions for AV/IT Installations</h3>
            {/* <Tab.Container defaultActiveKey="Commercial">
            <Nav variant="pills" className="justify-content-center">
                <Nav.Item>
                    <Nav.Link eventKey="Commercial">Mesh WiFi Systems</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Creative">WiFi Routers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Control">5G Mobile Routers</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="Rental">Cable Modems</Nav.Link>
                </Nav.Item>
            </Nav>

            <Tab.Content>
                <Tab.Pane eventKey="Commercial">
                    <AotoCommercial/>
                </Tab.Pane>

                <Tab.Pane eventKey="Creative">
                    <AotoCreative/>
                </Tab.Pane>

                <Tab.Pane eventKey="Rental">
                    <AotoRental/>
                </Tab.Pane>

                <Tab.Pane eventKey="Control">
                    <AotoControl/>
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container> */}
        <MultiProducts/>
        <div className="middle-button">
            <a target='_blank' href='https://products.multibrackets.com/en/about' rel="noreferrer"><button className="button_1">Know More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Your Partner in Digital Signage Solutions </h4>
            <div className='line-up'>
                <h4>Digital Signage Pro Series:</h4>
                <p>This extensive range of mounts supports creative video wall configurations with fine-tune adjustments in all axes, designed for high-end commercial applications.</p>
            </div>
            <div className='line-up'>
                <h4>Motorized Projection Screens:</h4>
                <p>Deliver exceptional viewing experiences with screens that smoothly retract for a clean aesthetic and quick setup, ideal for both presentations and home theater systems.</p>
                 </div>
            <div className='line-up'>
                <h4>Desktop Display Mounts: </h4>
              <p>Designed to enhance ergonomics, these mounts simplify monitor positioning, fostering a more comfortable work environment.</p>
            </div>
            <div className='line-up'>
                <h4>Wall Mounts:</h4>
                <p>Space-saving designs that provide secure mounting solutions for TVs and displays, ensuring a clean look while maximizing floor space.</p>
            </div>
            <div className='line-up'>
                <h4>Floor Stands:</h4>
              <p>These sturdy and stylish stands are perfect for putting displays at the right height for any audience, making them ideal for presentations and events.</p>
            </div>
            <div className='line-up'>
                <h4>Counterbalanced Solutions:</h4>
              <p>Innovative mounts combining sleek design with exceptional stability, providing reliable display solutions for commercial and residential setups.</p>
            </div>
            <div className='line-up'>
                <h4>AV/IT Storage:</h4>
                <p>Smartly designed storage solutions that accommodate AV/IT equipment while maintaining an organized space, enhancing both functionality and aesthetics.</p>
            </div>
            <div className='line-up'>
                <h4>Cable Management:</h4>
                <p>Innovative products that allow for seamless organization and concealment of cables, creating a clean and professional installation.</p>
            </div>
            <div className='line-up'>
                <h4>Projector Mounts:</h4>
                <p>Heavy-duty mounts that provide stability and flexibility for various projector models, ensuring precise alignment and optimal performance.</p>
            </div>
            <div className='line-up'>
                <h4>Headset Holders & Accessories:</h4>
                <p>A range of practical accessories designed to optimize workspace organization, catering to the needs of modern AV/IT environments.</p>
            </div>
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Trusted AV/IT Equipment Options</h5><br/>
            <p>Multi Brackets aims to become a leading innovator in the AV/IT mounting industry on a global scale. They aim to enhance our product offerings with advanced technology, ensuring that we remain at the forefront of trends and innovations in seamless connectivity and ergonomic design. Multibrackets commitment to sustainability will guide their production processes, fostering eco-friendly practices while delivering high-quality solutions. They envision a future where their products not only enrich user experiences but also adapt to the evolving nature of workspaces, helping businesses and individuals create the optimal environments for collaboration and creativity.</p>
            <a target='_blank' href='https://products.multibrackets.com/en/about' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
                <input
                  type="email"
                  className="subscribe-input"
                  placeholder="Enter your email"
                  required
                /> <br/><br/>
                <button type="submit" className="button_4">
                  Subscribe
                </button>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQ/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about Multi Brackets and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 
    </div>
  )
}

export default About
