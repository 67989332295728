import React from 'react';
import aoto1 from '../../Assets/MultiBrackets/MBKey1.jpg';
import aoto2 from '../../Assets/MultiBrackets/MBKey2.jpg';
import aoto3 from '../../Assets/MultiBrackets/MBKey3.jpg';
import aoto4 from '../../Assets/MultiBrackets/MBKey4.jpg';
import aoto5 from '../../Assets/MultiBrackets/MBKey5.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
<div className="card resp key audac multi" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item ">
            <h3>Versatile Product Range</h3>
            <p className='thro'>Multi Brackets offers a comprehensive selection of mounting solutions, including wall mounts, 
            desktop displays, and digital signage configurations. This extensive range ensures that there is a perfect solution for 
            every environment—home, office, or commercial space.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
<div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Scandinavian Design Philosophy</h3>
            <p className='thro'>With a focus on minimalism and functionality, our products are not only aesthetically pleasing 
            but also crafted to ensure effortless usability. The clean lines and stylish finishes of Multi Brackets’ offerings 
            enhance any décor while maximizing practicality, setting a new standard for AV installations.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
<div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Motorized Solutions</h3>
            <p className='thro'>Standing out in the market, our motorized height-adjustable mounts provide users with 
            exceptional convenience and comfort. Whether for home or office use, these solutions allow for seamless adjustments 
            ensuring that displays can be positioned perfectly for any viewing angle. Our commitment to innovation in this area sets us apart from competitors.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
<div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Easy Installation</h3>
            <p className='thro'>All Multi Brackets products are engineered with user-friendliness in mind, making 
            installation quick and straightforward. This focus on ease-of-use reduces installation times and minimizes 
            the need for professional assistance, empowering customers to set up their AV equipment independently.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
<div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Durability Assured</h3>
            <p className='thro'>Manufactured using high-quality materials, our products are designed to withstand rigorous use while 
            maintaining their integrity over time. This commitment to durability ensures that customers can rely on Multi Brackets products 
            for long-lasting performance without frequent replacements.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;