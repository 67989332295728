import React from 'react';
import aoto1 from '../../Assets/Xilica/Collaboration and Interactivity.jpg';
import aoto2 from '../../Assets/Xilica/Future-Ready Solutions.jpg';
import aoto3 from '../../Assets/Xilica/Robust Audio Performance.jpg';
import aoto4 from '../../Assets/Xilica/Seamless Plug-and-Play Integration.jpg';
import aoto5 from '../../Assets/Xilica/Standard IT Infrastructure Compatibility.jpg';
import aoto6 from '../../Assets/Xilica/Versatile Matrixes and Switchers.jpg';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Standard IT Infrastructure Compatibility</h3>
            <p className='thro'>Xilica integrates its audio solutions with existing IT infrastructure, ensuring easy deployment and scalability. This compatibility means customers can utilize their existing wiring and networks, simplifying installations and reducing costs without compromising performance.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
        <div className="card-body card-height audac-item">
            <h3>Advanced DSP Technology</h3>
            <p className='thro'>Our Digital Signal Processors (DSPs) offer unmatched sound clarity and versatility, enabling precise control over audio settings. With intuitive user interfaces and customizable configurations, these processors can adapt to any environment, ensuring the best auditory experience.</p>
        </div>
        <img
            src={aoto6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Seamless Plug-and-Play Integration</h3>
            <p className='thro'>Xilica products are designed for quick and easy setup with minimal training required. The plug-and-play nature of our solutions allows users to focus on their tasks rather than technical complexities, enhancing productivity in hybrid and traditional settings.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Robust Audio Performance</h3>
            <p className='thro'>Engineered for excellence, Xilica products deliver crystal-clear sound across various environments. Whether in large auditoriums or small meeting rooms, our solutions ensure that every voice is heard and understood, boosting engagement and communication.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Collaboration and Interactivity</h3>
            <p className='thro'>Xilica fosters enhanced collaboration through integrated user interfaces and networking capabilities. Our systems enable real-time engagement, making it easy for participants to connect, share information, and contribute to discussions, regardless of their location.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp key audac" style={{ width: "480px", borderColor: "white" }}>
    <div className="card-body card-height audac-item">
            <h3>Future-Ready Solutions</h3>
            <p className='thro'>With a commitment to innovation, Xilica continuously improves its offerings to stay ahead of industry trends. Our products are designed to evolve with changing technological demands, ensuring that clients are equipped for future challenges in audio-visual communication.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>






  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;