import React from 'react';
import aoto1 from '../../Assets/Alsee/Allseetouch1.png';
import aoto2 from '../../Assets/Alsee/Allseetouch2.png';
import aoto3 from '../../Assets/Alsee/Allseetouch3.png';
import aoto4 from '../../Assets/Alsee/Allseetouch4.png';
import aoto5 from '../../Assets/Alsee/Allseetouch5.png';
import aoto6 from '../../Assets/Alsee/Allseetouch6.png';
import aoto7 from '../../Assets/Alsee/Allseetouch7.png';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import './TopProductCarousel.css';
import './Aoto1.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
  autoplay: true,
 autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5" {...options}>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>PCAP Touch Screens With Dual OS</h3>
            <p className='thro'>Commercial touch screen solution with edge-to-edge glass and flexible integrated PC.</p>
        </div>
        <img
            src={aoto1}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>
<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Android PCAP Touch Screens</h3>
            <p className='thro'>Commercial touch screen solution with edge-to-edge glass and integrated Android PC.</p>
        </div>
        <img
            src={aoto2}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>PCAP Touch Screen Kiosk With Dual OS</h3>
            <p className='thro'>Commercial All-in-one touch kiosk solution with integrated PC and internal locker.</p>
        </div>
        <img
            src={aoto3}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>27” PCAP Self Service Kiosk</h3>
            <p className='thro'>Commercial self ordering touch screen solution for quick service restaurants.</p>
        </div>
        <img
            src={aoto4}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>Infrared Freestanding Multi Touch Screen Posters</h3>
            <p className='thro'>Commercial touch screen totem solution with flexible integrated PC.</p>
        </div>
        <img
            src={aoto5}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>4K Slim Bezel Interactive Touch Displays</h3>
            <p className='thro'>Whiteboard touchscreen solution for collaboration in the classrooms and meeting rooms.</p>
        </div>
        <img
            src={aoto6}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>

<div className="item">
    <div className="card resp aoto" style={{ width: "430px", borderColor: "white" }}>
    <div className="card-body card-height">
            <h3>POS Android PCAP Touch Screens</h3>
            <p className='thro'>Network PoE Point of Sale touchscreen.</p>
        </div>
        <img
            src={aoto7}
            className="card-img-bottom zooming-image"
            alt="React Icon"
        />
    </div>
</div>




  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;