import React from 'react';
import './CatchBox.css'
import CatchBoxKey from '../OwlCarousel/CatchBoxKey';
import CatchBox from '../OwlCarousel/CatchBox';

import trusted_1 from '../../Assets/trusted_1.png';
import trusted_2 from '../../Assets/trusted_2.png';
import trusted_3 from '../../Assets/trusted_3.png';
import trusted_4 from '../../Assets/trusted_4.png';
import trusted_5 from '../../Assets/trusted_5.png';
import { Helmet } from 'react-helmet-async';
import FAQSection from '../FAQ/CatchBoxFAQ';
import Enquiryform from '../../Main_Components/Forms/EnquiryForm'
// import { Link } from 'react-router-dom';


const About = () => {
  return (
    <div>
        {/* <div aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/products">Products</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Nureva
            </li>
          </ol>
        </div> */}
        <div>
            <Helmet>
                <title>Nvincy | CatchBox</title>
                <meta name="Catch Box: High Quality Wireless Microphones" content="Experience Top-notch Audio Capture With High-Quality Audio Solutions From Catch Box for Any Events. Wireless Mics for Clearer and Reliable Audio Experience." />
                <meta name="keywords" content="Wireless Microphone System, High quality audio, Audio Capture, Audio solutions, Technical expertise" />
            </Helmet>
        </div>

        <div class="catchbox-sub_banner">
          <div className="product-banner-content move-down">
            {/* <div class="d-inline-flex align-items-center bg-dark text-white rounded-pill px-3 py-1">
                <span class="badge bg-success rounded-pill me-2">New</span>
                <span>See the Future Clearly</span>
                <span class="ms-2 text-success">--</span>
            </div> */}
            <h1 className='product-h1'>
            Seamless Audio Quality - Loved <br/> by Professionals
            </h1>
            <div className="carousel-buttons">
                <a target='_blank' href='https://catchbox.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
            </div>
          </div>
        </div>



        
        <div className='cust-container' style={{paddingTop: "120px"}}>
          <div className='white_bg'>
            <div className='row '>
                <h1 className='htag'>Expert Wireless Audio Solutions Just For You</h1>
                <div className='col-12 col-md-12 col-sm-6'>
                    <p className='font-weight1 about1'>
                    Catchbox is revolutionizing the way we think about audio in various environments with its innovative and playful approach to wireless microphone systems. Their aim is to combine user-friendly design with advanced technology, setting new industry standards and making high-quality audio accessible to all.
<br/><br/>From universities to corporate offices and conferences, their unique throwable microphone system allows for dynamic audience interaction, ensuring that every voice is heard clearly and effortlessly. Designed for simplicity and versatility, Catchbox empowers users to achieve broadcast-quality sound in any space without the technical hassle typically associated with audio setups. 

                    </p>
                </div>
            </div>
          </div>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h2 className='htag'>High Quality Audio Matters!</h2>
            <CatchBoxKey/>
        </div>

        <div className='cust-container' style={{paddingTop: "150px"}}>
            <h3 className="htag mb-5"> High-Quality Audio Capture Systems</h3>
            <CatchBox/>
        <div className="middle-button">
            <a target='_blank' href='https://catchbox.com/' rel="noreferrer"><button className="button_1">Explore More</button></a>
        </div>
    </div>

    <div className='cust-container' style={{ paddingTop: "100px"}}>
          <div className="white_bg">
            <h4 className='htag'>Best-In-Class Wireless Audio Technology</h4>
            <div className='line-up'>
                <h4>Catchbox Plus
                :</h4>
                <p>The flagship product is a throwable wireless microphone designed for interactive environments. It offers excellent audio capture and allows for vibrant audience engagement during presentations and discussions.
</p>
            </div>
            <div className='line-up'>
                <h4>Catchbox Mod
                : </h4>
                <p>A modular system that allows users to customize their audio solutions based on specific needs. The Catchbox Mod is versatile, enabling easy additions and adjustments to the existing setup.

</p>
            </div>
            <div className='line-up'>
                <h4>Accessories and Spares
                :
</h4>
                <p>A range of supporting accessories and spare parts designed to enhance the functionality and longevity of Catchbox products, ensuring users have everything they need to maintain their audio systems.
</p>
            </div>
          </div>
    </div>

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h2><span className="font-weight2">Trusted Support,</span><br/> <span className="font-weight1">Every Step of the Way.</span></h2>
            <p className="responsive" style={{ paddingTop: "40px"}}>Join the ranks of the world's leading brands that rely on us for their AV/IT solutions! Our commitment to excellence has earned us their trust time and time again. With unparalleled expertise and innovative technology, we transform visions into vibrant realities.
</p>
            <div className="row" style={{ paddingTop: "40px"}}>
                <div className="col-md-2 trusted mx-0">
                  <img src={trusted_1} alt="trusted_1"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_2} alt="trusted_2"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_3} alt="trusted_3"></img>
                </div> 
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_4} alt="trusted_4"></img>
                </div>
                <div className="col-md-2 trusted mx-5 mx-sm-0 move-left">
                  <img src={trusted_5} alt="trusted_5"></img>
                </div>
            </div>
          </div>
        </div> 

       

        <div className='cust-container' style={{ paddingTop: "150px"}}>
          <div className="white_bg">
            <h5 className='htag'>Experience Innovation. Get Started With Wireless Audio Devices Today
</h5><br/>
            <p>Catchbox envisions a future where audio technology seamlessly integrates into every learning, corporate, and creative environment, fostering open communication and collaboration regardless of location. By continuously innovating and breaking industry norms, Catchbox aims to enhance and redefine the standards for how microphones are used, ensuring that everyone—from teachers to corporate leaders—can engage and communicate effectively in any setting, making quality audio a universal standard rather than a luxury.
</p>
            <a target='_blank' href='https://catchbox.com/' rel="noreferrer"><button className="button_1">Know More</button></a>
          </div>
        </div>

        <div className='cust-container' style={{ paddingTop: "150px", paddingBottom: "150px"}}>
              <div className="white_bg">
              <div className="newsletter-section">
              <h1 className="newsletter-heading font-weight2">Subscribe To Our Newsletter</h1>
              <p className="newsletter-description py-4">
                Join our subscribers list to get the latest news, updates, and special
                <br />
                offers delivered directly to your inbox.
              </p>
              <form className="subscribe-form">
              <iframe title='subscription' aria-label='Subscription Form' frameborder="0" style={{height: "300px", width: "300%", border: "none", overflowY: "hidden"}} src='https://forms.zohopublic.in/nvincysolutionspvtltd1/form/SubscriptionForm1/formperma/TYeotemh9179x02yS3EgGuuuU7uIipOnJ5-NGymdmbE'></iframe>
              </form>
        </div>
              </div>
        </div> 

        <div className='cust-container' style={{ paddingTop: "0"}}>
          <h2 className="font-weight1" style={{ textAlign: "center"}}>Help & FAQ</h2>
          <FAQSection/>
        </div> 

        <div className='cust-container' style={{ paddingTop: "100px"}}>
          <h2 className="font-weight1" style={{ textAlign: "center", paddingBottom: "60px"}}>Still have questions?</h2>
          <p style={{paddingBottom: "100px"}}>Contact Nvincy today! Our team of experts is happy to answer any questions you may have about CatchBox and discuss how it can transform your meeting room management.
          </p>
          <Enquiryform/>
        </div> 

    </div>
  )
}

export default About
