import React from 'react';
import position1 from '../../Assets/position1.png';
import position2 from '../../Assets/position2.png';
// import position3 from '../../Assets/position3.png';
import './CareerCarousel.css';
//Owl Carousel Libraries and Module
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

//Owl Carousel Settings
const options = {
  margin: 350,
  responsiveClass: true,
  nav: false,
 autoplay: true,
  autoplayTimeout: 3500,
  loop: false,
  navText: [
    '<i class="fas fa-chevron-left left-arrow"></i>', 
    '<i class="fas fa-chevron-right right-arrow"></i>' 
  ],
  dots: true,
  smartSpeed: 500,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 2,
      },
      600: {
          items: 2,
      },
      700: {
          items: 3,
      },
      1000: {
          items: 4,

      }
  },
};
class App extends React.Component {
  render() {
   

    return (
<div>
<OwlCarousel className="slider-items owl-carousel pt-5 carousel-wrapper-career" {...options}>
    <div className="item ">
        <div className="card resp" style={{ width: "430px", borderColor: "white" }}>
        <img
                src={position2}
                className="card-img-top zooming-image"
                alt="React Icon"
            />
            <div className="card-body">
                <h3>Application Engineer <br/> Location: Chennai</h3>
            </div>
        </div>
    </div>
    <div className="item">
        <div className="card resp" style={{ width: "430px", borderColor: "white" }}>
            <img
                src={position1}
                className="card-img-top zooming-image"
                alt="React Icon"
            />
            <div className="card-body">
                <h3>Sales Manager <br/> Location: Chennai</h3>
            </div>
        </div>
    </div>

{/* <div className="item">
    <div className="card resp" style={{ width: "430px", borderColor: "white" }}>
    <img
            src={position3}
            className="card-img-top zooming-image"
            alt="React Icon"
        />
    <div className="card-body">
            <h3>Marketing Manager</h3>
        </div>
        
    </div>
</div>
<div className="item">
    <div className="card resp" style={{ width: "430px", borderColor: "white" }}>
    <img
            src={position1}
            className="card-img-top zooming-image"
            alt="React Icon"
        />
        <div className="card-body">
            <h3>Developer</h3>
        </div>
    </div>
</div> */}

  {/* Add more items here similarly */}
</OwlCarousel>

</div>
)
};
}

export default App;