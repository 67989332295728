import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What is the primary function of Laia cameras?",
      answer: "Laia cameras are designed for high-definition video capture with intelligent tracking for conferences, live events, and professional environments.",
    },
    {
      question: "How does AI tracking work?",
      answer: "The AI tracking technology uses advanced processing algorithms to follow subjects in real-time, keeping them centered within the frame regardless of their movement.",
    },
    {
      question: "What video output formats do Laia cameras support?",
      answer: "Laia cameras support multiple connectivity formats including HDMI, 3G-SDI, and USB3.0, ensuring compatibility with various broadcasting and streaming setups.",
    },
    {
      question: "Are Laia cameras suitable for low-light environments?",
      answer: "Yes, Laia cameras have optimized performance for low-light conditions, allowing for clear video capture even in dimly lit settings.",
    },
    {
      question: "Can Laia cameras be integrated with popular video conferencing platforms?",
      answer: "Absolutely! Laia cameras are compatible with platforms like Google Meet, Microsoft Teams, and Zoom, making them versatile for any meeting environment.",
    },
    {
      question: "What are the privacy features of Laia cameras?",
      answer: "Laia cameras come equipped with privacy covers that physically block the camera view, providing users with control over when their camera is active.",
    },
    {
        question: "What control protocols are supported?",
        answer: "Laia cameras support a wide array of control protocols, including VISCA and Pelco-D, allowing for easy integration with existing control systems.",
      },
      
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
