import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What are BrightSign digital signage media players? ",
      answer: "BrightSign media players are powerful devices that decode and display digital signage content like images, videos, and interactive elements on screens or displays.",
    },
    {
      question: "What types of content can BrightSign players display?",
      answer: "BrightSign players can display a variety of content formats including: Images (JPEG, PNG), Videos (MP4, H.264), Animations (Flash), Live streams (with additional software), Interactive elements (with web-based technologies)",
    },
    {
      question: "Are BrightSign players easy to use? ",
      answer: "Absolutely! They come with user-friendly software that allows you to create, schedule, and manage your digital signage content without extensive technical knowledge.",
    },
    {
      question: "How secure are BrightSign players? ",
      answer: "BrightSign players are equipped with a variety of security features to protect your content and data. These features include encryption, access controls, and secure content delivery protocols.",
    },
    {
        question: "Is it difficult to set up BrightSign players? ",
        answer: "Setting up BrightSign players is relatively straightforward. Nvincy offers comprehensive setup and training services to ensure seamless integration with your existing systems.",
      },
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
