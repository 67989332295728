import React, { useState } from 'react';
import './Faq.css'; // Optional: If you want to keep CSS separate.

const FAQSection = () => {
  // Define state to track which FAQ item is open
  const [openIndex, setOpenIndex] = useState(null);

  // Toggle function for showing/hiding answers
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // FAQ Data: You can add more FAQ items here
  const faqData = [
    {
      question: "What types of screens does XPO offer?",
      answer: "XPO offers a variety of digital display solutions, including elevator media displays, smart mirrors, bespoke digital signage, restroom displays, and more tailored to different industries.",
    },
    {
      question: "Can XPO screens be customized?",
      answer: "Yes, all XPO products can be tailored to meet specific customer needs, including size, design, and functionality.",
    },
    {
      question: "Are XPO screens suitable for outdoor use?",
      answer: "While XPO specializes in indoor solutions, certain products can be adapted for outdoor use with additional weatherproofing features—please consult our team for specifics.",
    },
    {
      question: "How eco-friendly are XPO products?",
      answer: "XPO is committed to sustainability, using eco-friendly materials and energy-efficient production techniques in our display solutions.",
    },
    {
      question: "Can I manage content on XPO screens remotely?",
      answer: "Yes! XPO screens come equipped with smart integration capabilities, allowing for remote content management and real-time updates through existing systems.",
    },
    {
      question: "What industries does XPO cater to?",
      answer: "XPO provides digital signage solutions for a range of industries including retail, corporate, hospitality, residential buildings, and conventions.",
    },
    {
      question: "Are XPO screens easy to install?",
      answer: "Yes, XPO products are designed for straightforward installation, and our team provides support to ensure a smooth setup process.",
    },
    {
      question: "What is narrowcasting, and how is it used?",
      answer: "Narrowcasting refers to targeted digital signage designed to engage specific audiences with relevant content at the right times and locations.",
    },
    {
      question: "How durable are XPO screens?",
      answer: "All XPO screens are built with robust materials designed to withstand the challenges of high-traffic areas, ensuring longevity and reliability.",
    },
    {
      question: "How can I purchase XPO products?",
      answer: "You can explore our product offerings and place orders through our website or by contacting our sales team for further assistance.",
    },
    
    
    // Add more FAQ items as needed
  ];

  return (
    <section id="faq">
      {faqData.map((faq, index) => (
        <div className="faq-item" key={index}>
          <h3 onClick={() => toggleFAQ(index)}>
            {faq.question}
            <span className="icon">{openIndex === index ? '–' : '+'}</span>
          </h3>
          <p style={{ maxHeight: openIndex === index ? '200px' : '0' }}>
            {faq.answer}
          </p>
        </div>
      ))}
    </section>
  );
};

export default FAQSection;
